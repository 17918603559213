import sdk from '@hubportal/sdk';
import { useQueryClient } from '@tanstack/react-query';
import { useHubPatchRequest } from 'api/generated/apiComponents';
import { QUERY_KEYS } from 'lib/constants';
import { notifyError, notifySuccess } from 'lib/toast';
import { useTranslation } from 'hooks';

const ReactivateHubButton = ({ hubSlug }: { hubSlug: string }) => {
  const { t } = useTranslation();
  const { mutate } = useHubPatchRequest();
  const queryClient = useQueryClient();

  const handleReactivateHub = () => {
    mutate(
      {
        body: { force_close: { active: false } },
        pathParams: { hubSlug },
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: QUERY_KEYS.GET_HUB_REQUEST,
          });
          await queryClient.invalidateQueries({
            queryKey: QUERY_KEYS.HUBS,
          });
          notifySuccess(t('toast.changes-applied-successfully'));
        },
        onError: (e) => {
          const errorMessage = `${t('toast.error')}${e.payload ? `:${e.payload}` : ''}`;
          sdk.reportError(new Error(String(e.payload)), '@flapp/hub-manager');
          notifyError(errorMessage);
        },
      }
    );
  };

  return (
    <button className="flex-1 text-end hover:text-flinkPink" onClick={() => handleReactivateHub()}>
      {t('components.hub-setting-dropdown-menu.reactivate-hub.button')}
    </button>
  );
};

export default ReactivateHubButton;
