import { IconCircle, IconSelect } from 'assets/icons';
import Modal from 'components/Modal';
import ScheduledClosureDate, { SelectedDateTimeType } from 'components/ScheduledClosureDate';
import { DEFAULT_DATE_TIME, SCHEDULE_CLOSURE_REASONS_OPTION } from 'lib/constants';
import { useCallback, useState } from 'react';
import { Trans } from 'react-i18next';
import { ScheduleDates } from 'api/generated/apiSchemas';
import { getDateTime } from 'lib/helpers';
import useBulkHubClosure from 'stores/useBulkHubClosure';
import { HUBS_CLOSURE } from 'stores/types';
import useHubsScheduledClosureModal from 'stores/useHubsClosureModal';
import { useTranslation } from 'hooks';
import Spinner from 'components/Spinner';

type HubCreateScheduledClosureModalPropsType = {
  onClickCancel: () => void;
  updateScheduledClosure: ({
    reason,
    scheduledDate,
  }: {
    reason: string;
    scheduledDate: ScheduleDates;
  }) => void;
  isLoading: boolean;
  multi?: boolean;
};

const LinkText = ({ children }: { children?: React.ReactNode }) => {
  const { toggleModal } = useHubsScheduledClosureModal();
  return (
    <button className="underline" onClick={() => toggleModal(HUBS_CLOSURE.SCHEDULED)}>
      {children}
    </button>
  );
};

const HubCreateScheduledClosureModal = ({
  onClickCancel,
  updateScheduledClosure,
  isLoading,
  multi = false,
}: HubCreateScheduledClosureModalPropsType) => {
  const { t } = useTranslation();
  const [selectedReason, setSelectedReason] = useState('');
  const [selectedDateTime, setSelectedDateTime] = useState<SelectedDateTimeType>(DEFAULT_DATE_TIME);
  const { selectedHubs } = useBulkHubClosure();

  const createScheduledClosure = useCallback(() => {
    const startDate = getDateTime(
      selectedDateTime?.selectedDate?.[0],
      selectedDateTime.startTime.value
    );
    const endTime = getDateTime(
      selectedDateTime?.selectedDate?.[1],
      selectedDateTime.endTime.value
    );
    const scheduledDate = {
      start_date: startDate,
      end_date: endTime,
    };
    updateScheduledClosure({ reason: selectedReason, scheduledDate });
  }, [
    selectedDateTime.endTime.value,
    selectedDateTime?.selectedDate,
    selectedDateTime.startTime.value,
    selectedReason,
    updateScheduledClosure,
  ]);
  const shouldDisabledButton = !selectedReason || !selectedDateTime.selectedDate || isLoading;

  return (
    <Modal
      title={t('components.hub-create-scheduled-closure-modal.title')}
      onClickOutside={onClickCancel}
      customClass="overflow-hidden w-3/5"
    >
      <div>
        {selectedHubs.length && multi ? (
          <div className="text-white">
            <Trans
              i18nKey="components.hub-create-scheduled-closure-modal.subTitle"
              count={selectedHubs.length}
              components={{ Link: <LinkText /> }}
            />
          </div>
        ) : null}
        <ScheduledClosureDate
          selectedDateTime={selectedDateTime}
          handleSelect={setSelectedDateTime}
        />
        <div className="pb-6">
          <p className="font-bold text-2xl text-white">
            {t('components.hub-create-scheduled-closure-modal.reason')}
          </p>
          <div className="py-6 overflow-auto max-h-80">
            {SCHEDULE_CLOSURE_REASONS_OPTION.map((option) => (
              <button
                key={option}
                onClick={() =>
                  setSelectedReason((preOption) => (preOption === option ? '' : option))
                }
                className="h-14 px-4 my-1 w-full bg-flinkGray rounded-lg flex items-center"
              >
                <div className="w-5">
                  {selectedReason === option ? <IconSelect /> : <IconCircle />}
                </div>
                <p className="text-white text-base font-medium pl-3">
                  {t(`scheduled_closure_reasons.${option}`)}
                </p>
              </button>
            ))}
          </div>
        </div>
        <div className="flex justify-between">
          <button
            className="text-white bg-buttonBgColor w-56 leading-none p-4 rounded fontFamily-sans text-lg font-extrabold h-13"
            onClick={onClickCancel}
          >
            {t('components.hub-create-scheduled-closure-modal.cancel-button')}
          </button>
          <button
            className="flex items-center justify-center text-white bg-red w-56 leading-none p-4 rounded fontFamily-sans text-lg font-extrabold h-13 disabled:bg-flinkGray-light"
            onClick={createScheduledClosure}
            disabled={shouldDisabledButton}
          >
            {isLoading ? (
              <Spinner size={5} />
            ) : (
              t('components.hub-create-scheduled-closure-modal.schedule-close-button')
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default HubCreateScheduledClosureModal;
