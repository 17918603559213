import { useTranslation } from 'hooks';

const ErrorMessage = ({ errorMessage }: { errorMessage?: string }) => {
  const resonUnauthorized = errorMessage === "You're unauthorized to access this resource";
  const { t } = useTranslation();

  return (
    <div
      id="error"
      className="h-screen flex flex-col bg-flinkGray-medium text-white justify-center items-center ml-5"
    >
      <h1 className="font-extrabold text-6xl mb-5">{t('components.error-message-error.title')}</h1>
      <div className="w-10/12 text-center text-2xl font-extrabold mb-3">
        {errorMessage || t('components.error-message-error')}
      </div>
      {resonUnauthorized ? (
        <div>{t('components.error-message-info.support')}</div>
      ) : (
        <button onClick={() => window.location.reload()} className="underline">
          {t('components.error-message-info.reload')}
        </button>
      )}
    </div>
  );
};

export default ErrorMessage;
