import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { getZonedDate } from 'lib/helpers';
import { useTranslation } from 'hooks';

type DateRangeSelectPropsType = {
  onChange: (optinn: Date[]) => void;
  selectedDateRange?: Date[];
};

const DateRangeSelect = ({ onChange, selectedDateRange }: DateRangeSelectPropsType) => {
  const { t } = useTranslation();
  const TODAY = getZonedDate();

  return (
    <div className="date-range-select">
      <p className="text-base text-white">{t('components.date-range-select.label')}</p>
      <DateRangePicker
        className="react-daterange-picker"
        calendarClassName="react-calendar"
        onChange={(option: Date[]) => onChange(option)}
        value={selectedDateRange}
        format="dd.MM.yyyy"
        locale="de"
        minDate={TODAY}
      />
    </div>
  );
};

export default DateRangeSelect;
