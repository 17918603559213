import { ChangeEvent, memo, useCallback, useMemo, useRef } from 'react';
import { IconMagnify, IconClose } from 'assets/icons';
import { DEBOUNCE_TIME_IN_MS } from 'lib/constants';
import debounce from 'lodash.debounce';

type SearchInputProps = {
  searchQuery?: string;
  onSearch: (value: string) => void;
  placeholder?: string;
};

const SearchInput = ({ searchQuery, onSearch, placeholder = 'Search...' }: SearchInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const debounceQuery = useMemo(() => {
    return debounce((value: string) => {
      onSearch(value);
    }, DEBOUNCE_TIME_IN_MS);
  }, [onSearch]);

  const handleInputQuery = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      debounceQuery(event.target.value);
    },
    [debounceQuery]
  );

  const clearInput = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    onSearch('');
  }, [onSearch]);

  return (
    <div className="flex flex-1 items-center bg-flinkGray rounded-lg px-4 h-12 w-3/5 my-4">
      <input
        className="bg-flinkGray focus:outline-0 w-full"
        type="text"
        placeholder={placeholder}
        onChange={handleInputQuery}
        ref={inputRef}
        defaultValue={searchQuery}
        data-testid="search-input"
      />
      {searchQuery ? (
        <IconClose data-testid="icon-close" className="cursor-pointer" onClick={clearInput} />
      ) : (
        <IconMagnify data-testid="icon-magnify" />
      )}
    </div>
  );
};

export default memo(SearchInput);
