import { latLng, latLngBounds } from 'leaflet';
import { FiltersProps, SerializedHub } from './types';

export const getSearchParams = (searchParams: URLSearchParams) => {
  const countries = searchParams.getAll('country');
  const cities = searchParams.getAll('city');
  const statuses = searchParams.getAll('status');

  return { countries, cities, statuses };
};

export const getFilteredHubs = ({ hubs, countries, cities, statuses }: FiltersProps) => {
  let filtered = hubs;
  if (countries?.length) {
    filtered = filtered.filter(
      (hub: SerializedHub) => hub.country && countries.includes(hub.country)
    );
  }
  if (cities?.length) {
    filtered = filtered.filter((hub: SerializedHub) => hub.city && cities.includes(hub.city));
  }
  if (statuses?.length) {
    filtered = filtered.filter((hub: SerializedHub) => hub.status && statuses.includes(hub.status));
  }
  return filtered;
};

export const getLatLngBounds = (positions) => {
  const latLngs = positions.map((position) => {
    return latLng(position[0], position[1]);
  });
  const bounds = latLngBounds(latLngs);
  return bounds;
};
