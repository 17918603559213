import { useMutation } from '@tanstack/react-query';
import { ErrorWrapper, apiFetch } from 'api/generated/apiFetcher';
import { ApiContext, useApiContext } from 'api/generated/apiContext';
import { Turf } from 'api/generated/apiSchemas';

type UploadKmlFileVariables = {
  body?: BodyInit;
} & ApiContext['fetcherOptions'];

type ErrorResponse = ErrorWrapper<{
  status: number;
  payload: ErrorResponse;
}>;

export type UploadKmlFileResponse = Array<Turf>;

const uploadKmlFile = (variables: UploadKmlFileVariables) =>
  apiFetch<UploadKmlFileResponse, ErrorResponse, {}, {}, {}, {}>({
    url: '/hub/kml2json-service/',
    method: 'post',
    customUrl: true,
    ...variables,
  });

export const useUploadKmlFile = () => {
  const { fetcherOptions } = useApiContext();
  return useMutation((variables: UploadKmlFileVariables) =>
    uploadKmlFile({ ...fetcherOptions, ...variables })
  );
};
