import { IconCheck, IconArrowDownLg, IconArrowUpLg } from 'assets/icons';
import { memo, useCallback, useEffect, useRef, useState } from 'react';

import { useKeyPress, useOutsideClick } from 'hooks/index';

export type Option = {
  value: string;
  label: string;
  disabled?: boolean;
};

export type WorkingHoursSelectPropsType = {
  options: Array<Option>;
  selectedOption?: string;
  onSelectInput: (value: string) => void;
  isDisabled?: boolean;
};

const WorkingHoursSelect = ({
  options,
  selectedOption,
  onSelectInput,
  isDisabled,
}: WorkingHoursSelectPropsType) => {
  const ref = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLLIElement>(null);
  const escape = useKeyPress('Escape');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleButtonClick = () => {
    if (listRef.current) {
      listRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  };

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (escape && isOpen) {
      onClose();
    }
  }, [escape, isOpen, onClose]);

  useOutsideClick(ref, () => {
    if (isOpen) {
      onClose();
    }
  });

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSelectInput(event.target.value);
    handleButtonClick();
  };

  const onSelectOption = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    option: Option
  ) => {
    event.preventDefault();
    onSelectInput(option.value);
    onClose();
  };

  const Icon = isOpen ? IconArrowUpLg : IconArrowDownLg;

  return (
    <div className="relative" ref={ref}>
      <div className="flex justify-between items-center w-22 gap-1 h-12 mx-2 rounded-lg py-3 pl-3 pr-2 text-white border border-flinkGray">
        <input
          className="block truncate text-base border-0 py-3 w-fit h-12 placeholder-flinkGray-light caret-transparent bg-transparent"
          value={selectedOption}
          disabled={isDisabled}
          onChange={(event) => onChangeInput(event)}
          type="time"
          aria-label="working-hours-select"
        />
        <Icon
          data-testid="workingHoursSelect"
          onClick={() => setIsOpen(!isOpen)}
          className="cursor-pointer"
        />
      </div>
      {isOpen && (
        <ul className="absolute w-full w-32 z-10 mt-1 mx-2 bg-flinkGray max-h-96 rounded-md py-1 border-0 overflow-auto text-sm">
          {options.map((option: Option) => (
            <li
              ref={option.value === selectedOption ? listRef : null}
              key={option.value}
              className="hover:bg-flinkGray-medium"
            >
              <button
                className="flex items-center justify-between p-2 my-0.5 hover:bg-flinkGray-medium w-full border-b-0 text-base text-white disabled:opacity-60 disabled:bg-flinkGray-medium disabled:cursor-not-allowed"
                onClick={(event) => onSelectOption(event, option)}
                type="button"
                disabled={option.disabled}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={selectedOption === option.value}
              >
                <span className="ml-3 block truncate">{option.label}</span>
                {selectedOption === option.value && <IconCheck color="#E31C79" />}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default memo(WorkingHoursSelect);
