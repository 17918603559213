import { IconCircle, IconSelect } from 'assets/icons';
import Modal from 'components/Modal';
import { useCallback, useState } from 'react';
import { Trans } from 'react-i18next';
import { HUBS_CLOSURE } from 'stores/types';
import useBulkHubClosure from 'stores/useBulkHubClosure';
import useHubsClosureModal from 'stores/useHubsClosureModal';
import { useTranslation } from 'hooks';
import { EmergencyClosureReasons } from 'lib/types';
import { EMERGENCY_CLOSURE_REASONS } from 'lib/constants';

type HubEmergencyClosureModalProps = {
  onClickCancel: () => void;
  hubName?: string | null;
  updateHubState: (reason?: EmergencyClosureReasons) => void;
  multi?: boolean;
};

const LinkText = ({ children }: { children?: React.ReactNode }) => {
  const { toggleModal } = useHubsClosureModal();
  return (
    <button className="underline" onClick={() => toggleModal(HUBS_CLOSURE.EMERGENCY)}>
      {children}
    </button>
  );
};

const HubEmergencyClosureModal = ({
  onClickCancel,
  hubName,
  updateHubState,
  multi = false,
}: HubEmergencyClosureModalProps) => {
  const { t } = useTranslation();

  const [selectedReason, setSelectedReason] = useState<EmergencyClosureReasons>();
  const { selectedHubs } = useBulkHubClosure();

  const closeHub = useCallback(() => {
    updateHubState(selectedReason);
  }, [selectedReason, updateHubState]);

  const emergencyClosureReasonOptions = Object.values(EMERGENCY_CLOSURE_REASONS);
  const title = multi
    ? t('components.modal.shut-down-hub.multi.title')
    : `${t('components.modal.shut-down-hub')} ${hubName} ?`;
  return (
    <Modal title={title} onClickOutside={onClickCancel}>
      <div>
        {selectedHubs.length && multi ? (
          <div className="text-white">
            <Trans
              i18nKey="components.modal.shut-down-hub.multi.subTitle"
              count={selectedHubs.length}
              components={{ Link: <LinkText /> }}
            />
          </div>
        ) : null}
        <div className="py-6 px-4">
          <ul className="list-disc">
            <li className="text-white fontFamily-flink text-base font-medium">
              {t('components.modal.list-item-1')}
            </li>
            <li className="text-white fontFamily-flink text-base font-medium">
              {t('components.modal.list-item-2')}
            </li>
            <li className="text-white fontFamily-flink text-base font-medium">
              {t('components.modal.list-item-3')}
            </li>
          </ul>
        </div>
        <div className="pb-6">
          <p className="font-bold text-2xl text-white"> {t('components.modal.select-reason')}:</p>
          <div className="py-6 overflow-auto max-h-80">
            {emergencyClosureReasonOptions.map((option) => (
              <button
                key={option}
                onClick={() =>
                  setSelectedReason((preOption) => (preOption === option ? undefined : option))
                }
                className="h-14 px-4 my-1 w-full bg-flinkGray-dark rounded-lg flex items-center"
              >
                <div className="w-5">
                  {selectedReason === option ? <IconSelect /> : <IconCircle />}
                </div>
                <p className="text-white text-base font-medium pl-3">
                  {t(`emergency_closure_reasons.${option}`)}
                </p>
              </button>
            ))}
          </div>
        </div>
        <div className="flex justify-between">
          <button
            className="text-white bg-buttonBgColor w-56 leading-none p-4 rounded fontFamily-sans text-lg font-extrabold h-13"
            onClick={onClickCancel}
          >
            {t('components.modal.cancel-button')}
          </button>
          <button
            className="text-white bg-red w-56 leading-none p-4 rounded fontFamily-sans text-lg font-extrabold h-13 disabled:bg-flinkGray-light"
            onClick={closeHub}
            data-testid="closeHub"
            disabled={!selectedReason}
          >
            {t('components.modal.close-hub-button')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default HubEmergencyClosureModal;
