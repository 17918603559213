import { useTranslation } from 'hooks';
import HubContainer from 'components/HubContainer';
import SearchInput from 'components/SearchInput';
import MultiSelect from 'components/MultiSelect';
import { useEffect, useMemo } from 'react';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { ROUTE_PATHS, STATUS_OPTIONS, STATUS } from 'lib/constants';
import { getCountryAndCityOptions, getFilteredHubs, getSearchParams, SearchParams } from './helper';
import { ListHub } from 'api/generated/apiSchemas';
import { useHubs } from 'api/generated/apiComponents';
import Spinner from 'components/Spinner';
import ErrorMessage from 'components/ErrorMessage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScheduledClosureSetting from 'components/ScheduledClosureSetting';
import useBulkHubClosure from 'stores/useBulkHubClosure';

const HubManagerView = () => {
  const { setBatchClosureReasons } = useBulkHubClosure();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { countries, cities, statuses, searchQuery } = getSearchParams(searchParams);
  const defaultStatus = STATUS_OPTIONS.filter((status) => status.value !== STATUS.INACTIVE).map(
    (status) => status.value
  );
  const hasNoSeachParams = !cities.length && !countries.length && !statuses.length && !searchQuery;
  const { pathname } = useLocation();

  useEffect(() => {
    if (hasNoSeachParams && pathname === ROUTE_PATHS.HUB_MANAGER) {
      setSearchParams({ status: defaultStatus });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: hubs,
    isLoading,
    error,
  } = useHubs<ListHub[]>(
    {},
    {
      onSuccess: (data) => {
        setBatchClosureReasons(data);
      },
    }
  );

  const { countryOptions, cityOptions } = useMemo(() => {
    return getCountryAndCityOptions(hubs);
  }, [hubs]);

  const filteredHubs = useMemo(() => {
    if (!hubs) return [];
    if (hasNoSeachParams) {
      return hubs;
    } else {
      return getFilteredHubs({ hubs, countries, cities, statuses, searchQuery });
    }
  }, [cities, countries, hasNoSeachParams, hubs, searchQuery, statuses]);
  const selectedCountries = useMemo(() => {
    if (countries.length) {
      return countryOptions.filter((option) => countries.includes(option.value));
    }
  }, [countries, countryOptions]);

  const selectedCities = useMemo(() => {
    if (cities.length) {
      return cityOptions.filter((option) => cities.includes(option.value));
    }
  }, [cities, cityOptions]);

  const selectedStatuses = useMemo(() => {
    if (statuses.length) {
      return STATUS_OPTIONS.filter((option) => statuses.includes(option.value));
    }
  }, [statuses]);

  const setFilters = (searchParams: SearchParams) => {
    const currentSearchParams = {
      ...(searchQuery ? { search: searchQuery } : {}),
      ...(countries.length ? { country: countries } : {}),
      ...(cities.length ? { city: cities } : {}),
      ...(statuses.length ? { status: statuses } : {}),
    };
    setSearchParams({
      ...currentSearchParams,
      ...searchParams,
    });
  };

  if (error) return <ErrorMessage errorMessage={error.payload as string} />;

  return (
    <div className="px-10 h-screen-main overflow-y-scroll">
      <div className="my-10">
        <SearchInput
          onSearch={(query: string) => {
            if (query) {
              setFilters({ search: query });
            } else if (searchQuery) {
              searchParams.delete('search');
              setSearchParams(searchParams);
            }
          }}
          searchQuery={searchQuery}
          placeholder={t('pages.hub-manager-view.search-input-placeholder')}
        />
        <div className="flex space-x-4">
          <MultiSelect
            options={countryOptions}
            onSelect={(countries: Array<string>) => setFilters({ country: countries })}
            variant="large"
            selectedOptions={selectedCountries}
            placeholder={t('pages.hub-manager-view.multi-select-placeholder.country')}
          />
          <MultiSelect
            options={cityOptions}
            onSelect={(cities: Array<string>) => setFilters({ city: cities })}
            variant="small"
            selectedOptions={selectedCities}
            placeholder={t('pages.hub-manager-view.multi-select-placeholder.city')}
          />
          <MultiSelect
            options={STATUS_OPTIONS}
            onSelect={(statuses: Array<string>) => setFilters({ status: statuses })}
            selectedOptions={selectedStatuses}
            placeholder={t('pages.hub-manager-view.multi-select-placeholder.status')}
          />
        </div>
        <ScheduledClosureSetting filteredHubs={filteredHubs} />
      </div>
      {isLoading ? (
        <div className="mx-4 my-4">
          <Spinner />
        </div>
      ) : (
        <div>
          {filteredHubs.length ? (
            filteredHubs.map((hub: ListHub) => <HubContainer key={hub.slug} hub={hub} />)
          ) : (
            <div>
              <p className=" text-white text-2xl font-bold pb-4">
                {t('pages.hub-manager-view.no-hub-displayed')}
              </p>
            </div>
          )}
        </div>
      )}
      <Outlet />
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default HubManagerView;
