const IconCloudUpload = (props) => {
  const { color = '#FFFFFF', width, height } = props;
  return (
    <div {...props}>
      <svg
        width={width || 24}
        height={height || 24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.82743 16.0013C3.36684 14.2923 2.36832 11.1388 3.39676 8.32496C4.42521 5.51112 7.2219 3.74479 10.2046 4.02525C13.1874 4.3057 15.6058 6.5624 16.0917 9.51863C16.2318 9.50867 16.3618 9.49862 16.5019 9.49862C18.6942 9.49786 20.5681 11.077 20.9389 13.2377C21.3098 15.3984 20.0696 17.512 18.0025 18.2423"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.0012 15.0013L12 12L8.99875 15.0013"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 12V20.0031"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
export default IconCloudUpload;
