import Toggle from 'components/Toggle';

type CardProps = {
  title: string;
  text?: string;
  borderColor: string;
  backgroundColor?: string;
  reason?: Array<string>;
  small?: boolean;
};

type CardWithToggleProps = CardProps & {
  toggleValue: boolean;
  onToggleChange: (value: boolean) => void;
};

const Card = ({
  title,
  text,
  borderColor,
  backgroundColor,
  small = false,
  ...restProps
}: CardProps | CardWithToggleProps) => {
  const fixedHeight = small ? 'h-fit' : 'h-18';
  return (
    <div
      className={`text-flinkGray-light w-fit items-center ${fixedHeight} py-3 px-2 border-l-8 flex rounded-md ${
        backgroundColor || 'bg-flinkGray-medium'
      } ${borderColor}`}
    >
      <div className="px-2">
        <p className="text-base text-white">{title}</p>
        <p className="text-sm	text-flinkGray-light">{text}</p>
      </div>
      {'onToggleChange' in restProps && (
        <div className="px-2 flex items-center">
          <Toggle
            dataTestid="card"
            onChange={restProps.onToggleChange}
            value={restProps.toggleValue}
          />
        </div>
      )}
    </div>
  );
};

export default Card;
