import { useCallback, useEffect, useRef, useState } from 'react';
import { IconArrowDownLg, IconArrowUpLg } from 'assets/icons';
import { useOutsideClick, useKeyPress, useTranslation } from 'hooks';
import ReactivateHubButton from './ReactivateHubButton';
import EmergencyClosureButton from './EmergencyClosureButton';
import CreateScheduledClosureButton from './CreateScheduledClosureButton';
import { ClosureReasons } from 'api/generated/apiSchemas';
import HubSettingsButton from './HubSettingsButton';

const HubSettingDropdownMenu = ({
  hubSlug,
  hubName,
  closureReasons,
  isHubDisabled,
  shouldDisableHubEmergencyClosure,
}: {
  hubSlug: string;
  hubName: string | null;
  closureReasons?: ClosureReasons;
  isHubDisabled?: boolean;
  shouldDisableHubEmergencyClosure?: boolean;
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const escape = useKeyPress('Escape');
  const [isOpen, setOpen] = useState(false);
  const Icon = isOpen ? IconArrowUpLg : IconArrowDownLg;

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (escape && isOpen) {
      onClose();
    }
  }, [escape, isOpen, onClose]);

  useOutsideClick(ref, () => {
    if (isOpen) {
      onClose();
    }
  });

  return (
    <div className="relative text-white" ref={ref}>
      <button
        className="flex grow items-center bg-flinkGray w-34 min-w-full h-14 rounded p-2 cursor-pointer"
        onClick={() => setOpen(!isOpen)}
        type="button"
      >
        <p className="grow px-1">{t('components.hub-setting-dropdown-menu.label')}</p>
        <Icon className="flex-none" />
      </button>
      {isOpen && (
        <div className="flex flex-col gap-6 absolute right-0 w-68 min-w-full min-h-fit rounded p-6 mt-2 bg-flinkGray">
          {isHubDisabled ? (
            <ReactivateHubButton hubSlug={hubSlug} />
          ) : (
            <EmergencyClosureButton
              hubSlug={hubSlug}
              hubName={hubName}
              shouldDisableHubEmergencyClosure={shouldDisableHubEmergencyClosure}
            />
          )}
          <CreateScheduledClosureButton hubSlug={hubSlug} closureReasons={closureReasons} />
          <HubSettingsButton />
        </div>
      )}
    </div>
  );
};

export default HubSettingDropdownMenu;
