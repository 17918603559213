import sdk from '@hubportal/sdk';
import { useEffect, useState } from 'react';

const useToken = () => {
  const [token, setToken] = useState<string | null>(null);
  useEffect(() => {
    sdk
      .getAuthClient()
      .getTokenSilently({ authorizationParams: { audience: process.env.BE_AUDIENCE } })
      .then((token: string) => setToken(token))
      .catch((error: unknown) => {
        sdk.reportError(
          new Error(String(`The token is not provided - ${error}`)),
          '@flapp/hub-manager'
        );
      });
  }, []);
  return token;
};

export default useToken;
