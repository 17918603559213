import sdk from '@hubportal/sdk';
import { PERMISSIONS } from 'lib/constants';

export const shouldEnableUpdatePDT = () => {
  const permission = sdk.getUserProfile().permissions.includes(PERMISSIONS.PDT);
  return !!permission;
};

export const shouldEnableUpdateHubConfig = () => {
  const permission = sdk.getUserProfile().permissions.includes(PERMISSIONS.CONFIGURATION);
  return !!permission;
};

export const shouldBeAbleToDeleteTurf = () => {
  const permission = sdk.getUserProfile().permissions.includes(PERMISSIONS.CONFIGURATION);
  return !!permission;
};

export const shouldBeAbleToUploadTurf = () => {
  const permission = sdk.getUserProfile().permissions.includes(PERMISSIONS.CONFIGURATION);
  return !!permission;
};

export const shouldBeAbleToUpdateTurfConfig = () => {
  const permission = sdk.getUserProfile().permissions.includes(PERMISSIONS.CONFIGURATION);
  return !!permission;
};

export const shouldBeAbleToViewAuditLogs = () => {
  const permission = sdk.getUserProfile().permissions.includes(PERMISSIONS.CONFIGURATION);
  return !!permission;
};
