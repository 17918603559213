import { ReactElement } from 'react';

type ModalProps = {
  title?: string;
  onClickOutside: () => void;
  customClass?: string;
  children: ReactElement;
};

const Modal = ({ title, onClickOutside, customClass, children }: ModalProps) => {
  const additionalClass = customClass ? customClass : 'h-auto overflow-hidden w-132';
  return (
    <div
      className="w-full h-full flex fixed bg-background box-border flex-row items-center justify-center overflow-hidden top-0 left-0 z-20 transition-all ease-in-out duration-2000 transform"
      role="presentation"
      onClick={onClickOutside}
    >
      <div
        className={`bg-flinkGray-medium p-8 rounded-lg ${additionalClass}`}
        role="presentation"
        onClick={(event) => event.stopPropagation()}
      >
        <p className="text-white fontFamily-flink font-bold text-2xl">{title}</p> {children}
      </div>
    </div>
  );
};

export default Modal;
