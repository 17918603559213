const IconUploadFailure = (props) => {
  const { color = '#FFFFFF', width, height } = props;
  return (
    <div {...props}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.75 5.205H12.529C12.198 5.205 11.888 5.041 11.702 4.767L10.797 3.437C10.611 3.164 10.302 3 9.971 3H7.25C6.145 3 5.25 3.895 5.25 5V13C5.25 14.105 6.145 15 7.25 15H16.75C17.855 15 18.75 14.105 18.75 13V7.205C18.75 6.101 17.855 5.205 16.75 5.205Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 15.02V16.52"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 20H20"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5 18.5L13.5 21.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5 21.5L13.5 18.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 20H8"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
export default IconUploadFailure;
