import { ApiContext } from './apiContext';

export type ErrorWrapper<TError> = TError | { status: number | 'unknown'; payload: string };

export type ApiFetcherOptions<TBody, THeaders, TQueryParams, TPathParams> = {
  url: string;
  method: string;
  body?: TBody;
  headers?: THeaders;
  queryParams?: TQueryParams;
  pathParams?: TPathParams;
  signal?: AbortSignal;
  customUrl?: boolean;
} & ApiContext['fetcherOptions'];

export async function apiFetch<
  TData,
  TError,
  TBody extends {} | undefined | null,
  THeaders extends {},
  TQueryParams extends {},
  TPathParams extends {}
>({
  url,
  method,
  body,
  headers,
  pathParams,
  queryParams,
  signal,
  customUrl = false,
}: ApiFetcherOptions<TBody, THeaders, TQueryParams, TPathParams>): Promise<TData> {
  const baseUrl = customUrl ? process.env.BE_AUDIENCE : process.env.BASE_URL;
  const formattedBody = body ? (customUrl ? (body as BodyInit) : JSON.stringify(body)) : undefined;
  try {
    const response = await window.fetch(`${baseUrl}${resolveUrl(url, queryParams, pathParams)}`, {
      signal,
      method: method.toUpperCase(),
      body: formattedBody,
      headers: customUrl
        ? headers
        : {
            'Content-Type': 'application/json',
            ...headers,
          },
    });
    if (!response.ok) {
      let error: ErrorWrapper<TError>;
      try {
        const result = await response.json();
        error = {
          status: response.status || ('unknown' as const),
          payload: result.error || 'Network error',
        };
      } catch (e) {
        error = {
          status: 'unknown' as const,
          payload: 'Unexpected error',
        };
      }

      throw error;
    }

    if (response.headers.get('content-type')?.includes('json')) {
      return await response.json();
    } else {
      // if it is not a json response, assume it is a blob and cast it to TData
      return (await response.blob()) as unknown as TData;
    }
  } catch (e) {
    throw e;
  }
}

const resolveUrl = (
  url: string,
  queryParams: Record<string, string> = {},
  pathParams: Record<string, string> = {}
) => {
  let query = new URLSearchParams(queryParams).toString();
  if (query) query = `?${query}`;
  return url.replace(/\{\w*\}/g, (key) => pathParams[key.slice(1, -1)]) + query;
};
