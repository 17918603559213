import TurfCard from 'components/TurfCard';
import { getTurfStatusConfigWithScheduledDelivery, TurfStatusIndicatorProps } from './helper';

const TurfStatusIndicator = ({
  turf,
  isHubEnabled,
  isHubActive,
  isInWorkingHour,
}: TurfStatusIndicatorProps) => {
  const turfStatuses = getTurfStatusConfigWithScheduledDelivery({
    turf,
    isHubEnabled,
    isHubActive,
    isInWorkingHour,
  });
  return (
    <div className="flex flex-wrap">
      {turfStatuses.map((item) => (
        <TurfCard key={item.title} title={item.title} icon={item.icon} tooltip={item.tooltip} />
      ))}
    </div>
  );
};

export default TurfStatusIndicator;
