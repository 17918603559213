import { useTranslation } from 'hooks';
import Toggle from 'components/Toggle';
import {
  ScheduledDeliverySetting,
  ScheduledDeliverySettings,
  Turf,
} from 'api/generated/apiSchemas';
import { usePatchTurfRequest } from 'api/generated/apiComponents';
import { notifyError, notifySuccess } from 'lib/toast';
import sdk from '@hubportal/sdk';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'lib/constants';
import { useState } from 'react';
import Modal from 'components/Modal';

type OrderPermissionsPropsType = {
  turf: Turf;
};

type ConfirmationModalPropsType = {
  handleUpdate: (enabled?: boolean) => void;
  onClickOutside: () => void;
};

const ConfirmationModal = ({ handleUpdate, onClickOutside }: ConfirmationModalPropsType) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t('components.delivery-settings.confirmation-modal.title')}
      onClickOutside={onClickOutside}
    >
      <div>
        <p className="text-white font-medium my-5">
          {t('components.delivery-settings.confirmation-modal.sub-title')}
        </p>
        <div className="flex justify-between">
          <button
            className="text-white w-56 p-4 rounded text-lg font-extrabold h-13 bg-buttonBgColor leading-none"
            onClick={() => handleUpdate()}
          >
            {t('components.delivery-settings.confirmation-modal.disabled')}
          </button>
          <button
            className="text-white w-56 p-4 rounded text-lg font-extrabold h-13 bg-flinkPink leading-none"
            onClick={() => handleUpdate(true)}
          >
            {t('components.delivery-settings.confirmation-modal.enable')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

const isScheduledDeliverySettingsEnabled = (deliverySetting?: ScheduledDeliverySetting) => {
  if (deliverySetting && deliverySetting.enabled) return true;
  return false;
};

const DeliverySettings = ({ turf }: OrderPermissionsPropsType) => {
  const { t } = useTranslation();
  const hubSlug = turf.hub_slug;
  const turfId = turf.id;
  const turfEnabled = !!turf.enabled;
  const currentScheduledDeliverySettings = turf.scheduled_delivery_settings;
  const instantDelivery = currentScheduledDeliverySettings
    ? isScheduledDeliverySettingsEnabled(currentScheduledDeliverySettings.instant_delivery)
    : false;
  const scheduledDelivery = currentScheduledDeliverySettings
    ? isScheduledDeliverySettingsEnabled(currentScheduledDeliverySettings.scheduled_delivery)
    : false;
  const [variables, setVariables] = useState({
    show: false,
    scheduledDeliverySettings: currentScheduledDeliverySettings,
  });

  const queryClient = useQueryClient();
  const { mutate: updateTurfDeliverySettings, isLoading } = usePatchTurfRequest();

  const handleUpdateTurfDeliverySettings = ({
    scheduledDeliverySettings,
    enabled,
  }: {
    scheduledDeliverySettings?: ScheduledDeliverySettings;
    enabled?: boolean;
  }) => {
    if (hubSlug && turfId && scheduledDeliverySettings) {
      updateTurfDeliverySettings(
        {
          body: { enabled, scheduled_delivery_settings: scheduledDeliverySettings },
          pathParams: { hubSlug, turfId },
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries({
              queryKey: QUERY_KEYS.GET_ALL_TURFS_REQUEST,
            });
            notifySuccess(t('toast.changes-applied-successfully'));
          },
          onError: (e) => {
            const errorMessage = `${t('toast.error')}${e.payload ? `:${e.payload}` : ''}`;
            sdk.reportError(new Error(String(e.payload)), '@flapp/hub-manager');
            notifyError(errorMessage);
          },
        }
      );
      setVariables({ show: false, scheduledDeliverySettings: undefined });
    }
  };

  const handleOnChange = ({
    scheduledDeliverySettings,
    shouldShowConfirmationModal,
  }: {
    scheduledDeliverySettings: ScheduledDeliverySettings;
    shouldShowConfirmationModal: boolean;
  }) => {
    if (shouldShowConfirmationModal) {
      return setVariables({ show: true, scheduledDeliverySettings });
    }
    return handleUpdateTurfDeliverySettings({ scheduledDeliverySettings });
  };

  return (
    <div className="my-4 w-full">
      <p className="text-2xl font-bold text-white">{t('components.delivery-settings.title')}</p>
      <div className="flex flex-col mt-3 divide-y divide-solid divide-flinkGray">
        <div className="flex items-center justify-between py-4">
          <div className="flex flex-col">
            <p className="text-lg font-medium text-white">
              {t('components.delivery-settings.asap-delivery.label')}
            </p>
            <p className="text-sm font-medium text-flinkGray-light">
              {t('components.delivery-settings.asap-delivery.description')}
            </p>
          </div>
          <Toggle
            label={t(`components.toggletoggle-label.${instantDelivery ? 'enabled' : 'disabled'}`)}
            onChange={() =>
              handleOnChange({
                scheduledDeliverySettings: {
                  ...currentScheduledDeliverySettings,
                  instant_delivery: { enabled: !instantDelivery },
                },
                shouldShowConfirmationModal: !turfEnabled && !instantDelivery,
              })
            }
            value={instantDelivery}
            disabled={isLoading}
            dataTestid="instantDelivery"
          />
        </div>
        <div className="flex items-center justify-between  py-4">
          <div className="flex flex-col">
            <p className="text-lg font-medium text-white">
              {t('components.delivery-settings.planned-delivery.label')}
            </p>
            <p className="text-sm font-medium text-flinkGray-light">
              {t('components.delivery-settings.planned-delivery.description')}
            </p>
          </div>
          <Toggle
            label={t(`components.toggletoggle-label.${scheduledDelivery ? 'enabled' : 'disabled'}`)}
            onChange={() =>
              handleOnChange({
                scheduledDeliverySettings: {
                  ...currentScheduledDeliverySettings,
                  scheduled_delivery: { enabled: !scheduledDelivery },
                },
                shouldShowConfirmationModal: !turfEnabled && !scheduledDelivery,
              })
            }
            value={scheduledDelivery}
            disabled={isLoading}
            dataTestid="scheduledDelivery"
          />
        </div>
      </div>
      {variables.show && (
        <ConfirmationModal
          handleUpdate={(enabled?: boolean) =>
            handleUpdateTurfDeliverySettings({
              scheduledDeliverySettings: variables.scheduledDeliverySettings,
              enabled,
            })
          }
          onClickOutside={() =>
            setVariables({
              show: false,
              scheduledDeliverySettings: undefined,
            })
          }
        />
      )}
    </div>
  );
};

export default DeliverySettings;
