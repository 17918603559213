const IconEarthPin = (props) => {
  const { color = '#C4C4C4' } = props;
  return (
    <div {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.51388 15H6C7.10457 15 8 14.1046 8 13V11C8 9.89544 8.89543 9.00001 10 9.00001H12C13.1046 9.00001 14 8.10458 14 7.00001V3.22469"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.499 17.25C17.3611 17.2506 17.2496 17.3627 17.25 17.5007C17.2504 17.6386 17.3624 17.7502 17.5003 17.75C17.6383 17.7498 17.75 17.6379 17.75 17.5C17.7504 17.4334 17.724 17.3695 17.6768 17.3225C17.6296 17.2755 17.5656 17.2494 17.499 17.25"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 17.5V17.5C14 15.567 15.567 14 17.5 14V14C19.433 14 21 15.567 21 17.5V17.5C21 19.1385 19.212 20.8888 18.192 21.7544C17.7892 22.0819 17.2118 22.0819 16.809 21.7544C15.788 20.8888 14 19.1385 14 17.5Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default IconEarthPin;
