import { useTranslation } from 'hooks';
import { SerializedHub } from './types';
import { Hub, SelectedHub } from './Hub';
import { Turf } from 'api/generated/apiSchemas';

type DeliveryAreasPropsType = {
  hub: SerializedHub;
  allTurfs?: Turf[];
  selectedHub: string;
  setSelectedHub: (slug: string) => void;
};

const DeliveryAreas = ({ hub, allTurfs, selectedHub, setSelectedHub }: DeliveryAreasPropsType) => {
  const { t } = useTranslation();

  if (!hub) return null;

  const position = hub.coordinates;

  if (!position) return null;

  const hubSlug = hub.slug;
  const hubName = hub.name;
  const hubStatus = hub.status;
  const workingHour = hub.workingHour;
  const formattedWorkingHours = workingHour
    ? `${t('components.delivery-area-page.city.working-hours.label')} ${workingHour.openTime} - ${
        workingHour.closeTime
      }`
    : '';
  const turfs = allTurfs?.filter((turfs) => turfs.hub_slug === hubSlug);
  const isSelectedHub = selectedHub === hubSlug;
  const isHubEnabled = hub.isHubEnabled;
  if (isSelectedHub) {
    return (
      <SelectedHub
        hubSlug={hubSlug}
        hubName={hubName}
        workingHour={formattedWorkingHours}
        hubStatus={hubStatus}
        position={position}
        isHubEnabled={isHubEnabled}
      />
    );
  }
  return (
    <Hub
      hubSlug={hubSlug}
      hubName={hubName}
      workingHour={formattedWorkingHours}
      hubStatus={hubStatus}
      position={position}
      isHubEnabled={isHubEnabled}
      setSelectedHub={setSelectedHub}
      turfs={turfs}
    />
  );
};

export default DeliveryAreas;
