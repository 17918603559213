import { useTranslation } from 'hooks';
import { useHubPatchRequest } from 'api/generated/apiComponents';
import { Coordinates } from 'api/generated/apiSchemas';
import { ChangeEvent, SyntheticEvent, useState } from 'react';
import { checkIfValidlatitudeAndlongitude } from 'lib/helpers';
import { HubDetail } from 'components/HubDrawer/serializer';
import isEqual from 'lodash.isequal';
import { notifyError, notifySuccess } from 'lib/toast';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'lib/constants';
import sdk from '@hubportal/sdk';

type HubCoordinatesPropsType = {
  hubCoordinates?: HubDetail['coordinates'];
  hubSlug: string;
};

const HubCoordinates = ({ hubCoordinates, hubSlug }: HubCoordinatesPropsType) => {
  const { t } = useTranslation();
  const { mutate } = useHubPatchRequest();
  const queryClient = useQueryClient();
  const currentCoordinates = {
    Lat: hubCoordinates?.lat,
    Long: hubCoordinates?.lng,
  };
  const [coordinates, setCoordinates] = useState<Coordinates>(currentCoordinates);
  const [isValid, setIsValid] = useState(true);
  const [hasChange, setHasChange] = useState(false);
  const handleUpdateLatitudeAndLongitude = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.currentTarget.value;
    const isValid = checkIfValidlatitudeAndlongitude(inputValue);
    const inputCoordinate = inputValue.split(',').map((element) => Number(element));
    const newCoordinates = { Lat: inputCoordinate[0], Long: inputCoordinate[1] };
    const hasChange = !isEqual(currentCoordinates, newCoordinates);
    setHasChange(hasChange);
    setCoordinates(newCoordinates);
    setIsValid(isValid);
  };

  const onSubmitHandler = (e: SyntheticEvent) => {
    e.preventDefault();
    mutate(
      {
        body: { coordinates },
        pathParams: { hubSlug },
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: QUERY_KEYS.GET_HUB_REQUEST,
          });
          setHasChange(false);
          notifySuccess(t('toast.changes-applied-successfully'));
        },
        onError: (e) => {
          const errorMessage = `${t('toast.error')}${e.payload ? `:${e.payload}` : ''}`;
          sdk.reportError(new Error(String(e.payload)), '@flapp/hub-manager');
          notifyError(errorMessage);
        },
      }
    );
  };

  return (
    <div className="my-4 w-full">
      <p className="text-2xl font-bold text-white">{t('components.hub-coordinates.title')}</p>
      <form action="#" onSubmit={onSubmitHandler}>
        <div className="flex gap-4 py-4">
          <input
            type="text"
            defaultValue={`${currentCoordinates.Lat}, ${currentCoordinates.Long}`}
            className={`${
              isValid ? 'border-flinkGray' : 'border-flinkPink'
            }  px-3 text-white text-sm	w-full h-12 rounded-lg`}
            onChange={handleUpdateLatitudeAndLongitude}
            aria-label="coordinates-input"
          />

          <button
            className="text-white bg-flinkPink w-56 leading-none p-4 rounded-lg fontFamily-sans text-lg font-extrabold h-12 disabled:bg-flinkGray-light"
            type="submit"
            disabled={!hasChange || !isValid}
          >
            {t('components.modal.save-button')}
          </button>
        </div>
      </form>
    </div>
  );
};

export default HubCoordinates;
