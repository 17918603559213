import useHubsClosureModal from 'stores/useHubsClosureModal';
import HubCreateScheduledClosureModal from 'components/HubCreateScheduledClosureModal';
import { useBatchClosureReasonPatchRequest } from 'api/generated/apiComponents';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'lib/constants';
import { notifyError, notifySuccess } from 'lib/toast';
import { useTranslation } from 'hooks';
import useBulkHubClosure from 'stores/useBulkHubClosure';
import { BatchUpdateClosureReasonRequest, ScheduleDates } from 'api/generated/apiSchemas';
import sdk from '@hubportal/sdk';

const HubsCreateScheduledClosureModal = () => {
  const { showHubsScheduledClosureModal, reset } = useHubsClosureModal();
  const { mutate, isLoading } = useBatchClosureReasonPatchRequest();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { selectedHubs, batchClosureReasons } = useBulkHubClosure();

  if (!showHubsScheduledClosureModal) return null;

  const handleMultiHubsScheduledClosure = ({
    reason,
    scheduledDate,
  }: {
    reason: string;
    scheduledDate: ScheduleDates;
  }) => {
    const newBatchClosureReasons: BatchUpdateClosureReasonRequest[] = selectedHubs.map((hub) => {
      const currentClosureReason = batchClosureReasons.find(
        (batchClosureReason) =>
          batchClosureReason.hub_slug === hub && batchClosureReason.reason === reason
      );
      const newScheduledDate =
        currentClosureReason && currentClosureReason.scheduled_dates
          ? [...currentClosureReason.scheduled_dates, scheduledDate]
          : [scheduledDate];
      return {
        hub_slug: hub,
        enabled: true,
        reason,
        scheduled_dates: newScheduledDate,
      };
    });

    mutate(
      {
        body: newBatchClosureReasons,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: QUERY_KEYS.HUBS,
          });
          notifySuccess(t('toast.changes-applied-successfully'));
        },
        onError: (e) => {
          const errorMessage = `${t('toast.error')}${e.payload ? `:${e.payload}` : ''}`;
          sdk.reportError(new Error(String(e.payload)), '@flapp/hub-manager');
          notifyError(errorMessage);
        },
        onSettled: () => reset(),
      }
    );
  };

  return (
    <HubCreateScheduledClosureModal
      onClickCancel={reset}
      updateScheduledClosure={handleMultiHubsScheduledClosure}
      isLoading={isLoading}
      multi
    />
  );
};

export default HubsCreateScheduledClosureModal;
