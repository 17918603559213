import { Turf } from 'api/generated/apiSchemas';
import { STATUS } from 'lib/constants';
import { getClosureReasonState } from 'lib/helpers';
import { Status } from 'lib/types';

export type TurfStatusIndicatorProps = {
  turf: Turf;
  isHubEnabled: boolean;
  isHubActive?: boolean;
  isInWorkingHour?: boolean;
};

type TurfStatusType = {
  title: string;
  icon: string;
  tooltip?: string;
};

type getTurfStatusConfigWithScheduledDelivery = {
  title: string;
  borderColor: string;
  reasons?: string[];
  status: Status;
};

export const getTurfStatusConfigWithTitleAndBorder = ({
  turf,
  isHubEnabled,
}: TurfStatusIndicatorProps): getTurfStatusConfigWithScheduledDelivery => {
  const isTurfEnabled = !!turf.enabled;
  const isPdtEnabled = !!turf.closure_rules?.pdt?.enabled;

  if (!isHubEnabled) {
    return {
      //Turf status: Closed manually - emergency closure is active
      title: 'components.turf-status-indicator.closed',
      borderColor: 'border-red',
      status: STATUS.CLOSED,
    };
  }

  if (!isTurfEnabled) {
    if (!isPdtEnabled) {
      return {
        //Turf status: Manually closed / Auto-Open is disabled
        title: 'components.turf-status-indicator.closed.auto-open-disabled',
        borderColor: 'border-red',
        status: STATUS.CLOSED,
      };
    } else {
      return {
        //Turf status: Manually closed / Auto-Open is enabled
        title: 'components.turf-status-indicator.closed.manually-closed',
        borderColor: 'border-red',
        status: STATUS.CLOSED,
      };
    }
  } else {
    const isTurfTemporaryClosed =
      turf.state?.closure_reasons &&
      Object.values(turf.state.closure_reasons).some((value) => getClosureReasonState(value));
    if (isTurfTemporaryClosed) {
      const filteredObjectReasons =
        turf.state?.closure_reasons &&
        Object.entries(turf.state.closure_reasons).filter(([, value]) => {
          return getClosureReasonState(value);
        });
      const filteredReasons = filteredObjectReasons?.map((reason) => reason[0]);
      const reasons = filteredReasons?.length ? { reasons: filteredReasons } : {};
      return {
        //Turf status: temporarily closed because of high PDT
        title: 'components.turf-status-indicator.temporary-closed',
        borderColor: 'border-orange',
        status: STATUS.CLOSED,
        ...reasons,
      };
    }
    if (!isPdtEnabled) {
      return {
        //Turf status: Operaional / Auto-close is disabled
        title: 'components.turf-status-indicator.operational.auto-close-disabled',
        borderColor: 'border-veggieGreen-dark',
        status: STATUS.OPERATIONAL,
      };
    }
    return {
      //Turf status: Operational - Everything is enabled and operational
      title: 'components.turf-status-indicator.operational',
      borderColor: 'border-veggieGreen-dark',
      status: STATUS.OPERATIONAL,
    };
  }
};

export const getTurfStatusConfigWithScheduledDelivery = ({
  turf,
  isHubEnabled,
  isHubActive,
  isInWorkingHour,
}: TurfStatusIndicatorProps): TurfStatusType[] => {
  const isTurfEnabled = !!turf.enabled;
  const isPdtEnabled = !!turf.closure_rules?.pdt?.enabled;
  const instantDelivery = turf.scheduled_delivery_settings?.instant_delivery?.enabled;
  const scheduledDelivery = turf.scheduled_delivery_settings?.scheduled_delivery?.enabled;
  const isHighPDT = turf.state?.closure_reasons?.high_pdt?.enabled;

  if (!isHubActive) {
    return [
      {
        //Turf status: Inactive - The hub is not currently operating.
        title: 'components.turf-status-indicator.inactive',
        icon: 'close',
        tooltip: 'components.turf-status-indicator.inactive.tooltip',
      },
    ];
  }

  if (!isInWorkingHour) {
    return [
      {
        //Turf status: Closed - The hub is currently outside of its working hours.
        title: 'components.turf-status-indicator.closed',
        icon: 'close',
        tooltip: 'components.turf-status-indicator.outside-business-hour.tooltip',
      },
    ];
  }

  if (!isHubEnabled) {
    return [
      {
        //Turf status: Closed - The hub is closed.
        title: 'components.turf-status-indicator.closed',
        icon: 'close',
        tooltip: 'components.turf-status-indicator.closed.tooltip',
      },
    ];
  }

  if (!isTurfEnabled) {
    return [
      {
        //Turf status: Manually closed - The turf is closed manually.
        title: 'components.turf-status-indicator.manually-closed',
        icon: 'close',
        tooltip: 'components.turf-status-indicator.manually-closed.tooltip',
      },
      {
        //Turf status: Auto-Open is enabled/disabled
        title: `components.turf-status-indicator.closed.${
          isPdtEnabled ? 'auto-open-enabled' : 'auto-open-disabled'
        }`,
        icon: isPdtEnabled ? 'check' : 'close',
      },
    ];
  } else {
    if (isHighPDT) {
      return [
        {
          //Turf status: High PDT - ASAP delivery will not be available until the PDT is low again.
          title: 'components.turf-status-indicator.high-pdt',
          icon: 'alert',
          tooltip: 'components.turf-status-indicator.high-pdt.tooltip',
        },
        {
          //Turf status: Planned Delivery enabled
          title: 'components.turf-status-indicator.planned-delivery',
          icon: scheduledDelivery ? 'check' : 'close',
        },
      ];
    }

    const status: TurfStatusType[] = [
      {
        //Turf status: ASAP Delivery enabled/disabled
        title: 'components.turf-status-indicator.asap-delivery',
        icon: instantDelivery ? 'check' : 'close',
      },
      {
        //Turf status: Planned Delivery enabled/disabled
        title: 'components.turf-status-indicator.planned-delivery',
        icon: scheduledDelivery ? 'check' : 'close',
      },
    ];

    if (!isPdtEnabled && instantDelivery) {
      status.push({
        //Turf status: Auto close disabled
        title: 'components.turf-status-indicator.auto-close-disabled',
        icon: 'close',
      });
    }

    return status;
  }
};
