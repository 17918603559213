import { IconArrowDownLg, IconArrowUpLg, IconSettings } from 'assets/icons';
import { useTranslation } from 'hooks';
import { getWorkingHours } from 'lib/helpers';
import TurfStatusIndicator from 'components/TurfStatusIndicator';
import { Turf } from 'api/generated/apiSchemas';
import { LatLngLiteral } from 'leaflet';
import TurfDetails from 'components/TurfDetails';
import { memo, useState } from 'react';
import TurfEnableToggleSwitch from 'components/TurfEnableToggleSwitch';
import { shouldBeAbleToUpdateTurfConfig } from 'lib/permissions';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ROUTE_PATHS } from 'lib/constants';

type TurfOverViewPropsType = {
  turf: Turf;
  isHubEnabled: boolean;
  isHubActive: boolean;
  isInWorkingHour: boolean;
};

const TurfOverView = ({
  turf,
  isHubEnabled,
  isHubActive,
  isInWorkingHour,
}: TurfOverViewPropsType) => {
  const { t } = useTranslation();
  const turfWorkingHour = getWorkingHours(turf.working_hours);
  return (
    <div>
      <p className="flex flex-col gap-1 pb-3">
        <span className="text-2xl font-bold text-white" data-testid="turfName">
          {turf.name}
        </span>
        <span className="text-sm text-flinkGray-light" data-testid="turfId">
          ({turf.id})
        </span>
      </p>
      {turfWorkingHour ? (
        <p className="text-base font-medium pb-4">
          {`${t('components.turf-management-card.working-hours.label')} ${
            turfWorkingHour.openTime
          } - ${turfWorkingHour.closeTime}`}
        </p>
      ) : null}
      <TurfStatusIndicator
        turf={turf}
        isHubEnabled={isHubEnabled}
        isHubActive={isHubActive}
        isInWorkingHour={isInWorkingHour}
      />
    </div>
  );
};

const TurfSettingButton = ({ turf }: { turf: Turf }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { slug } = useParams();
  const turfId = turf.id;

  return (
    <button
      className="ml-4 h-10 w-10 bg-flinkGray-medium px-2 flex items-center rounded-full"
      data-testid="hubSettingButton"
      onClick={() => {
        if (state?.background) {
          navigate(`${ROUTE_PATHS.HUB_MANAGER}/${slug}/${turfId}`, {
            state: { background: state.background },
          });
        } else {
          navigate(`${ROUTE_PATHS.HUB_MANAGER}/${slug}/${turfId}`);
        }
      }}
    >
      <IconSettings />
    </button>
  );
};

const TurfManagementCard = ({
  turf,
  isHubEnabled,
  hubLocation,
  isHubActive,
  isInWorkingHour,
}: {
  turf: Turf;
  isHubEnabled: boolean;
  isHubActive: boolean;
  isInWorkingHour: boolean;
  hubLocation?: LatLngLiteral;
}) => {
  const [showMore, setShowMore] = useState(false);
  const ShowMoreIcon = showMore ? IconArrowUpLg : IconArrowDownLg;
  const hasPermission = shouldBeAbleToUpdateTurfConfig();
  return (
    <div className="bg-flinkGray mt-6 h-fit rounded-lg" data-testid="turf-management-card">
      <div className="rounded-lg p-6 flex items-center justify-between">
        <TurfOverView
          turf={turf}
          isHubEnabled={isHubEnabled}
          isHubActive={isHubActive}
          isInWorkingHour={isInWorkingHour}
        />
        <div className="flex items-center">
          <TurfEnableToggleSwitch turf={turf} />
          {hasPermission ? <TurfSettingButton turf={turf} /> : null}
          <ShowMoreIcon
            className="cursor-pointer pl-12 h-20 flex items-center"
            onClick={() => setShowMore(!showMore)}
            data-testid={showMore ? 'icon-arrow-up' : 'icon-arrow-down'}
          />
        </div>
      </div>
      {showMore && <TurfDetails turf={turf} hubLocation={hubLocation} />}
    </div>
  );
};

export default memo(TurfManagementCard);
