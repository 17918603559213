import { Line, Turf, Turfs } from 'api/generated/apiSchemas';
import 'react-toastify/dist/ReactToastify.css';
import { Icon, LatLngLiteral, Marker as MarkerType } from 'leaflet';
import { Marker, Polygon, Polyline, Popup, Tooltip, useMap } from 'react-leaflet';
import { useEffect, useMemo, useRef } from 'react';
import { ROUTE_PATHS, STATUS } from 'lib/constants';
import { useNavigate } from 'react-router-dom';
import { getTurfStatusConfigWithTitleAndBorder } from 'components/TurfStatusIndicator/helper';
import { HubPropsType } from './types';
import hubLocationPin from 'assets/icons/hub-location-pin.svg';
import { useTranslation } from 'hooks';
import truncate from '@turf/truncate';
import union from '@turf/union';
import { polygon, Position } from '@turf/helpers';
import { useGetAllTurfsRequest } from 'api/generated/apiComponents';

const HubLocationPinIcon = new Icon({
  iconUrl: hubLocationPin,
  iconSize: [18, 25],
  iconAnchor: [9, 25],
});

const SelectedHubLocationPinIcon = new Icon({
  iconUrl: hubLocationPin,
  iconSize: [36, 51],
  iconAnchor: [18, 51],
});

const TurfPolygon = ({ turf, isHubEnabled }: { turf: Turf; isHubEnabled?: boolean }) => {
  if (!turf || !turf.area) return null;

  const { status } = getTurfStatusConfigWithTitleAndBorder({
    turf: turf,
    isHubEnabled: !!isHubEnabled,
  });

  const getAdditionalClass = () => {
    if (status === STATUS.CLOSED) return 'fill-flinkGray-medium stroke-flinkGray-medium';
    return 'fill-flinkPink stroke-flinkPink';
  };

  const additionalClass = getAdditionalClass();
  const formattedTurf = turf.area.map((turf) =>
    turf.map((t) => ({ lat: t.lat, lng: t.long } as LatLngLiteral))
  );
  return (
    <>
      {formattedTurf?.map((turf, i) => (
        <>{turf ? <Polygon key={i} positions={turf} className={additionalClass} /> : null}</>
      ))}
    </>
  );
};

const TurfPolygos = ({ turfs, isHubEnabled }: { turfs: Turfs; isHubEnabled?: boolean }) => {
  const deliveryAreas = turfs.map((turf) => turf.area).flat() as Line[];
  const formattedTurf = deliveryAreas.map((deliveryArea) => {
    const positions = deliveryArea.map<Position>((point) => [point.lat, point.long] as Position);
    return polygon([positions]);
  });
  const unionOfTurf = useMemo(() => {
    if (!formattedTurf.length) return null;
    let unions = formattedTurf[0] as any;
    formattedTurf.forEach((polygon) => {
      unions = union(truncate(unions), truncate(polygon));
    });
    return unions;
  }, [formattedTurf]);

  return (
    <>
      {turfs.map((turf) => {
        return <TurfPolygon key={turf.id} turf={turf} isHubEnabled={isHubEnabled} />;
      })}
      {unionOfTurf?.geometry ? (
        <Polyline positions={unionOfTurf.geometry.coordinates} className="stroke-info-dark" />
      ) : null}
    </>
  );
};

export const SelectedHub = ({
  hubSlug,
  hubName,
  workingHour,
  hubStatus,
  position,
  isHubEnabled,
}: HubPropsType) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const markerRef = useRef<MarkerType>(null);
  const map = useMap();

  const { data: selectedTurfs } = useGetAllTurfsRequest<Turf[]>({
    pathParams: { hubSlug },
  });

  useEffect(() => {
    const marker = markerRef.current;
    if (marker) {
      marker.openPopup();
      map.setView(position, 13);
    }
  }, [map, position, selectedTurfs]);

  if (!selectedTurfs) return null;

  return (
    <>
      <Marker
        ref={markerRef}
        position={position}
        icon={SelectedHubLocationPinIcon}
        zIndexOffset={-1}
      >
        <Popup>
          <button
            className="hover:cursor-pointer font-medium underline"
            onClick={() => navigate(`${ROUTE_PATHS.HUB_MANAGER}/${hubSlug}`)}
          >
            {hubName} ({hubSlug})
          </button>
          <br />
          {workingHour}
          <br />
          {t('components.delivery-area-page.hub.hub-status.label')} {hubStatus}
        </Popup>
      </Marker>
      {selectedTurfs.map((selectedTurf) => (
        <TurfPolygon key={selectedTurf.id} turf={selectedTurf} isHubEnabled={isHubEnabled} />
      ))}
    </>
  );
};

export const Hub = ({
  hubSlug,
  hubName,
  workingHour,
  hubStatus,
  position,
  turfs,
  isHubEnabled,
  setSelectedHub,
}: HubPropsType & {
  turfs?: Turfs;
  setSelectedHub: (slug: string) => void;
}) => {
  const { t } = useTranslation();
  const markerRef = useRef<MarkerType>(null);
  const marker = markerRef.current;

  return (
    <>
      <Marker
        ref={markerRef}
        position={position}
        icon={HubLocationPinIcon}
        zIndexOffset={-1}
        eventHandlers={{
          click: () => {
            setSelectedHub(hubSlug);
            if (marker) marker.closeTooltip();
          },
        }}
      >
        <Tooltip>
          {hubName} ({hubSlug})<br />
          {workingHour}
          <br />
          {t('components.delivery-area-page.hub.hub-status.label')} {hubStatus}
        </Tooltip>
      </Marker>
      {turfs ? <TurfPolygos turfs={turfs} isHubEnabled={isHubEnabled} /> : null}
    </>
  );
};
