import { ListHub } from 'api/generated/apiSchemas';
import { getHubStatus } from 'pages/HubManagerView/helper';
import { SerializedHub } from './types';
import { getWorkingHours } from 'lib/helpers';
import { latLng } from 'leaflet';

export const serializedHubs = (hubs: ListHub[]): SerializedHub[] => {
  const serializedHubs = hubs
    .filter((hub) => hub.slug && !hub.slug.includes('all_skus'))
    .map<SerializedHub>((hub) => {
      const status = getHubStatus(hub);
      const workingHour = getWorkingHours(hub.working_hours);
      const coordinates =
        hub.coordinates?.Lat && hub.coordinates?.Long
          ? latLng(hub.coordinates.Lat, hub.coordinates.Long)
          : null;
      return {
        slug: hub.slug || '',
        name: hub.name || '',
        city: hub.city || '',
        country: hub.country || '',
        workingHour,
        status,
        isHubEnabled: !hub.state?.force_close,
        coordinates,
      };
    });
  return serializedHubs;
};
