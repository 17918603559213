import { useUploadKmlFile } from 'api/custom/useUploadKmlFile';
import { IconCloudUpload } from 'assets/icons';
import React, { ChangeEvent, useRef, useState } from 'react';
import { useTranslation } from 'hooks';
import { useParams } from 'react-router-dom';
import useTurfsArea from 'stores/useTurfsArea';
import sdk from '@hubportal/sdk';
import UploadFileLoading from 'components/UploadFileLoading';
import UploadFileError from 'components/UploadFileError';

const fileTypes = /(\.kml)$/i;

function validFileType(file: File) {
  return fileTypes.exec(file.name);
}

const DragAndDropFile = () => {
  const { t } = useTranslation();
  const [hasFormatError, setHasFormatError] = useState(false);
  const [hasUploadKmlFileError, setHasUploadKmlFileError] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const { slug } = useParams();
  const { mutate, isLoading } = useUploadKmlFile();
  const { setSelectedTurfs, backToUploadTurfsPage, isHubcorrespondent } = useTurfsArea();

  const uploadKmlFile = (kmlFile: File) => {
    const formData = new FormData();
    formData.append('file', kmlFile);
    mutate(
      {
        body: formData,
      },
      {
        onSuccess: (data) => {
          setSelectedTurfs(data, slug);
        },
        onError: (e: any) => {
          backToUploadTurfsPage();
          sdk.reportError(new Error(String(e.payload)), '@flapp/hub-manager');
          setHasUploadKmlFileError(true);
        },
      }
    );
  };

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    backToUploadTurfsPage();
    if (event.dataTransfer.files) {
      if (!validFileType(event.dataTransfer.files[0])) {
        setHasFormatError(true);
      } else {
        const kmlFile = event.dataTransfer.files[0];
        uploadKmlFile(kmlFile);
        setHasFormatError(false);
      }
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    backToUploadTurfsPage();
    if (event.target.files) {
      if (!validFileType(event.target.files[0])) {
        setHasFormatError(true);
      } else {
        const kmlFile = event.target.files[0];
        uploadKmlFile(kmlFile);
        setHasFormatError(false);
      }
    }
  };

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  if (isLoading) {
    return <UploadFileLoading />;
  }

  if (hasUploadKmlFileError) {
    return <UploadFileError reset={() => setHasUploadKmlFileError(false)} />;
  }

  return (
    <div
      className="flex flex-col items-center justify-center bg-flinkGray h-96 my-12 rounded-lg text-white"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <IconCloudUpload width={132} height={132} style={{ margin: '32px' }} />
      <label htmlFor="file-upload" className="text-center font-medium my-2">
        {t('components.drag-and-drop-file.label')} <br />
        {t('components.drag-and-drop-file.sub-label')}
      </label>
      <input
        type="file"
        id="file-upload"
        name="file-upload"
        accept=".kml"
        onChange={handleChange}
        hidden
        ref={inputRef}
      />
      <button
        className="font-extrabold bg-flinkGray-medium w-36 h-14 min-w-fit h-fit rounded-lg p-3 mt-2 mb-4"
        onClick={handleClick}
      >
        {t('components.drag-and-drop-file.button')}
      </button>
      {hasFormatError && (
        <span className="text-red font-medium mb-4">
          {t('components.drag-and-drop-file.format-error-message')}
        </span>
      )}
      {isHubcorrespondent === 'not-correspondent' && (
        <span className="text-red font-medium mb-4">
          {t('components.drag-and-drop-file.not-correspondent')}
        </span>
      )}
    </div>
  );
};

export default DragAndDropFile;
