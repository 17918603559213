import { UploadKmlFileResponse } from 'api/custom/useUploadKmlFile';
import {
  BatchScheduledChange,
  BatchUpdateClosureReasonRequest,
  ListHub,
  Turf,
} from 'api/generated/apiSchemas';

export const HUBS_CLOSURE = {
  SCHEDULED: 'scheduled',
  EMERGENCY: 'emergency',
} as const;

export type HubsClosure = (typeof HUBS_CLOSURE)[keyof typeof HUBS_CLOSURE];

export type BulkHubClosureStore = {
  selectedHubs: string[];
  isSelectedAllHubs: boolean;
  batchClosureReasons: BatchUpdateClosureReasonRequest[];
};

export type BulkHubClosureWithMethods = BulkHubClosureStore & {
  resetBulkHubClosure(): void;
  setSelectedHubs(selectedHub?: string): void;
  setSelectedAllHubs(selectedHub: ListHub[]): void;
  resetSelectedHubs(): void;
  setBatchClosureReasons(selectedHub: ListHub[]): void;
};

export type HubsClosureModalStore = {
  showHubsScheduledClosureModal: boolean;
  showHubsEmergencyClosureModal: boolean;
  showSelectedHubsList: boolean;
  preModal: HubsClosure | null;
};

export type HubsClosureModalStoreWithMethods = HubsClosureModalStore & {
  toggleModal(type?: HubsClosure): void;
  openHubsScheduledClosureModal(): void;
  openHubsEmergencyClosureModal(): void;
  reset(): void;
};

export type TurfsAreaStore = {
  selectedTurfs: Array<BatchScheduledChange & { name?: string }> | null;
  currentExistingTurfs: Turf[] | null;
  isHubcorrespondent: 'undefined' | 'correspondent' | 'not-correspondent';
  isAllTUrfsMatched: 'undefined' | 'matched' | 'not-matched';
  includesNewTurf: boolean;
};

export type TurfsAreaStoreWithMethods = TurfsAreaStore & {
  setSelectedTurfs(turfs?: UploadKmlFileResponse, slug?: string | null): void;
  setCurrentExistingTurfs(turfs?: Turf[]): void;
  backToUploadTurfsPage(): void;
  reset(): void;
};
