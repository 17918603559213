import { DEFAULT_DATE_TIME } from 'lib/constants';
import { getZonedDate } from 'lib/helpers';

export type Option = {
  value: string;
  label: string;
};
export const daySelectionValues = {
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  CUSTOM: 'custom',
};

export const DaySelectOptions = [
  { label: 'Today', value: daySelectionValues.TODAY },
  { label: 'Yesterday', value: daySelectionValues.YESTERDAY },
  { label: 'Custom', value: daySelectionValues.CUSTOM },
];

export const TODAY = getZonedDate();
export const YESTERDAY = new Date();
YESTERDAY.setDate(YESTERDAY.getDate() - 1);

export const DEFAULT_PAGE = 0;
export const DEFAULT_PAGE_SIZE = 50;
export const theLastHour = { value: '24:00', label: '24:00' };
export const todaySelection = { value: daySelectionValues.TODAY, label: 'Today' };
export const DEFAULT_FILTER = {
  query: '',
  eventType: '',
  selectedDateRange: [TODAY, TODAY],
  daySelection: todaySelection,
  startTime: DEFAULT_DATE_TIME.startTime,
  endTime: theLastHour,
};
