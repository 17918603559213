import { getFormattedDate, getFormattedTime } from 'lib/helpers';
import { AuditLog } from 'api/generated/apiSchemas';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from 'lib/constants';
import { useTranslation } from 'hooks';

const AuditLogsContainer = ({ log }: { log: AuditLog }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="grid grid-cols-5 h-16 border-b border-lightBlackBorder">
      <button
        className="flex items-center text-white text-sm underline"
        onClick={() =>
          navigate(`${ROUTE_PATHS.HUB_MANAGER}/${log.hub_slug}`, {
            state: { background: location },
          })
        }
      >
        {log.hub_slug ?? '-'}
      </button>
      {log.turf_name ? (
        <button
          className="flex items-center text-white text-sm underline"
          onClick={() =>
            navigate(`${ROUTE_PATHS.HUB_MANAGER}/${log.hub_slug}`, {
              state: { background: location },
            })
          }
        >
          {log.turf_name}
        </button>
      ) : (
        <p className="flex items-center">-</p>
      )}
      <div className="flex items-center">
        <div className="text-flinkGray-light">
          <p className="text-sm">{getFormattedDate(log.created_at)} </p>
          <p className="text-xs">{getFormattedTime(log.created_at)} </p>
        </div>
      </div>
      <div className="flex items-center text-flinkGray-light text-sm">
        {t(`components.audit-logs.event.${log.event_type}`)}
      </div>
      <div className="flex items-center text-flinkGray-light text-sm">{log.actor_email ?? '-'}</div>
    </div>
  );
};

export default AuditLogsContainer;
