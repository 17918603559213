import { IconInfo } from 'assets/icons';
import ImportTurfChangesDialogBox from 'components/ImportTurfChangesDialogBox';
import { useTranslation } from 'hooks';
import useTurfsArea from 'stores/useTurfsArea';

const WarningMessage = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-buttonBgColor my-6 py-4 h-fit rounded-lg flex">
      <div className="px-4 flex items-center">
        <IconInfo color="#FFF0E0" bgColor="#EC7400" />
      </div>
      <div>
        <p className="text-base text-white font-medium">
          {t('components.imported-turf-details-container.title')}
        </p>
        <div className="flex">
          <p className="text-base text-white font-medium">
            {t('components.imported-turf-details-container.sub-title')}
          </p>
          <a
            href="https://goflink.atlassian.net/servicedesk/customer/portal/24"
            className="text-base font-medium underline text-flinkPink mx-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            Tech Ops
          </a>
          <p className="text-base text-white font-medium">
            {t('components.imported-turf-details-container.sub-title-part-two')}
          </p>
        </div>
      </div>
    </div>
  );
};

const UploadedExistingTurfs = () => {
  const { t } = useTranslation();
  const { currentExistingTurfs } = useTurfsArea();
  return (
    <div className="bg-buttonBgColor my-6 py-4 h-fit rounded-lg flex">
      <div className="px-4 flex items-center">
        <IconInfo color="#FFF0E0" bgColor="#EC7400" />
      </div>
      <div>
        <p className="text-base text-white font-medium">
          {t('components.imported-turf-details-container.warning')}
        </p>
        <div className="flex flex-wrap">
          {currentExistingTurfs?.map((turf) => (
            <div key={turf.id} className="py-1">
              <div>
                <p className="px-4 flex cursor-pointer items-center mr-2 h-10 bg-flinkGray-medium rounded text-white">
                  {turf.name}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

type ImportedTurfDetailsContainerPropsType = {
  onCloseImportTurfDrawer: () => void;
};

const ImportedTurfDetailsContainer = ({
  onCloseImportTurfDrawer,
}: ImportedTurfDetailsContainerPropsType) => {
  const { t } = useTranslation();
  const { selectedTurfs, backToUploadTurfsPage, isAllTUrfsMatched, includesNewTurf } =
    useTurfsArea();

  if (isAllTUrfsMatched === 'matched' && selectedTurfs?.length)
    return <ImportTurfChangesDialogBox onCloseImportTurfDrawer={onCloseImportTurfDrawer} />;

  return (
    <>
      {includesNewTurf && <WarningMessage />}
      <UploadedExistingTurfs />
      <div className="flex justify-end">
        <button
          className="flex items-center justify-center text-white bg-flinkGray leading-none p-4 rounded fontFamily-sans text-lg font-extrabold h-13 w-24"
          onClick={backToUploadTurfsPage}
        >
          {t('components.import-turf-changes-dialog-box.cancel')}
        </button>
      </div>
    </>
  );
};

export default ImportedTurfDetailsContainer;
