import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import Root from './root.component';
import sdk from '@hubportal/sdk';
import singleSpaCss from 'single-spa-css';
import '@hubportal/components/index.css';

const cssLifecycles = singleSpaCss({
  cssUrls: [],
  webpackExtractedCss: false,
  shouldUnmount: true,
});

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err: Error, info: React.ErrorInfo) {
    sdk.reportError(err, '@flapp/hub-manager', info);
    return (
      <div>
        <h1>Something went wrong.</h1>
      </div>
    );
  },
});

export const bootstrap = [cssLifecycles.bootstrap, reactLifecycles.bootstrap];

export const mount = [cssLifecycles.mount, reactLifecycles.mount];

export const unmount = [reactLifecycles.unmount, cssLifecycles.unmount];
