import { ClosureReasons, Hub, WorkingHours } from 'api/generated/apiSchemas';
import { LatLngLiteral } from 'leaflet';
import { getWorkingHours, WorkingHour } from 'lib/helpers';

export interface HubDetail {
  id: string | null;
  name: string | null;
  address: string | null;
  forceClose: boolean;
  closureReasons?: ClosureReasons;
  clickAndCollectActive: boolean;
  coordinates?: LatLngLiteral;
  workingHour: WorkingHour;
  workingHours?: WorkingHours[];
  openDate?: string | null;
  closeDate?: string | null;
  gln?: string | null;
}
export const serializeHub = (hub: Hub): HubDetail => {
  const coordinates = hub.lat && hub.long ? { lat: hub.lat, lng: hub.long } : undefined;
  const workingHour = getWorkingHours(hub.working_hours);
  const adressLine = `${hub.address_line1} ${hub.address_line2}`;
  const address = [adressLine.trim(), hub.pincode, hub.city];
  return {
    id: hub.id ?? null,
    name: hub.name ?? null,
    address: address.filter((item) => item).join(', '),
    forceClose: !!hub.force_close?.active,
    closureReasons: hub.closure_reasons ?? undefined,
    clickAndCollectActive: !!hub.click_and_collect_active,
    coordinates,
    workingHour,
    workingHours: hub.working_hours ?? undefined,
    openDate: hub.open_date ?? null,
    closeDate: hub.close_date ?? null,
    gln: hub.gln ?? null,
  };
};
