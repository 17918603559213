import { IconTurfStatus } from 'assets/icons';
import { useTranslation } from 'hooks';

type TurfCardProps = {
  title: string;
  icon?: string;
  tooltip?: string;
};

const TurfCard = ({ title, icon, tooltip }: TurfCardProps) => {
  const { t } = useTranslation();
  return (
    <div className="w-fit items-center h-10 py-3 px-2 my-2 mr-4 flex rounded bg-flinkGray-medium group relative">
      <div className="px-2 flex items-center">
        <IconTurfStatus name={icon} />
        <p className="ml-2.5 text-sm	text-white font-medium">{t(title)}</p>
      </div>
      {tooltip && (
        <div className="pointer-events-none w-max absolute bottom-14 invisible group-hover:visible group-hover:z-50">
          <div className="relative bg-flinkGray-medium text-flinkGray-light rounded-lg flex items-center px-4 py-2">
            {t(tooltip)}
            <div className="absolute h-4 w-4 left-5 -bottom-2 bg-flinkGray-medium rotate-45" />
          </div>
        </div>
      )}
    </div>
  );
};
export default TurfCard;
