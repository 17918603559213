import { useRef } from 'react';
import { useOutsideClick, useTranslation } from 'hooks';
import { IconArrowLeft } from 'assets/icons';
import DragAndDropFile from 'components/DragAndDropFile';
import useTurfsArea from 'stores/useTurfsArea';
import ImportedTurfDetailsContainer from 'components/ImportedTurfDetailsContainer';
import { shouldBeAbleToUploadTurf } from 'lib/permissions';

type ImportTurfDrawerProps = {
  onClose: () => void;
};

type NavigationPropsType = {
  onClick: () => void;
  label: string;
};

const Navigation = ({ onClick, label }: NavigationPropsType) => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-between">
      <div className="flex items-center pt-2">
        <button
          className="mr-4 h-10 w-10 bg-flinkGray-dark px-2 flex items-center rounded-full"
          onClick={onClick}
          data-testid="back-button"
        >
          <IconArrowLeft />
        </button>
        <p className="text-4xl font-bold fontFamily-flink text-white">{t(label)}</p>
      </div>
    </div>
  );
};

const UploadTurfsPage = ({ onClose }: ImportTurfDrawerProps) => {
  return (
    <>
      <Navigation onClick={onClose} label="components.import-turf-drawer.upload-turf-navigation" />
      <DragAndDropFile />
    </>
  );
};

const ConfirmUploadTurfPage = ({ onClose }: ImportTurfDrawerProps) => {
  const { backToUploadTurfsPage } = useTurfsArea();

  return (
    <>
      <Navigation
        onClick={backToUploadTurfsPage}
        label="components.import-turf-drawer.confirm-change-turfs-area"
      />
      <ImportedTurfDetailsContainer onCloseImportTurfDrawer={onClose} />
    </>
  );
};

const ImportTurfDrawer = ({ onClose }: ImportTurfDrawerProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { reset, isHubcorrespondent } = useTurfsArea();
  const showImportedTurfDetailsPage = isHubcorrespondent === 'correspondent';

  useOutsideClick(ref, () => {
    onClose();
    reset();
  });

  const isUploadTurfActivated = shouldBeAbleToUploadTurf();
  if (!isUploadTurfActivated) return null;

  return (
    <div
      className="w-full h-full flex fixed bg-background box-border flex-row items-center justify-end overflow-hidden top-0 right-0 z-20 transition-all ease-in-out duration-2000 transform"
      role="presentation"
      data-testid="turfImportId"
    >
      <div
        className="bg-flinkGray-medium overflow-y-auto overflow-x-hidden w-3/5 h-full px-6 py-11"
        role="presentation"
        ref={ref}
      >
        {showImportedTurfDetailsPage ? (
          <ConfirmUploadTurfPage onClose={onClose} />
        ) : (
          <UploadTurfsPage onClose={onClose} />
        )}
      </div>
    </div>
  );
};

export default ImportTurfDrawer;
