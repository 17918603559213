import HubDrawer from 'components/HubDrawer';
import Layout from 'components/Layout';
import AuditLogsPage from 'pages/AuditLogsPage';
import DeliveryAreaPage from 'pages/DeliveryAreaPage';
import HubManagerView from 'pages/HubManagerView';
import HubSettingsPage from 'pages/HubSettingsPage';
import TurfSettingsPage from 'pages/TurfSettingsPage';
import { Outlet, Route, Routes, useLocation } from 'react-router-dom';

const App = () => {
  const location = useLocation();
  const background = location?.state?.background;
  return (
    <div id="hp-hub-manager-module">
      <Routes location={background || location}>
        <Route element={<Layout />}>
          <Route
            path="/"
            element={
              <div id="hp-hub-manager-module">
                <Outlet />
              </div>
            }
          />
          <Route path="hub-manager" element={<HubManagerView />}>
            <Route path=":slug" element={<HubDrawer />} />
            <Route path=":slug/hub-settings" element={<HubSettingsPage />} />
            <Route path=":slug/:turfId" element={<TurfSettingsPage />} />
          </Route>
          <Route path="hub-manager/delivery-area" element={<DeliveryAreaPage />} />
          <Route path="hub-manager/audit-logs" element={<AuditLogsPage />} />
        </Route>
      </Routes>
      {background && (
        <Routes>
          <Route path="hub-manager/:slug" element={<HubDrawer />} />
          <Route path="hub-manager/:slug/hub-settings" element={<HubSettingsPage />} />
          <Route path="hub-manager/:slug/:turfId" element={<TurfSettingsPage />} />
        </Routes>
      )}
    </div>
  );
};

export default App;
