import { Turf } from 'api/generated/apiSchemas';
import TurfManagementCard from 'components/TurfManagementCard';
import { useTranslation } from 'hooks';
import { LatLngLiteral } from 'leaflet';
import { WorkingHour, configIsHubActive, isTimeNowInsideWorkingHour } from 'lib/helpers';
import { memo } from 'react';

export type TurfsContainerProps = {
  turfs?: Turf[];
  hubCoordinates?: LatLngLiteral;
  isHubDisabled?: boolean;
  workingHour?: WorkingHour;
  openDate?: string | null;
  closeDate?: string | null;
};

const TurfsContainer = ({
  turfs,
  hubCoordinates,
  isHubDisabled,
  workingHour,
  openDate,
  closeDate,
}: TurfsContainerProps) => {
  const { t } = useTranslation();

  if (!turfs || turfs.length == 0) {
    return (
      <div>
        <p className=" text-white text-2xl font-bold pb-4">
          {t('components.turfoperationalmodal.no-turf-available')}
        </p>
      </div>
    );
  }
  const sortedTurf = turfs.sort((a, b) => {
    const turfA = a.name || '';
    const turfB = b.name || '';
    return turfA > turfB ? 1 : -1;
  });
  const isHubActive = configIsHubActive({
    openDate: openDate,
    closeDate: closeDate,
  });
  const isInWorkingHour = isTimeNowInsideWorkingHour(workingHour);

  return (
    <div>
      {sortedTurf.map((turf) => (
        <TurfManagementCard
          key={turf.id}
          turf={turf}
          isHubEnabled={!isHubDisabled}
          isHubActive={isHubActive}
          isInWorkingHour={isInWorkingHour}
          hubLocation={hubCoordinates}
        />
      ))}
    </div>
  );
};

export default memo(TurfsContainer);
