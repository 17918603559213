import { ListHub } from 'api/generated/apiSchemas';
import BulkActionBar from 'components/BulkActionBar';
import { useTranslation } from 'hooks';
import useBulkHubClosure from 'stores/useBulkHubClosure';
import { IconCalender } from 'assets/icons';
import ScheduledClosureLogsModal from 'components/ScheduledClosureLogsModal';
import { useState } from 'react';

const SelectedAllHubsCheckBox = ({ filteredHubs }: { filteredHubs: ListHub[] }) => {
  const { t } = useTranslation();
  const { isSelectedAllHubs, setSelectedAllHubs } = useBulkHubClosure();

  return (
    <div className="flex items-center px-4 gap-x-4 w-34 min-w-fit h-12 bg-buttonBgColor rounded-lg">
      <input
        type="checkbox"
        className="bg-transparent hover:cursor-pointer checked:bg-flinkPink checked:border-none"
        checked={isSelectedAllHubs}
        onClick={() => setSelectedAllHubs(filteredHubs)}
      />
      <label className="text-white">{t('pages.hub-manager-view.bulk-actions.select-all')}</label>
    </div>
  );
};

const ScheduledClosureLogs = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <button
        className="flex items-center px-4 gap-x-4 w-34 min-w-fit h-12 bg-buttonBgColor rounded-lg text-white"
        onClick={() => setIsOpen(true)}
      >
        <div className="relative">
          <span className="absolute rounded-full h-2 w-2 bg-flinkPink z-10 -top-0.5 -right-0.5" />
          <IconCalender />
        </div>
        {t('pages.hub-manager-view.button.scheduled-closures')}
      </button>
      {isOpen ? <ScheduledClosureLogsModal onClose={() => setIsOpen(false)} /> : null}
    </>
  );
};

const NumberOfHubsIndicator = ({ numberOfHubs }: { numberOfHubs?: number }) => {
  const { t } = useTranslation();
  if (!numberOfHubs) return null;
  return (
    <p className="text-white text-base">
      {t('pages.hub-manager-view.numberOfHubs', { count: numberOfHubs })}
    </p>
  );
};

const ScheduledClosureSetting = ({ filteredHubs }: { filteredHubs: ListHub[] }) => {
  return (
    <>
      <BulkActionBar />
      <div className="flex justify-between items-center w-3/5 mt-6">
        <div className="flex gap-6">
          <SelectedAllHubsCheckBox filteredHubs={filteredHubs} />
          <ScheduledClosureLogs />
        </div>
        <NumberOfHubsIndicator numberOfHubs={filteredHubs.length} />
      </div>
    </>
  );
};

export default ScheduledClosureSetting;
