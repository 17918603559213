import { useQueryClient } from '@tanstack/react-query';
import { useHubPatchRequest } from 'api/generated/apiComponents';
import { QUERY_KEYS } from 'lib/constants';
import { notifyError, notifySuccess } from 'lib/toast';
import { useTranslation } from 'hooks';
import HubEmergencyClosureModal from 'components/HubEmergencyClosureModal';
import { useState } from 'react';
import sdk from '@hubportal/sdk';
import { EmergencyClosureReasons } from 'lib/types';

const EmergencyClosureButton = ({
  hubSlug,
  hubName,
  shouldDisableHubEmergencyClosure,
}: {
  hubSlug: string;
  hubName: string | null;
  shouldDisableHubEmergencyClosure?: boolean;
}) => {
  const { t } = useTranslation();
  const [isHubEmergencyClosureModalVisible, setIsHubEmergencyClosureModalVisible] = useState(false);
  const toggleEmergencyClosureModal = () => {
    setIsHubEmergencyClosureModalVisible(!isHubEmergencyClosureModalVisible);
  };

  const { mutate } = useHubPatchRequest();
  const queryClient = useQueryClient();

  const handleEmergencyClosure = (reason?: EmergencyClosureReasons) => {
    const comment = reason ? { comment: reason } : null;

    mutate(
      {
        body: { force_close: { active: true, ...comment } },
        pathParams: { hubSlug },
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: QUERY_KEYS.GET_HUB_REQUEST,
          });
          await queryClient.invalidateQueries({
            queryKey: QUERY_KEYS.HUBS,
          });
          notifySuccess(t('toast.changes-applied-successfully'));
        },
        onError: (e) => {
          const errorMessage = `${t('toast.error')}${e.payload ? `:${e.payload}` : ''}`;
          sdk.reportError(new Error(String(e.payload)), '@flapp/hub-manager');
          notifyError(errorMessage);
        },
        onSettled: () => toggleEmergencyClosureModal(),
      }
    );
  };

  return (
    <>
      <button
        className="flex-1 text-end hover:text-flinkPink disabled:text-flinkGray-light"
        disabled={shouldDisableHubEmergencyClosure}
        onClick={toggleEmergencyClosureModal}
      >
        {t('components.hub-setting-dropdown-menu.emergency-closure.button')}
      </button>
      {isHubEmergencyClosureModalVisible && (
        <HubEmergencyClosureModal
          onClickCancel={toggleEmergencyClosureModal}
          hubName={hubName}
          updateHubState={handleEmergencyClosure}
        />
      )}
    </>
  );
};

export default EmergencyClosureButton;
