import HubsCreateScheduledClosureModal from 'components/HubsCreateScheduledClosureModal';
import HubsEmergencyClosureModal from 'components/HubsEmergencyClosureModal';
import SelectedHubsListModal from 'components/SelectedHubsListModal';
import useHubsClosureModal from 'stores/useHubsClosureModal';

const HubsClosureModal = () => {
  const { showSelectedHubsList, showHubsEmergencyClosureModal, showHubsScheduledClosureModal } =
    useHubsClosureModal();
  if (showSelectedHubsList) return <SelectedHubsListModal />;
  if (showHubsScheduledClosureModal) return <HubsCreateScheduledClosureModal />;
  if (showHubsEmergencyClosureModal) return <HubsEmergencyClosureModal />;

  return null;
};

export default HubsClosureModal;
