import { IconUploadFailure } from 'assets/icons';
import { useTranslation } from 'hooks';

type UploadFileErrorPropsType = {
  reset: () => void;
};

const UploadFileError = ({ reset }: UploadFileErrorPropsType) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center bg-flinkGray h-96 my-12 rounded-lg text-white">
      <IconUploadFailure color="#D8232A" width={132} height={132} style={{ margin: '32px' }} />
      <span className="font-medium mt-2 mb-1">{t('components.upload-file-error.info')}</span>
      <span className="mb-2">{t('components.upload-file-error.sub-info')}</span>
      <button
        className="font-extrabold bg-flinkPink w-36 h-14 min-w-fit h-fit rounded-lg p-3 mt-2 mb-4"
        onClick={reset}
      >
        {t('components.upload-file-error.button')}
      </button>
    </div>
  );
};

export default UploadFileError;
