import { ClosureReasons } from 'api/generated/apiSchemas';
import { IconInfo } from 'assets/icons';
import { useState } from 'react';
import { useTranslation } from 'hooks';
import ScheduledClosuresListModal from './ScheduledClosuresListModal';
import { configScheduledClosuresValid } from 'lib/helpers';

type ScheduledClosuresInfoBarPropsType = {
  hubSlug: string;
  closureReasons?: ClosureReasons;
};

const ScheduledClosuresInfoBar = ({
  hubSlug,
  closureReasons,
}: ScheduledClosuresInfoBarPropsType) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const currentScheduledClosures = closureReasons
    ? Object.entries(closureReasons).filter(([, closureReason]) =>
        configScheduledClosuresValid(closureReason)
      )
    : undefined;

  if (!hubSlug || !closureReasons || !currentScheduledClosures || !currentScheduledClosures.length)
    return null;

  const filteredcurrentScheduledClosures = Object.fromEntries(currentScheduledClosures);

  return (
    <div className="mt-4 text-white flex justify-between items-center h-14 bg-buttonBgColor rounded-lg p-4">
      <div className="flex justify-between items-center gap-4">
        <IconInfo color="#FFF0E0" bgColor="#EC7400" />
        <p className="text-white font-medium">
          {t('components.scheduled-closures-info-bar.info-text')}
        </p>
      </div>
      <button
        className="bg-flinkGray-medium font-medium w-34 min-w-fit h-10 rounded-lg p-2 cursor-pointer"
        onClick={() => setOpen(true)}
        type="button"
      >
        {t('components.scheduled-closures-info-bar.action-button')}
      </button>
      {isOpen && (
        <ScheduledClosuresListModal
          hubSlug={hubSlug}
          closureReasons={closureReasons}
          filteredcurrentScheduledClosures={filteredcurrentScheduledClosures}
          onClose={() => setOpen(false)}
        />
      )}
    </div>
  );
};

export default ScheduledClosuresInfoBar;
