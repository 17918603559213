import { create } from 'zustand';
import { TurfsAreaStore, TurfsAreaStoreWithMethods } from './types';
import isEqual from 'lodash.isequal';

const initialState: TurfsAreaStore = {
  selectedTurfs: null,
  currentExistingTurfs: null,
  isHubcorrespondent: 'undefined',
  isAllTUrfsMatched: 'undefined',
  includesNewTurf: false,
};

const useTurfsArea = create<TurfsAreaStoreWithMethods>()((set, get) => ({
  ...initialState,
  setSelectedTurfs(turfs, slug) {
    const isHubcorrespondent = turfs?.every((turf) => turf.hub_slug === slug);
    if (isHubcorrespondent) {
      const { currentExistingTurfs } = get();
      const currentExistingTurfsName = currentExistingTurfs?.map((turf) => turf.name) || [];
      const uploadedTurfsName = turfs?.map((turf) => turf.name) || [];
      const includesNewTurf = uploadedTurfsName.some(
        (turf) => !currentExistingTurfsName.includes(turf)
      );
      const isAllTurfsMatched = isEqual(currentExistingTurfsName, uploadedTurfsName);
      if (isAllTurfsMatched) {
        const turfsWithId = turfs?.length
          ? turfs.map((existingTurf) => {
              const turfId = currentExistingTurfs?.find(
                (currentExistingTurf) => currentExistingTurf.name === existingTurf.name
              )?.id;
              return {
                ...existingTurf,
                turf_id: turfId,
              };
            })
          : null;
        set((currentState) => ({
          ...currentState,
          selectedTurfs: turfsWithId,
          isAllTUrfsMatched: 'matched',
          isHubcorrespondent: 'correspondent',
          includesNewTurf: false,
        }));
      } else {
        set((currentState) => ({
          ...currentState,
          isAllTUrfsMatched: 'not-matched',
          isHubcorrespondent: 'correspondent',
          includesNewTurf,
        }));
      }
    } else {
      set((currentState) => ({
        ...currentState,
        isHubcorrespondent: 'not-correspondent',
      }));
    }
  },
  setCurrentExistingTurfs(turfs) {
    set((currentState) => ({ ...currentState, currentExistingTurfs: turfs }));
  },
  backToUploadTurfsPage() {
    const { currentExistingTurfs } = get();
    set({ ...initialState, currentExistingTurfs });
  },
  reset() {
    set(initialState);
  },
}));

export default useTurfsArea;
