import { ClosureReasons } from 'api/generated/apiSchemas';
import Modal from 'components/Modal';
import { useTranslation } from 'hooks';
import ScheduledClosureList from './ScheduledClosureList';
import { isScheduledClosuresValid } from 'lib/helpers';

type ScheduledClosuresListModalPropsType = {
  hubSlug: string;
  closureReasons: ClosureReasons;
  filteredcurrentScheduledClosures: ClosureReasons;
  onClose: () => void;
};

const ScheduledClosuresListModal = ({
  hubSlug,
  closureReasons,
  filteredcurrentScheduledClosures,
  onClose,
}: ScheduledClosuresListModalPropsType) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t('components.scheduled-closures-info-bar.modal-title')}
      onClickOutside={onClose}
      customClass="w-132 max-h-700 overflow-visible"
    >
      <div className="mt-6 flex flex-col max-h-500 overflow-auto">
        {Object.entries(filteredcurrentScheduledClosures).map(([key, value]) => {
          const scheduledDates = value.scheduled_dates;
          const validScheduledDates = scheduledDates?.filter((scheduledDate) =>
            isScheduledClosuresValid(scheduledDate)
          );
          return (
            <ScheduledClosureList
              key={key}
              hubSlug={hubSlug}
              closureReasons={closureReasons}
              reason={key}
              scheduledDates={validScheduledDates}
            />
          );
        })}
      </div>
    </Modal>
  );
};

export default ScheduledClosuresListModal;
