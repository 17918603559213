import { useQueryClient } from '@tanstack/react-query';
import { useClosureReasonPatchRequest } from 'api/generated/apiComponents';
import { QUERY_KEYS } from 'lib/constants';
import { notifyError, notifySuccess } from 'lib/toast';
import { useTranslation } from 'hooks';
import HubCreateScheduledClosureModal from 'components/HubCreateScheduledClosureModal';
import { useState } from 'react';
import { ClosureReasons, ScheduleDates } from 'api/generated/apiSchemas';
import sdk from '@hubportal/sdk';

const CreateScheduledClosureButton = ({
  hubSlug,
  closureReasons,
}: {
  hubSlug: string;
  closureReasons?: ClosureReasons;
}) => {
  const { t } = useTranslation();
  const { mutate, isLoading } = useClosureReasonPatchRequest();
  const queryClient = useQueryClient();

  const [isHubCreateScheduledClosureModalVisible, setIsHubCreateScheduledClosureModalVisible] =
    useState(false);
  const toggleHubCreateScheduledClosureModal = () => {
    setIsHubCreateScheduledClosureModalVisible(!isHubCreateScheduledClosureModalVisible);
  };

  const handleCreateScheduledClosure = ({
    reason,
    scheduledDate,
  }: {
    reason: string;
    scheduledDate: ScheduleDates;
  }) => {
    const newClosureReasons: ClosureReasons = {
      ...closureReasons,
    };
    const newScheduledDate =
      newClosureReasons[reason].scheduled_dates && newClosureReasons[reason].scheduled_dates.length
        ? [...newClosureReasons[reason].scheduled_dates, scheduledDate]
        : [scheduledDate];
    newClosureReasons[reason] = {
      ...newClosureReasons[reason],
      enabled: true,
      scheduled_dates: newScheduledDate,
    };

    mutate(
      {
        body: newClosureReasons,
        pathParams: { hubSlug },
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: QUERY_KEYS.GET_HUB_REQUEST,
          });
          notifySuccess(t('toast.changes-applied-successfully'));
        },
        onError: (e) => {
          const errorMessage = `${t('toast.error')}${e.payload ? `:${e.payload}` : ''}`;
          sdk.reportError(new Error(String(e.payload)), '@flapp/hub-manager');
          notifyError(errorMessage);
        },
        onSettled: () => toggleHubCreateScheduledClosureModal(),
      }
    );
  };

  return (
    <>
      <button
        className="flex-1 text-end hover:text-flinkPink"
        onClick={toggleHubCreateScheduledClosureModal}
      >
        {t('components.hub-setting-dropdown-menu.create-scheduled-closure.button')}
      </button>
      {isHubCreateScheduledClosureModalVisible && (
        <HubCreateScheduledClosureModal
          onClickCancel={toggleHubCreateScheduledClosureModal}
          updateScheduledClosure={handleCreateScheduledClosure}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default CreateScheduledClosureButton;
