import { memo, useState } from 'react';
import { useTranslation } from 'hooks';
import TurfDeleteModal from 'components/TurfDeleteModal';

type TurfDeleteButtonPropsType = { hubSlug?: string; turfId?: string };

const TurfDeleteButton = ({ hubSlug, turfId }: TurfDeleteButtonPropsType) => {
  const { t } = useTranslation();
  const [isDeleteTurfModalVisible, setIsDeleteTurfModalVisible] = useState(false);

  const toggleDeleteTurfModal = () => {
    setIsDeleteTurfModalVisible((isDeleteTurfModalVisible) => !isDeleteTurfModalVisible);
  };

  return (
    <>
      <button
        className="mr-2 flex items-center justify-center text-white bg-flinkPink leading-none p-4 rounded fontFamily-sans text-lg font-extrabold h-13 disabled:bg-flinkGray-light w-42"
        onClick={toggleDeleteTurfModal}
      >
        {t('components.turf-management-card.button.delete-turf')}
      </button>
      {isDeleteTurfModalVisible ? (
        <TurfDeleteModal onClickCancel={toggleDeleteTurfModal} hubSlug={hubSlug} turfId={turfId} />
      ) : null}
    </>
  );
};

export default memo(TurfDeleteButton);
