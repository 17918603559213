const IconInfo = (props) => {
  const { color = '#BFBFBF', bgColor } = props;
  return (
    <div {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="10" fill={bgColor} />
        <rect x="11.252" y="6.65601" width="1.49664" height="7" rx="0.748318" fill={color} />
        <rect x="11" y="15.344" width="2" height="2" rx="1" fill={color} />
      </svg>
    </div>
  );
};
export default IconInfo;
