import { create } from 'zustand';
import { HUBS_CLOSURE, HubsClosureModalStore, HubsClosureModalStoreWithMethods } from './types';

const initialState: HubsClosureModalStore = {
  showHubsScheduledClosureModal: false,
  showHubsEmergencyClosureModal: false,
  preModal: null,
  showSelectedHubsList: false,
};

const useHubsClosureModal = create<HubsClosureModalStoreWithMethods>()((set, get) => ({
  ...initialState,
  toggleModal(type) {
    const {
      preModal,
      showSelectedHubsList,
      openHubsScheduledClosureModal,
      openHubsEmergencyClosureModal,
    } = get();
    if (showSelectedHubsList) {
      if (preModal === HUBS_CLOSURE.SCHEDULED) {
        openHubsScheduledClosureModal();
      }
      if (preModal === HUBS_CLOSURE.EMERGENCY) {
        openHubsEmergencyClosureModal();
      }
    } else {
      set({
        ...initialState,
        preModal: type,
        showSelectedHubsList: true,
      });
    }
  },
  openHubsScheduledClosureModal() {
    set({
      ...initialState,
      showHubsScheduledClosureModal: true,
    });
  },
  openHubsEmergencyClosureModal() {
    set({
      ...initialState,
      showHubsEmergencyClosureModal: true,
    });
  },
  reset() {
    set(initialState);
  },
}));

export default useHubsClosureModal;
