import { useState, useMemo } from 'react';
import { useCreateTurfRequest } from '../../api/generated/apiComponents';
import { Turf as BackendTurf, Turf } from '../../api/generated/apiSchemas';
import { useTranslation } from 'hooks';
import { PREDEFINED_TURFS, EXEMPTED_TURFS, QUERY_KEYS, COUNTRIES } from 'lib/constants';
import { useQueryClient } from '@tanstack/react-query';
import MultiSelect from 'components/MultiSelect';

const CreateTurfButton = ({ hubSlug, turfs }: { hubSlug: string; turfs?: Turf[] }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [selectedTurfs, setSelectedTurfs] = useState<string[]>([]);
  const { mutateAsync: createTurf } = useCreateTurfRequest();

  const availableTurfs = useMemo(() => {
    const createdTurfNames = (turfs || [])
      .map((turf) => turf.name)
      .filter((name): name is string => name !== '');

    const countryPrefix = COUNTRIES.find((country) =>
      hubSlug.startsWith(country.value.toLowerCase())
    )?.value;

    const exemptedTurfs = countryPrefix ? EXEMPTED_TURFS[countryPrefix] || [] : [];

    return PREDEFINED_TURFS.filter(
      (turf) => !createdTurfNames.includes(turf.id) && !exemptedTurfs.includes(turf.id)
    ).map((turf) => ({ value: turf.id, label: turf.label }));
  }, [hubSlug, turfs]);

  const handleSelect = (selectedValues: string[]) => {
    setSelectedTurfs(selectedValues);
  };

  const handleCreateTurf = async () => {
    if (!selectedTurfs.length) return;

    const turfArray: BackendTurf[] = selectedTurfs.map((turfId) => ({
      enabled: false,
      name: turfId,
      area: [[]],
    }));

    await createTurf({ pathParams: { hubSlug }, body: turfArray as BackendTurf });
    setSelectedTurfs([]);
    await queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.GET_ALL_TURFS_REQUEST,
    });
  };

  return (
    <div className="flex items-center space-x-4">
      <div className="w-40 h-12 bg-gray-800 text-white rounded-lg flex items-center">
        <MultiSelect
          options={availableTurfs}
          onSelect={handleSelect}
          selectedOptions={availableTurfs.filter((option) => selectedTurfs.includes(option.value))}
          placeholder={t('components.select-turf.action-button')}
        />
      </div>
      <button
        className={`flex items-center justify-center text-white bg-flinkPink px-4 py-2 rounded-lg text-lg font-extrabold h-12 w-40 ${
          !selectedTurfs.length ? 'opacity-50 cursor-not-allowed' : ''
        }`}
        onClick={handleCreateTurf}
        aria-label={t('components.create-turf.action-button')}
        disabled={!selectedTurfs.length}
      >
        {t('components.create-turf.action-button')}
      </button>
    </div>
  );
};

export default CreateTurfButton;
