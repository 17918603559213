import { shouldEnableUpdateHubConfig } from 'lib/permissions';
import { useTranslation } from 'hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ROUTE_PATHS } from 'lib/constants';

const HubSettingsButton = () => {
  const { t } = useTranslation();
  const hasPermission = shouldEnableUpdateHubConfig();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { slug } = useParams();

  if (!hasPermission) return null;

  return (
    <button
      className="flex-1 text-end hover:text-flinkPink disabled:text-flinkGray-light"
      onClick={() => {
        if (state?.background) {
          navigate(`${ROUTE_PATHS.HUB_MANAGER}/${slug}${ROUTE_PATHS.HUB_SETTINGS}`, {
            state: { background: state.background },
          });
        } else {
          navigate(`${ROUTE_PATHS.HUB_MANAGER}/${slug}${ROUTE_PATHS.HUB_SETTINGS}`);
        }
      }}
    >
      {t('components.hub-setting-dropdown-menu.hub-settings.button')}
    </button>
  );
};

export default HubSettingsButton;
