import { useCallback, useEffect, useRef, useState } from 'react';
import { IconArrowDownLg, IconArrowUpLg } from 'assets/icons';
import { useTranslation, useOutsideClick, useKeyPress } from 'hooks';
import useBulkHubClosure from 'stores/useBulkHubClosure';
import useHubsClosureModal from 'stores/useHubsClosureModal';
import HubsClosureModal from 'components/HubsClosureModal';

const BulkActionBar = () => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const escape = useKeyPress('Escape');
  const [isOpen, setOpen] = useState(false);
  const Icon = isOpen ? IconArrowUpLg : IconArrowDownLg;
  const { selectedHubs } = useBulkHubClosure();
  const { openHubsScheduledClosureModal, openHubsEmergencyClosureModal } = useHubsClosureModal();
  const numberOfSelectedHubs = selectedHubs.length;

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (escape && isOpen) {
      onClose();
    }
  }, [escape, isOpen, onClose]);

  useOutsideClick(ref, () => {
    if (isOpen) {
      onClose();
    }
  });

  if (!numberOfSelectedHubs) return null;

  return (
    <div className="text-white font-medium flex justify-between items-center w-3/5 h-18 mt-6 bg-buttonBgColor rounded-lg p-4">
      <p className="text-white font-medium">
        {t('components.bulk-action-bar.numberOfSelectedHubs', { count: numberOfSelectedHubs })}
      </p>

      <div className="relative" ref={ref}>
        <button
          className="flex justify-between items-center bg-flinkGray-medium w-28 min-w-fit h-10 rounded-lg p-2 cursor-pointer"
          onClick={() => setOpen(!isOpen)}
          type="button"
        >
          {t('components.bulk-action-bar.action-button')}
          <Icon />
        </button>
        {isOpen && (
          <div className="flex flex-col absolute w-64 min-w-fit h-28 rounded-lg p-6 mt-2 bg-flinkGray">
            <button
              className="flex-1 text-end hover:text-flinkPink"
              onClick={openHubsEmergencyClosureModal}
            >
              {t('components.bulk-action-bar.action.close')}
            </button>
            <button
              className="flex-1 text-end hover:text-flinkPink"
              onClick={openHubsScheduledClosureModal}
            >
              {t('components.bulk-action-bar.action.scheduled-closure')}
            </button>
          </div>
        )}
      </div>
      <HubsClosureModal />
    </div>
  );
};

export default BulkActionBar;
