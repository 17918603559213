import { BatchUpdateClosureReasonRequest, ListHub } from 'api/generated/apiSchemas';
import { enableDevTool } from 'utils/flags';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { BulkHubClosureStore, BulkHubClosureWithMethods } from './types';

const initialState: BulkHubClosureStore = {
  selectedHubs: [],
  isSelectedAllHubs: false,
  batchClosureReasons: [],
};

const useBulkHubClosure = create<BulkHubClosureWithMethods>()(
  devtools(
    (set, get) => ({
      ...initialState,
      resetBulkHubClosure() {
        set(initialState);
      },
      setSelectedHubs(selectedHub) {
        const { selectedHubs } = get();
        if (selectedHub) {
          const newSelectedHubs = selectedHubs.includes(selectedHub)
            ? selectedHubs.filter((hub) => hub !== selectedHub)
            : [...selectedHubs, selectedHub];
          set((currentState) => ({
            ...currentState,
            isSelectedAllHubs: false,
            selectedHubs: newSelectedHubs,
          }));
        }
      },
      setSelectedAllHubs(hubs) {
        const { isSelectedAllHubs } = get();
        if (isSelectedAllHubs) {
          set((currentState) => ({ ...currentState, isSelectedAllHubs: false, selectedHubs: [] }));
        } else {
          const newSelectedHubs = hubs.map((hub: ListHub) => hub.slug ?? '').filter((slug) => slug);
          set((currentState) => ({
            ...currentState,
            isSelectedAllHubs: true,
            selectedHubs: newSelectedHubs,
          }));
        }
      },
      resetSelectedHubs() {
        set((currentState) => ({ ...currentState, isSelectedAllHubs: false, selectedHubs: [] }));
      },
      setBatchClosureReasons(hubs) {
        const newBatchClosureReasons: BatchUpdateClosureReasonRequest[] = [];
        hubs?.forEach((hub) => {
          if (hub && hub.state?.closure_reasons) {
            Object.entries(hub.state.closure_reasons).forEach(([key, value]) => {
              if (value.enabled && value.scheduled_dates?.length) {
                const batchClosureReasonRequest: BatchUpdateClosureReasonRequest = {
                  hub_slug: hub.slug,
                  reason: key,
                  scheduled_dates: value.scheduled_dates,
                };
                newBatchClosureReasons.push(batchClosureReasonRequest);
              }
            });
          }
        });
        set((currentState) => ({
          ...currentState,
          batchClosureReasons: newBatchClosureReasons,
        }));
      },
    }),
    { enabled: enableDevTool }
  )
);

export default useBulkHubClosure;
