import { useHubPatchRequest } from 'api/generated/apiComponents';
import Card from 'components/Card';
import { useTranslation } from 'hooks';

import { QUERY_KEYS } from 'lib/constants';
import { notifyError, notifySuccess } from 'lib/toast';
import { useQueryClient } from '@tanstack/react-query';
import sdk from '@hubportal/sdk';

type ClickAndCollectProp = {
  isClickAndCollectActive: boolean;
  hubSlug: string;
};

const ClickAndCollect = ({ isClickAndCollectActive, hubSlug }: ClickAndCollectProp) => {
  const { mutate } = useHubPatchRequest();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const handleUpdateClickAndCollect = () => {
    mutate(
      {
        body: { click_and_collect_active: !isClickAndCollectActive },
        pathParams: { hubSlug },
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: QUERY_KEYS.GET_HUB_REQUEST,
          });
          await queryClient.invalidateQueries({
            queryKey: QUERY_KEYS.HUBS,
          });
          notifySuccess(t('toast.changes-applied-successfully'));
        },
        onError: (e) => {
          const errorMessage = `${t('toast.error')}${e.payload ? `:${e.payload}` : ''}`;
          sdk.reportError(new Error(String(e.payload)), '@flapp/hub-manager');
          notifyError(errorMessage);
        },
      }
    );
  };

  return (
    <Card
      backgroundColor="bg-flinkGray-dark"
      borderColor={isClickAndCollectActive ? 'border-veggieGreen-dark' : 'border-flinkGray-light'}
      title={t('components.turfoperationalmodal.click-and-collect.toggle-button')}
      text={t(
        `components.turfoperationalmodal.click-and-collect.${
          isClickAndCollectActive ? 'enabled' : 'disabled'
        }`
      )}
      toggleValue={isClickAndCollectActive}
      onToggleChange={() => handleUpdateClickAndCollect()}
    />
  );
};
export default ClickAndCollect;
