import Toggle from 'components/Toggle';
import { useTranslation } from 'hooks';
import { Turf } from 'api/generated/apiSchemas';
import { usePatchTurfRequest } from 'api/generated/apiComponents';
import { useQueryClient } from '@tanstack/react-query';
import { shouldBeAbleToUpdateTurfConfig, shouldEnableUpdatePDT } from 'lib/permissions';
import { QUERY_KEYS } from 'lib/constants';
import { notifySuccess, notifyError } from 'lib/toast';
import { useCallback } from 'react';
import sdk from '@hubportal/sdk';

type TurfEnableToggleSwitchPropsType = { turf: Turf };

const TurfEnableToggleSwitch = ({ turf }: TurfEnableToggleSwitchPropsType) => {
  const { t } = useTranslation();
  const isTurfEnabled = !!turf.enabled;
  const hubSlug = turf.hub_slug;
  const turfId = turf.id;
  const enableUpdatePDT = shouldEnableUpdatePDT();
  const { mutate: updateTurfEnableDisable } = usePatchTurfRequest();
  const queryClient = useQueryClient();
  const scheduledDeliverySettings = turf.scheduled_delivery_settings;
  const isInstantDeliveryEnabled =
    scheduledDeliverySettings &&
    scheduledDeliverySettings.instant_delivery &&
    scheduledDeliverySettings.instant_delivery.enabled;
  const isScheduledDeliveryEnabled =
    scheduledDeliverySettings &&
    scheduledDeliverySettings.scheduled_delivery &&
    scheduledDeliverySettings.scheduled_delivery.enabled;

  const hasPermission = shouldBeAbleToUpdateTurfConfig();
  const disableTurfEnable =
    hasPermission && !isTurfEnabled && !isInstantDeliveryEnabled && !isScheduledDeliveryEnabled;

  const handleTurfStateUpdate = useCallback(() => {
    if (hubSlug && turfId) {
      updateTurfEnableDisable(
        {
          body: {
            enabled: !isTurfEnabled,
          },
          pathParams: { hubSlug, turfId },
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries({
              queryKey: QUERY_KEYS.GET_ALL_TURFS_REQUEST,
            });
            await queryClient.invalidateQueries({
              queryKey: QUERY_KEYS.HUBS,
            });
            notifySuccess(t('toast.changes-applied-successfully'));
          },
          onError: (e) => {
            const errorMessage = `${t('toast.error')}${e.payload ? `:${e.payload}` : ''}`;
            sdk.reportError(new Error(String(e.payload)), '@flapp/hub-manager');
            notifyError(errorMessage);
          },
        }
      );
    }
  }, [hubSlug, isTurfEnabled, queryClient, t, turfId, updateTurfEnableDisable]);

  if (!enableUpdatePDT) return null;

  return (
    <Toggle
      label={t(
        `components.turf-management-card.toggle-label.${isTurfEnabled ? 'enabled' : 'disabled'}`
      )}
      onChange={handleTurfStateUpdate}
      value={isTurfEnabled}
      dataTestid={`turf-enabled-disabled-${turf.id}`}
      disabled={disableTurfEnable}
      disabledStyle
    />
  );
};

export default TurfEnableToggleSwitch;
