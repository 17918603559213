import { ClosureDetails, ScheduleDates, Weekday, WorkingHours } from 'api/generated/apiSchemas';
import { format, utcToZonedTime } from 'date-fns-tz';
import { FROM, TO, WEEKDAYS, WEEKDAYS_NO } from './constants';
import { MultiWorkingHours } from './types';

export type WorkingHour = {
  openTime: string;
  closeTime: string;
} | null;

export const getWorkingHours = (workingHours: Array<WorkingHours> | undefined) => {
  const today = new Date();
  const dayOfWweek = today.getDay();
  if (workingHours?.length) {
    const workingHour = workingHours.find((element: WorkingHours) => element.day_no === dayOfWweek);
    if (workingHour?.open_time && workingHour?.close_time) {
      return { openTime: workingHour.open_time, closeTime: workingHour.close_time };
    }
  }
  return null;
};

export const getSortedWeekdays = (multiWorkingHours: MultiWorkingHours) => {
  const filteredMultiWorkingHours = multiWorkingHours.filter(
    (multiWorkingHour): multiWorkingHour is { day_no: Weekday; working_hours: WorkingHours[] } =>
      typeof multiWorkingHour.day_no === 'number'
  );
  return filteredMultiWorkingHours.sort((a, b) => {
    if (a.day_no === WEEKDAYS_NO.SUNDAY) return 1;
    if (b.day_no === WEEKDAYS_NO.SUNDAY) return -1;
    return a.day_no - b.day_no;
  });
};

export const getFormattedWorkingHours = (workingHours?: WorkingHours[]) => {
  const DATE = new Date();
  const timeZone = 'CET';
  const zonedDate = utcToZonedTime(DATE, timeZone);
  const todayOfWweek = zonedDate.getDay();
  const weeklyWorkingHours = Object.values(WEEKDAYS_NO).map((dayNo) => {
    const weekdayNo = Number(dayNo);
    const matcheWorkingHour = workingHours?.find((workingHour) => workingHour.day_no === weekdayNo);
    return {
      weekday: WEEKDAYS[weekdayNo],
      isToday: todayOfWweek === weekdayNo,
      open_time: matcheWorkingHour?.open_time || undefined,
      close_time: matcheWorkingHour?.close_time || undefined,
    };
  });
  return weeklyWorkingHours;
};

export const checkIfValidlatitudeAndlongitude = (str: string) => {
  // Regular expression to check if string is a latitude and longitude
  const regexExp = /^-?(90|[0-8]?\d)(\.\d+)?, *-?(180|1[0-7]\d|\d?\d)(\.\d+)?$/gm;
  return regexExp.test(str);
};

export const configIsHubActive = ({
  openDate,
  closeDate,
}: {
  openDate?: string | null;
  closeDate?: string | null;
}): boolean => {
  const DATE = new Date();
  const timeZone = 'CET';
  const TODAY = utcToZonedTime(DATE, timeZone).toISOString();
  const OPEN = openDate ? new Date(openDate).toISOString() : null;
  const CLOSE = closeDate ? new Date(closeDate).toISOString() : null;

  if (!OPEN) return false;

  if ((OPEN <= TODAY && !CLOSE) || (OPEN <= TODAY && CLOSE && TODAY < CLOSE)) {
    return true;
  }

  return false;
};

export const isTimeNowInsideWorkingHour = (workingHours?: WorkingHour | null): boolean => {
  if (!workingHours?.openTime || !workingHours?.closeTime) return false;
  const DATE = new Date();
  const timeZone = 'CET';
  const zonedDate = utcToZonedTime(DATE, timeZone);
  const pattern = 'HHmm';
  const CURRENT_TIME = format(zonedDate, pattern, { timeZone });
  const openTime = workingHours.openTime.split(':').join('');
  const closeTime = workingHours.closeTime.split(':').join('');
  return openTime <= CURRENT_TIME && closeTime >= CURRENT_TIME;
};

export const isDateInRange = (scheduledDate: ScheduleDates) => {
  const TODAY = new Date().toISOString();
  const START = scheduledDate.start_date ? new Date(scheduledDate.start_date).toISOString() : null;
  const END = scheduledDate.end_date ? new Date(scheduledDate.end_date).toISOString() : null;

  if (START && END) {
    return START <= TODAY && END >= TODAY;
  }
  if (START) {
    return START <= TODAY;
  }
  if (END) {
    return END >= TODAY;
  }
  return false;
};

export const isScheduledClosuresValid = (scheduledDate: ScheduleDates) => {
  if (scheduledDate.end_date) {
    const TODAY = getZonedDate();
    const END = new Date(scheduledDate.end_date);
    END.setHours(0, 0, 0, 0);
    TODAY.setHours(0, 0, 0, 0);
    return END.toISOString() >= TODAY.toISOString();
  }
  return false;
};

export const configScheduledClosuresValid = (closureReason: ClosureDetails) => {
  if (closureReason.enabled) {
    const scheduledDates = closureReason.scheduled_dates;
    if (scheduledDates?.length) {
      return scheduledDates.some((scheduledDate: ScheduleDates) =>
        isScheduledClosuresValid(scheduledDate)
      );
    }
    return false;
  }
  return false;
};

export const getClosureReasonState = (closureReason: ClosureDetails) => {
  if (closureReason.enabled) {
    const scheduledDates = closureReason.scheduled_dates;
    if (scheduledDates?.length) {
      return scheduledDates.some((scheduledDate: ScheduleDates) => isDateInRange(scheduledDate));
    }
    return true;
  }
  return false;
};

export const getTimes = (start: number, end: number) => {
  start = start * 2;
  end = end * 2;
  return Array.from({ length: end - start + 1 }, (_, i) => {
    return (((i + start) >> 1) + ':' + ((i + start) % 2) * 3 + '0').replace(/^\d:/, '0$&');
  });
};

export const getTimeOptions = (variant?: string, currentStartTimeValue?: string) => {
  if (variant === FROM) {
    return getTimes(0, 23.5).map((time) => {
      return { value: time, label: time };
    });
  } else if (variant === TO && currentStartTimeValue) {
    return getTimes(0, 23.5).map((time) => {
      return { value: time, label: time, disabled: time <= currentStartTimeValue };
    });
  } else {
    return getTimes(0, 23.5).map((time) => {
      return { value: time, label: time };
    });
  }
};

export const getZonedDate = () => {
  const DATE = new Date();
  const timeZone = 'CET';
  const zonedDate = utcToZonedTime(DATE, timeZone);
  return zonedDate;
};

export const getDateTime = (date?: Date, selectedTime?: string) => {
  if (!date || !selectedTime) return '';
  const DATE = new Date(date);
  const timeZone = 'CET';
  const zonedDate = utcToZonedTime(DATE, timeZone);
  const TIME = selectedTime.split(':');
  const hour = Number(TIME[0]);
  const min = Number(TIME[1]);
  zonedDate.setHours(hour, min);
  return zonedDate.toISOString();
};

export const getFormattedDate = (value?: string | Date, pattern?: string) => {
  const formatPattern = pattern || 'dd.MM.yyyy';
  if (!value) return '';
  const timeZone = 'CET';
  const date = new Date(value);
  const zonedDate = utcToZonedTime(date, timeZone);
  return format(zonedDate, formatPattern, { timeZone });
};

export const getFormattedTime = (value?: string, pattern?: string) => {
  const formatPattern = pattern || 'HH:mm';
  if (!value) return '';
  const timeZone = 'CET';
  const date = new Date(value);
  const zonedDate = utcToZonedTime(date, timeZone);
  return format(zonedDate, formatPattern, { timeZone });
};
