const IconTrufStatus = ({ name }) => {
  switch (name) {
    case 'close':
      return (
        <div>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="10" fill="#CC0000" />
            <path
              d="M9 9L15 15"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15 9L9 15"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      );
    case 'alert':
      return (
        <div>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="10" fill="#EC7400" />
            <rect x="11.2517" y="6.65601" width="1.49664" height="7" rx="0.748318" fill="white" />
            <rect x="11" y="15.344" width="2" height="2" rx="1" fill="white" />
          </svg>
        </div>
      );
    default:
      return (
        <div>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="10" fill="#009B7D" />
            <path
              d="M15.6263 9.336L10.8588 14.664"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <path
              d="M10.8587 14.664L8.37374 12.336"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </svg>
        </div>
      );
  }
};
export default IconTrufStatus;
