import { memo, useCallback } from 'react';
import { useDeleteTurfRequest } from 'api/generated/apiComponents';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'lib/constants';
import { notifyError, notifySuccess } from 'lib/toast';
import { useTranslation } from 'hooks';
import Modal from 'components/Modal';
import sdk from '@hubportal/sdk';

type TurfDeleteModalPropsType = { onClickCancel: () => void; hubSlug?: string; turfId?: string };

const TurfDeleteModal = ({ onClickCancel, hubSlug, turfId }: TurfDeleteModalPropsType) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate: deleteTurf } = useDeleteTurfRequest();

  const handleTurfDelete = useCallback(() => {
    if (hubSlug && turfId) {
      deleteTurf(
        {
          pathParams: { hubSlug, turfId },
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries({
              queryKey: QUERY_KEYS.GET_ALL_TURFS_REQUEST,
            });
            notifySuccess(t('toast.changes-applied-successfully'));
          },
          onError: (e) => {
            const errorMessage = `${t('toast.error')}${e.payload ? `:${e.payload}` : ''}`;
            sdk.reportError(new Error(String(e.payload)), '@flapp/hub-manager');
            notifyError(errorMessage);
          },
        }
      );
    }
  }, [deleteTurf, hubSlug, queryClient, t, turfId]);

  return (
    <Modal title={t('components.modal.delete-turf')} onClickOutside={onClickCancel}>
      <div>
        <div className="py-6">
          <p className="text-white fontFamily-flink text-base font-normal">
            {t('components.modal.turf-delete.paragraph')}
          </p>
        </div>
        <div className="flex justify-between">
          <button
            className="text-white bg-buttonBgColor w-56 leading-none p-4 rounded fontFamily-sans text-lg font-extrabold h-13"
            onClick={onClickCancel}
          >
            {t('components.modal.turf-delete.no')}
          </button>
          <button
            className="text-white bg-red w-56 leading-none p-4 rounded fontFamily-sans text-lg font-extrabold h-13"
            onClick={handleTurfDelete}
            data-testid="delete-turf"
          >
            {t('components.modal.turf-delete.yes')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default memo(TurfDeleteModal);
