import { useScheduleClosureList } from 'api/generated/apiComponents';
import { ListScheduleClosure } from 'api/generated/apiSchemas';
import Modal from 'components/Modal';
import MultiSelect from 'components/MultiSelect';
import SearchInput from 'components/SearchInput';
import Spinner from 'components/Spinner';
import { COUNTRIES } from 'lib/constants';
import { useMemo, useState } from 'react';
import { useTranslation } from 'hooks';
import { customDate, filterType, getFilteredLogs, sortLogsBasedOnStartDate } from './helper';
import { isScheduledClosuresValid } from 'lib/helpers';

const DEFAULT_FILTER = {
  searchQuery: '',
  countries: [],
};

const ScheduledClosureLogsModal = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const {
    data: scheduleClosureList,
    error,
    isLoading,
  } = useScheduleClosureList<ListScheduleClosure[]>({});
  const [filter, setFilter] = useState<filterType>(DEFAULT_FILTER);

  const filteredLogs = useMemo(() => {
    const validscheduleClosureList = scheduleClosureList?.filter(({ start_date, end_date }) =>
      isScheduledClosuresValid({ start_date, end_date })
    );
    if (!validscheduleClosureList) return [];
    if (!filter.countries.length && !filter.searchQuery) {
      return sortLogsBasedOnStartDate(validscheduleClosureList);
    } else {
      return sortLogsBasedOnStartDate(
        getFilteredLogs({
          hubsWithScheduledClosures: validscheduleClosureList,
          filter,
        })
      );
    }
  }, [filter, scheduleClosureList]);

  const handleSelect = (option) => {
    setFilter((pre) => {
      return { ...pre, ...option };
    });
  };

  return (
    <Modal
      title={t('components.scheduled-closure-logs-modal.title')}
      onClickOutside={onClose}
      customClass="w-10/12 max-w-5xl h-700 overflow-visible"
    >
      <div className="flex flex-col">
        <div className="flex items-center gap-6 my-2">
          <SearchInput
            onSearch={(query: string) => handleSelect({ searchQuery: query })}
            searchQuery={filter.searchQuery}
            placeholder={t('components.scheduled-closure-logs-modal.search-input-placeholder')}
          />
          <MultiSelect
            options={COUNTRIES}
            onSelect={(countries: Array<string>) => handleSelect({ countries })}
            variant="large"
            selectedOptions={COUNTRIES.filter((option) => filter.countries?.includes(option.value))}
            placeholder={t('pages.hub-manager-view.multi-select-placeholder.country-all')}
          />
        </div>
        <div className="flex flex-col overflow-auto h-500">
          {isLoading && (
            <div className="mx-4 my-4">
              <Spinner />
            </div>
          )}
          {error && <p>{t('pages.hub-manager-view.scheduledclosuremodal.error-message')}</p>}
          {filteredLogs.length === 0 && (
            <p>{t('pages.hub-manager-view.scheduledclosuremodal.no-logs')}</p>
          )}
          {filteredLogs.length > 0 &&
            filteredLogs.map((item, index) => (
              <div
                key={index}
                className="bg-flinkGray rounded-lg flex justify-between items-center my-0.5 p-4"
                data-testid="schedule-closure-list-card"
              >
                <div className="flex flex-col h-full text-white">
                  <div className="flex-1  font-medium">
                    {item.hubs && item.hubs.map(({ name }) => name).join(', ')}
                  </div>
                  <div>
                    {t('pages.hub-manager-view.scheduledclosuremodal.closing-at')}
                    {customDate(item.start_date)}
                  </div>
                  <div>
                    {t('pages.hub-manager-view.scheduledclosuremodal.reopening-at')}
                    {customDate(item.end_date)}
                  </div>
                  <div className="text-flinkGray-light">
                    {t('pages.hub-manager-view.scheduledclosuremodal.reason')}: {item.reason}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </Modal>
  );
};

export default ScheduledClosureLogsModal;
