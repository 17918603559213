import MultiSelect from 'components/MultiSelect';
import SearchInput from 'components/SearchInput';
import SingleSelect from 'components/SingleSelect';
import { AUDITLOG_HEADERS, EVENTS, TO } from 'lib/constants';
import { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'hooks';
import TimeSelect from 'components/TimeSelect';
import { getDateTime, getTimeOptions } from 'lib/helpers';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import Spinner from 'components/Spinner';
import { AuditLog, AuditLogsList } from 'api/generated/apiSchemas';
import { useGetAuditLogsRequest } from 'api/generated/apiComponents';
import AuditLogs from 'components/AuditLogs';
import { useSearchParams } from 'react-router-dom';
import { Option } from 'components/SingleSelect';
import { getCurrentValues } from './helper';
import { Pagination } from '@hubportal/components';
import {
  daySelectionValues,
  DaySelectOptions,
  DEFAULT_FILTER,
  theLastHour,
  TODAY,
  YESTERDAY,
} from './constants';
import { shouldBeAbleToViewAuditLogs } from 'lib/permissions';

const AuditLogsPage = () => {
  const hasPermission = shouldBeAbleToViewAuditLogs();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { events, query, selectedDay, from, to, fromTime, toTime, limit, page } =
    getCurrentValues(searchParams);

  const [selectedEvent, setSelectedEvent] = useState<string[]>(events);
  const [searchQuery, setSearchQuery] = useState<string>(query);
  const [selectDay, setSelectedDay] = useState<Option>(selectedDay);

  const [selectedDateRange, setSelectedDateRange] = useState<Array<Date>>([from, to]);

  const [startTime, setStartTime] = useState<Option>(fromTime);
  const [endTime, setEndTime] = useState<Option>(toTime);
  const showDateRangePicker = selectDay.value === daySelectionValues.CUSTOM;
  const [pagination, setPagination] = useState({
    pageIndex: page,
    pageSize: limit,
    pageCount: 0,
  });
  const [auditLogs, setAuditLogs] = useState<AuditLog[]>();

  const variablesConfig = useMemo(() => {
    const dayselection = selectDay.value === daySelectionValues.YESTERDAY ? YESTERDAY : TODAY;
    const startDate = showDateRangePicker ? selectedDateRange[0] : dayselection;
    const endDate = showDateRangePicker ? selectedDateRange[1] : dayselection;
    return {
      from: getDateTime(startDate, startTime.value),
      limit: pagination.pageSize,
      offset: pagination.pageIndex * pagination.pageSize,
      query: searchQuery,
      event_type: selectedEvent.toString(),
      to: getDateTime(endDate, endTime.value),
    };
  }, [
    selectDay.value,
    showDateRangePicker,
    selectedDateRange,
    startTime.value,
    pagination.pageSize,
    pagination.pageIndex,
    searchQuery,
    selectedEvent,
    endTime.value,
  ]);

  const { data, isLoading, isError } = useGetAuditLogsRequest<AuditLogsList>(
    {
      queryParams: variablesConfig,
    },
    {
      onSuccess(data) {
        setAuditLogs(data?.logs);
        setPagination({
          ...pagination,
          pageCount: Math.ceil((data?.total || 1) / (pagination.pageSize || 1)),
        });
      },
    }
  );
  useEffect(() => {
    setSearchParams(
      {
        events: selectedEvent.join(','),
        query: searchQuery,
        selectedDay: selectDay.value,
        from: selectedDateRange[0].toISOString(),
        to: selectedDateRange[1].toISOString(),
        fromTime: startTime.value,
        toTime: endTime.value,
        limit: pagination.pageSize.toString(),
        page: pagination.pageIndex.toString(),
      },
      { replace: true }
    );
  }, [
    selectedEvent,
    setSearchParams,
    searchQuery,
    selectDay,
    selectedDateRange,
    startTime,
    endTime,
    pagination,
  ]);

  if (!hasPermission) return null;

  return (
    <div className="px-10 pt-6 pb-10 w-full h-full overflow-auto">
      <div className="w-4/5">
        <SearchInput
          onSearch={(searchQuery: string) => setSearchQuery(searchQuery)}
          searchQuery={searchQuery}
          placeholder={t('pages.audit-logs-page.search-input-placeholder')}
        />
      </div>
      <div className="flex justify-between pt-1">
        <div>
          <div className="pb-2 px-1">
            <p className="text-sm text-flinkGray-light">{t('pages.audit-logs-page.filter-by')}</p>
          </div>
          <MultiSelect
            options={EVENTS}
            onSelect={(events: Array<string>) => setSelectedEvent(events)}
            variant="large"
            selectedOptions={EVENTS.filter((option) => selectedEvent?.includes(option.value))}
            placeholder={t('pages.audit-logs-page.event')}
          />
        </div>
        <div>
          <div className="flex justify-between">
            <div className="px-2">
              <div className="pb-2">
                <p className="text-sm text-flinkGray-light">{t('pages.audit-logs-page.date')}</p>
              </div>
              <div className="w-52 rounded-lg border-2 border-flinkGray bg-flinkGray-medium focus:outline-none">
                <SingleSelect
                  options={DaySelectOptions}
                  onSelect={(option) => {
                    setSelectedDay(option);
                    setSelectedDateRange(DEFAULT_FILTER.selectedDateRange);
                  }}
                  selectedOption={selectDay}
                  displayValue={selectDay.label}
                  placeholder="placeholder"
                />
              </div>
            </div>
            <div className="pl-8">
              <div className="pb-2">
                <p className="text-sm text-flinkGray-light">
                  {t('pages.audit-logs-page.timeframe')}
                </p>
              </div>
              <div>
                {showDateRangePicker ? (
                  <div className="date-range-select-audit-logs">
                    <DateRangePicker
                      className="react-daterange-picker"
                      calendarClassName="react-calendar"
                      onChange={(option) => {
                        if (option) {
                          setStartTime(DEFAULT_FILTER.startTime);
                          setEndTime(DEFAULT_FILTER.endTime);
                          setSelectedDateRange(option);
                        }
                      }}
                      value={selectedDateRange}
                      format="dd.MM.yyyy"
                      locale="de"
                    />
                  </div>
                ) : (
                  <div className="flex justify-between">
                    <TimeSelect
                      options={getTimeOptions()}
                      selectedOption={startTime}
                      displayValue={startTime.label}
                      handleSelect={(option) => {
                        if (option.value >= endTime.value) {
                          setStartTime(option);
                          setEndTime(DEFAULT_FILTER.endTime);
                        } else {
                          setStartTime(option);
                        }
                      }}
                      customClass="w-36 mr-2 rounded-lg border-2 border-flinkGray bg-flinkGray-medium focus:outline-none"
                    />
                    <TimeSelect
                      options={[...getTimeOptions(TO, startTime.value), theLastHour]}
                      displayValue={endTime.label}
                      handleSelect={(option) => setEndTime(option)}
                      selectedOption={endTime}
                      customClass="w-36 rounded-lg border-2 border-flinkGray bg-flinkGray-medium focus:outline-none"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-8 pb-12 px-1">
        <div className="grid grid-cols-5 pb-4">
          {AUDITLOG_HEADERS.map((header: string) => (
            <p key={header} className="font-medium text-xs text-flinkGray-light w-fit">
              {t(`pages.audit-logs-page.header.${header}`)}
            </p>
          ))}
        </div>
        <div>
          {isError ? (
            <p className=" text-white text-lg font-light">{t('pages.audit-logs-page.error')}</p>
          ) : isLoading ? (
            <div className="mx-4 my-8">
              <Spinner />
            </div>
          ) : (
            <AuditLogs auditLogs={auditLogs} />
          )}
        </div>
        <div className="py-4">
          <Pagination
            goToPage={(pi) => {
              setPagination({ ...pagination, pageIndex: pi });
            }}
            pageCount={pagination.pageCount}
            pageIndex={pagination.pageIndex}
            pageSize={pagination.pageSize}
            setPageSize={(ps) => {
              setPagination({ ...pagination, pageSize: ps });
            }}
            totalCount={data?.total || 0}
            labels={{ show: t('pages.audit-logs-page.paginate.show') }}
          />
        </div>
      </div>
    </div>
  );
};
export default AuditLogsPage;
