import { useEffect, useState } from 'react';
import { useTranslation } from 'hooks';
import DatePicker from 'react-date-picker';
import { DEFAULT_DATE_TIME, QUERY_KEYS, TO } from 'lib/constants';
import { Option } from 'components/SingleSelect';
import TimeSelect from 'components/TimeSelect';
import { getDateTime, getFormattedDate, getTimeOptions, getZonedDate } from 'lib/helpers';
import { IconCircle, IconSelect } from 'assets/icons';
import { useBatchScheduleAreaChangeRequest } from 'api/generated/apiComponents';
import useTurfsArea from 'stores/useTurfsArea';
import { notifyError, notifySuccess } from 'lib/toast';
import sdk from '@hubportal/sdk';
import { useQueryClient } from '@tanstack/react-query';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import 'react-date-picker/dist/DatePicker.css';

type ImportTurfChangesDialogBoxPropsType = {
  onCloseImportTurfDrawer: () => void;
};

const options = [
  { action: 'immediately', label: 'components.import-turf-changes-dialog-box.immediately' },
  { action: 'customDateTime', label: 'components.import-turf-changes-dialog-box.custom-date-time' },
];

const ImportTurfChangesDialogBox = ({
  onCloseImportTurfDrawer,
}: ImportTurfChangesDialogBoxPropsType) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const TODAY = getZonedDate();
  const [selectedAction, setSelectedAction] = useState('immediately');
  const [isSingleDateType, setIsSingleDateType] = useState(true);
  const [selectedDate, setSelectedDate] = useState<Date>(TODAY);
  const [selectedRangeDates, setSelectedRangeDates] = useState<Date[]>([TODAY, TODAY]);
  const [changesAt, setchangesAt] = useState<Option>(DEFAULT_DATE_TIME.startTime);
  const extraOption = {
    value: '',
    label: t('components.import-turf-changes-dialog-box.no-revert'),
  };
  const revertTimeOptions = isSingleDateType
    ? [extraOption, ...getTimeOptions(TO, changesAt.value)]
    : getTimeOptions();
  const [revertsAt, setRevertsAt] = useState<Option>(extraOption);

  useEffect(() => {
    const revertAt = isSingleDateType ? extraOption : DEFAULT_DATE_TIME.startTime;
    setRevertsAt(revertAt);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSingleDateType]);

  const scheduledAt = isSingleDateType ? selectedDate : selectedRangeDates[0];
  const revertAt = isSingleDateType ? selectedDate : selectedRangeDates[1];
  const formattedDate = getFormattedDate(scheduledAt);
  const displayValueOfToBeRevertsAt = revertsAt.value
    ? `${revertsAt.value} - ${getFormattedDate(revertAt)}`
    : revertsAt.label;
  const { selectedTurfs, reset, backToUploadTurfsPage } = useTurfsArea();
  const { mutate } = useBatchScheduleAreaChangeRequest();

  const handleUpdateTurfsArea = () => {
    const rollback_at = revertsAt.value
      ? {
          rollback_at: getDateTime(revertAt, revertsAt.value),
        }
      : {};
    const scheduled =
      selectedAction === 'customDateTime'
        ? {
            scheduled_at: getDateTime(scheduledAt, changesAt.value),
            ...rollback_at,
          }
        : { scheduled_at: selectedDate.toISOString() };
    const turfsArea = selectedTurfs?.map((turf) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { name, ...rest } = turf;
      return {
        ...rest,
        ...scheduled,
      };
    });
    if (turfsArea?.length)
      mutate(
        { body: turfsArea },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries({
              queryKey: QUERY_KEYS.GET_ALL_TURFS_REQUEST,
            });
            reset();
            onCloseImportTurfDrawer();
            notifySuccess(t('toast.upload-turf.success'));
          },
          onError: (e) => {
            const errorMessage = `${t('toast.error')}${e.payload ? `:${e.payload}` : ''}`;
            sdk.reportError(new Error(String(e.payload)), '@flapp/hub-manager');
            notifyError(errorMessage);
          },
        }
      );
  };

  const datePickerProps = {
    onChange: (value: Date | null | (Date | null)[]) => setSelectedDate(value as Date),
    value: selectedDate,
    locale: 'de',
    minDate: TODAY,
  };
  return (
    <div>
      <div className="bg-flinkGray px-6 my-6 h-fit rounded-lg">
        <div className="pt-6">
          <p className="text-xl text-white font-bold">
            {t('components.import-turf-changes-dialog-box.title')}
          </p>
        </div>
        <div className="py-4">
          {options.map(({ action, label }) => {
            return (
              <button
                className="bg-flinkGray-medium py-1 h-14 px-4 my-1 w-full rounded-lg flex items-center"
                onClick={() => setSelectedAction(action)}
                key={action}
              >
                <div className="w-5">
                  {selectedAction === action ? <IconSelect /> : <IconCircle />}
                </div>
                <p className="text-white text-base font-medium pl-3">{t(label)}</p>
              </button>
            );
          })}
        </div>
        {selectedAction === 'customDateTime' && (
          <div>
            <div className="pb-4 flex" data-testid="custom-date-time">
              <div className="flex pr-4">
                <button
                  className={`mr-2 ${
                    isSingleDateType
                      ? 'bg-flinkPink-light text-flinkPink'
                      : 'bg-flinkGray-medium text-flinkGray-light'
                  } rounded-lg px-3 w-28 text-sm font-medium`}
                  onClick={() => {
                    setIsSingleDateType(true);
                  }}
                >
                  {t('components.import-turf-changes-dialog-box.single-date')}
                </button>
                <button
                  className={`${
                    !isSingleDateType
                      ? 'bg-flinkPink-light text-flinkPink'
                      : 'bg-flinkGray-medium text-flinkGray-light'
                  } rounded-lg px-3 w-28 text-sm font-medium`}
                  onClick={() => {
                    setIsSingleDateType(false);
                  }}
                >
                  {t('components.import-turf-changes-dialog-box.range-date')}
                </button>
              </div>
              {isSingleDateType ? (
                <DatePicker
                  className="react-date-picker"
                  calendarClassName="react-calendar"
                  format="dd.MM.yyyy"
                  data-testid="date-picker"
                  {...datePickerProps}
                />
              ) : (
                <DateRangePicker
                  className="custom-range-picker"
                  calendarClassName="react-calendar"
                  onChange={(options: Date[]) => setSelectedRangeDates(options)}
                  value={selectedRangeDates}
                  format="dd.MM.yyyy"
                  locale="de"
                  minDate={TODAY}
                />
              )}
            </div>
            <div className="pb-6 flex justify-between">
              <TimeSelect
                title={t('components.import-turf-changes-dialog-box.changes-at')}
                options={getTimeOptions()}
                selectedOption={changesAt}
                displayValue={`${changesAt.value} - ${formattedDate}`}
                handleSelect={(option) => setchangesAt(option)}
                customClass="select-time-upload-form"
              />
              <TimeSelect
                title={t('components.import-turf-changes-dialog-box.reverts-at')}
                options={revertTimeOptions}
                selectedOption={revertsAt}
                displayValue={displayValueOfToBeRevertsAt}
                handleSelect={(option) => setRevertsAt(option)}
                customClass="select-time-upload-form"
              />
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-end">
        <button
          className="mr-6 flex items-center justify-center text-white bg-flinkGray leading-none p-4 rounded fontFamily-sans text-lg font-extrabold h-13 w-24"
          onClick={backToUploadTurfsPage}
        >
          {t('components.import-turf-changes-dialog-box.cancel')}
        </button>
        <button
          className="flex items-center justify-center text-white bg-flinkPink leading-none p-4 rounded fontFamily-sans text-lg font-extrabold h-13 w-40 disabled:bg-flinkGray-light"
          onClick={() => handleUpdateTurfsArea()}
          disabled={!selectedTurfs}
          data-testid="saveAndExit"
        >
          {t('components.import-turf-changes-dialog-box.save-and-exit')}
        </button>
      </div>
    </div>
  );
};

export default ImportTurfChangesDialogBox;
