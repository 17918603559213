import { IconArrowLeft, IconClose } from 'assets/icons';
import Modal from 'components/Modal';
import { useTranslation } from 'hooks';
import useBulkHubClosure from 'stores/useBulkHubClosure';
import useHubsClosureModal from 'stores/useHubsClosureModal';

const SelectedHubsListModal = () => {
  const { t } = useTranslation();
  const { showSelectedHubsList, toggleModal, reset } = useHubsClosureModal();
  const { selectedHubs, setSelectedHubs } = useBulkHubClosure();

  if (!showSelectedHubsList) return null;

  return (
    <Modal onClickOutside={reset}>
      <div>
        <button className="flex items-center gap-2 text-2xl" onClick={() => toggleModal()}>
          <IconArrowLeft />
          {t('components.selected-hubs-list-modal.title')}
        </button>
        <div className="mt-6 flex flex-col max-h-96 overflow-auto">
          {selectedHubs.map((hub) => (
            <div
              className="bg-flinkGray h-14 rounded-lg flex justify-between items-center my-0.5 p-4"
              key={hub}
            >
              <p className="text-white font-medium">{hub}</p>
              <IconClose
                onClick={() => {
                  setSelectedHubs(hub);
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default SelectedHubsListModal;
