import SingleSelect, { Option } from 'components/SingleSelect';

type TimeSelectPropsType = {
  title?: string;
  options: Option[];
  selectedOption?: Option;
  displayValue: string;
  handleSelect: (option: Option) => void;
  customClass?: string;
};

const TimeSelect = ({
  title,
  options,
  selectedOption,
  displayValue,
  handleSelect,
  customClass,
}: TimeSelectPropsType) => {
  return (
    <div className={customClass} data-testid="time-select">
      {title && <p className="text-base text-white">{title}</p>}
      <SingleSelect
        options={options}
        onSelect={(option) => handleSelect(option)}
        selectedOption={selectedOption}
        displayValue={displayValue}
        placeholder="placeholder"
      />
    </div>
  );
};

export default TimeSelect;
