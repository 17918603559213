type ToggleProps = {
  label?: string;
  value: boolean;
  onChange: (value: boolean) => void;
  dataTestid?: string;
  disabled?: boolean;
  disabledStyle?: boolean;
};

const Toggle = ({
  label,
  value,
  onChange,
  dataTestid,
  disabled,
  disabledStyle = false,
}: ToggleProps) => {
  return (
    <div className="relative flex flex-col items-center justify-center overflow-hidden">
      <div className="flex">
        <label
          className={`inline-flex items-center cursor-pointer ${
            disabled && disabledStyle ? 'cursor-auto' : 'cursor-pointer'
          }`}
          data-testid={`toggle-${dataTestid}-label`}
        >
          {label ? <p className="text-base text-white font-medium white mx-2">{label}</p> : null}
          <span className="inline-flex relative items-center">
            <input
              type="checkbox"
              className="sr-only peer w-11 h-6"
              readOnly
              checked={value}
              data-testid={`toggle-${dataTestid}`}
              disabled={disabled}
              onClick={() => onChange(!value)}
            />
            <div
              role="presentation"
              className={`pointer-events-none w-11 h-6 bg-flinkGray-light rounded-full peer peer-focus:ring-veggieGreen-dark  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all ${
                disabled && disabledStyle
                  ? 'peer-checked:bg-flinkGray-light'
                  : 'peer-checked:bg-veggieGreen-dark'
              }`}
            ></div>
          </span>
        </label>
      </div>
    </div>
  );
};

export default Toggle;
