import { useTranslation } from 'hooks';

const UploadFileLoading = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center bg-flinkGray  my-12 rounded-lg text-white h-32">
      <div className="pt-8">
        <div className="loading" data-testid="loading">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="mt-2">
          <p className="text-white font-medium text-base">
            {t('components.upload-file-loading.info')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default UploadFileLoading;
