import { useQueryClient } from '@tanstack/react-query';
import { useClosureReasonPatchRequest } from 'api/generated/apiComponents';
import { ClosureReasons, ScheduleDates } from 'api/generated/apiSchemas';
import { QUERY_KEYS } from 'lib/constants';
import { getFormattedDate, getFormattedTime } from 'lib/helpers';
import { notifyError, notifySuccess } from 'lib/toast';
import { useTranslation } from 'hooks';
import isEqual from 'lodash.isequal';
import sdk from '@hubportal/sdk';
import { Icon } from '@hubportal/components';
import Modal from 'components/Modal';
import { useState } from 'react';

type ScheduledClosureListPropsType = {
  key: string;
  hubSlug: string;
  closureReasons: ClosureReasons;
  reason: string;
  scheduledDates?: ScheduleDates[];
};

type ScheduledClosurePropsType = {
  closureReason: string;
  scheduledDate: ScheduleDates;
};

type ConfirmationModalPropsType = {
  handleUpdate: (enabled?: boolean) => void;
  onClose: () => void;
} & ScheduledClosurePropsType;

const ScheduledClosure = ({ closureReason, scheduledDate }: ScheduledClosurePropsType) => {
  const { t } = useTranslation();
  const startDate = getFormattedDate(scheduledDate.start_date);
  const endDate = getFormattedDate(scheduledDate.end_date);
  const closingAt = getFormattedTime(scheduledDate.start_date);
  const openingAt = getFormattedTime(scheduledDate.end_date);
  return (
    <div className="flex flex-col">
      <p className="text-white font-medium">
        {t('components.scheduled-closures-info-bar.list.range-date', {
          startDate,
          endDate,
        })}
      </p>
      <p className="text-white">
        {t('components.scheduled-closures-info-bar.list.closing-at', {
          closingAt,
          startDate,
        })}
      </p>
      <p className="text-white">
        {t('components.scheduled-closures-info-bar.list.reopening-at', {
          openingAt,
          endDate,
        })}
      </p>
      <p className="text-flinkGray-light">
        {t('components.scheduled-closures-info-bar.list.reason', {
          reason: closureReason,
        })}
      </p>
    </div>
  );
};

const ConfirmationModal = ({
  closureReason,
  scheduledDate,
  handleUpdate,
  onClose,
}: ConfirmationModalPropsType) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t('components.scheduled-closures-info-bar.confirmation-modal.title')}
      onClickOutside={onClose}
    >
      <div>
        <div className="bg-flinkGray h-fit rounded-lg my-4 p-4">
          <ScheduledClosure closureReason={closureReason} scheduledDate={scheduledDate} />
        </div>
        <div className="flex justify-between">
          <button
            className="text-white w-56 p-4 rounded text-lg font-extrabold h-13 bg-buttonBgColor leading-none"
            onClick={() => onClose()}
          >
            {t('components.scheduled-closures-info-bar.confirmation-modal.cancel')}
          </button>
          <button
            className="text-white w-56 p-4 rounded text-lg font-extrabold h-13 bg-red leading-none"
            onClick={() => handleUpdate()}
          >
            {t('components.scheduled-closures-info-bar.confirmation-modal.delete')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

const ScheduledClosureList = ({
  hubSlug,
  closureReasons,
  reason,
  scheduledDates,
}: ScheduledClosureListPropsType) => {
  const { t } = useTranslation();
  const defaultVariables = {
    show: false,
    closureReason: '',
    scheduledDate: {},
  };
  const [variables, setVariables] = useState(defaultVariables);
  const onClose = () => setVariables(defaultVariables);
  const { mutate } = useClosureReasonPatchRequest();
  const queryClient = useQueryClient();
  const handleDeleteScheduledClosure = ({
    reason,
    scheduledDate,
  }: {
    reason: string;
    scheduledDate: ScheduleDates;
  }) => {
    const newClosureReasons: ClosureReasons = {
      ...closureReasons,
    };

    const remainingDatesForReason = newClosureReasons[reason].scheduled_dates.filter(
      (date: ScheduleDates) => !isEqual(date, scheduledDate)
    );
    newClosureReasons[reason] = {
      ...newClosureReasons[reason],
      scheduled_dates: remainingDatesForReason,
      enabled: remainingDatesForReason.length > 0,
    };
    mutate(
      {
        body: newClosureReasons,
        pathParams: { hubSlug },
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: QUERY_KEYS.GET_HUB_REQUEST,
          });
          notifySuccess(t('toast.changes-applied-successfully'));
        },
        onError: (e) => {
          const errorMessage = `${t('toast.error')}${e.payload ? `:${e.payload}` : ''}`;
          sdk.reportError(new Error(String(e.payload)), '@flapp/hub-manager');
          notifyError(errorMessage);
        },
        onSettled: () => onClose(),
      }
    );
  };
  const closureReason = t(`scheduled_closure_reasons.${reason}`);

  return (
    <>
      {scheduledDates?.map((scheduledDate: ScheduleDates, index) => {
        if (!closureReasons) return null;
        const iconProps = { 'data-testid': 'trashIcon' };
        return (
          <div
            className="bg-flinkGray h-fit rounded-lg flex justify-between items-center my-0.5 p-4"
            key={index}
          >
            <ScheduledClosure closureReason={closureReason} scheduledDate={scheduledDate} />
            <Icon
              type="trash"
              onClick={() => setVariables({ show: true, closureReason, scheduledDate })}
              {...iconProps}
            />
          </div>
        );
      })}
      {variables.show && (
        <ConfirmationModal
          closureReason={variables.closureReason}
          scheduledDate={variables.scheduledDate}
          handleUpdate={() =>
            handleDeleteScheduledClosure({ reason, scheduledDate: variables.scheduledDate })
          }
          onClose={onClose}
        />
      )}
    </>
  );
};

export default ScheduledClosureList;
