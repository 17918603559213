import { useTranslation } from 'hooks';
import { IconArrowRight } from 'assets/icons';
import { getWorkingHours } from 'lib/helpers';
import HubStatusIndicator from 'components/HubStatusIndicator';
import { ListHub } from 'api/generated/apiSchemas';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from 'lib/constants';
import useBulkHubClosure from 'stores/useBulkHubClosure';

type HubContainerProps = {
  hub: ListHub;
};

const HubContainer = ({ hub }: HubContainerProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const workingHour = getWorkingHours(hub.working_hours);
  const { selectedHubs, setSelectedHubs } = useBulkHubClosure();
  return (
    <div className="flex bg-flinkGray w-3/5 text-flinkGray-light rounded-lg mb-4">
      <div className="flex flex-none justify-center items-center w-12 border-solid border-r border-flinkGray-medium">
        <input
          type="checkbox"
          checked={hub.slug ? selectedHubs.includes(hub.slug) : false}
          onClick={() => setSelectedHubs(hub.slug)}
        />
      </div>
      <div
        className="flex grow justify-between cursor-pointer p-6"
        data-testid="hubContainerId"
        role="presentation"
        onClick={() =>
          navigate(`${ROUTE_PATHS.HUB_MANAGER}/${hub.slug}`, {
            state: { background: location },
          })
        }
      >
        <div>
          <p className="fontFamily-flink text-white text-2xl font-bold pb-4">
            {hub.name} <span>({hub.slug})</span>
          </p>
          {workingHour ? (
            <p className="fontFamily-flink text-flinkGray-light font-medium text-base pb-4">
              {`${t('components.hubcontainer.working-hours')}: ${workingHour.openTime} - ${
                workingHour.closeTime
              }`}
            </p>
          ) : null}
          <HubStatusIndicator
            hubState={hub.state}
            turfs={hub.turfs}
            workingHour={workingHour}
            openDate={hub.open_date}
            closeDate={hub.close_date}
          />
        </div>
        <div className="self-center">
          <IconArrowRight />
        </div>
      </div>
    </div>
  );
};

export default HubContainer;
