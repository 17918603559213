import HubEmergencyClosureModal from 'components/HubEmergencyClosureModal';
import useHubsClosureModal from 'stores/useHubsClosureModal';
import { useBatchHubPatchRequest } from 'api/generated/apiComponents';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'lib/constants';
import { notifyError, notifySuccess } from 'lib/toast';
import { PatchRequest } from 'api/generated/apiSchemas';
import useBulkHubClosure from 'stores/useBulkHubClosure';
import { useTranslation } from 'hooks';
import sdk from '@hubportal/sdk';
import { EmergencyClosureReasons } from 'lib/types';

const HubsEmergencyClosureModal = () => {
  const { showHubsEmergencyClosureModal, reset } = useHubsClosureModal();
  const { selectedHubs } = useBulkHubClosure();
  const { mutate } = useBatchHubPatchRequest();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const handleUpdateMultipleClosedHubs = (reason?: EmergencyClosureReasons) => {
    if (reason)
      mutate(
        {
          body: [
            ...selectedHubs.map((item) => {
              return {
                hub_slug: item,
                force_close: {
                  active: true,
                  comment: reason,
                },
              };
            }),
          ] as PatchRequest[],
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries({
              queryKey: QUERY_KEYS.HUBS,
            });
            notifySuccess(t('toast.changes-applied-successfully'));
          },
          onError: (e) => {
            const errorMessage = `${t('toast.error')}${e.payload ? `:${e.payload}` : ''}`;
            sdk.reportError(new Error(String(e.payload)), '@flapp/hub-manager');
            notifyError(errorMessage);
          },
          onSettled: () => reset(),
        }
      );
  };
  if (!showHubsEmergencyClosureModal) return null;

  return (
    <HubEmergencyClosureModal
      onClickCancel={reset}
      updateHubState={(reason?: EmergencyClosureReasons) => {
        handleUpdateMultipleClosedHubs(reason);
      }}
      multi
    />
  );
};

export default HubsEmergencyClosureModal;
