import { useGetTurfsRequest, useHubs } from 'api/generated/apiComponents';
import ErrorMessage from 'components/ErrorMessage';
import 'react-toastify/dist/ReactToastify.css';
import ReactLeafletGoogleMap from 'components/Map';
import MultiSelect from 'components/MultiSelect';
import { useEffect, useMemo, useState } from 'react';
import { COUNTRIES, STATUS_OPTIONS } from 'lib/constants';
import { useTranslation } from 'hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { serializedHubs } from './serializer';
import { getFilteredHubs, getLatLngBounds, getSearchParams } from './helper';
import { SearchParams, SerializedHub } from './types';
import DeliveryAreas from './DeliveryAreas';

const CountryCoordinates = {
  DE: { coordinates: { lat: 51.1657, lng: 10.4515 }, zoom: 6 },
  FR: { coordinates: { lat: 46.2276, lng: 2.2137 }, zoom: 6 },
  NL: { coordinates: { lat: 52.1326, lng: 5.2913 }, zoom: 7 },
};

const defaultCountry = ['DE', 'FR', 'NL'];

const DeliveryAreaPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedHub, setSelectedHub] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const { countries, cities, statuses } = getSearchParams(searchParams);
  const hasNoSeachParams = !cities.length && !countries.length && !statuses.length;

  useEffect(() => {
    if (hasNoSeachParams) {
      setSearchParams({ country: defaultCountry });
    }
  }, [hasNoSeachParams, navigate, setSearchParams]);

  const { data: hubs, error } = useHubs<SerializedHub[]>(
    {},
    {
      select: (data) => {
        return serializedHubs(data);
      },
    }
  );

  const countryMap = useMemo(() => {
    const country = new Map();
    hubs?.forEach((hub) => {
      const hubCountry = hub.country;
      const hubCity = hub.city;
      if (country.has(hubCountry)) {
        const currentCities = country.get(hubCountry);
        country.set(hubCountry, [...new Set([...currentCities, hubCity])]);
      } else {
        country.set(hubCountry, [hubCity]);
      }
    });
    return country;
  }, [hubs]);

  const cityMap = useMemo(() => {
    const cities = new Map();
    hubs?.forEach((hub) => {
      if (cities.has(hub.city)) {
        const currentSlugs = cities.get(hub.city);
        cities.set(hub.city, [...currentSlugs, hub.slug]);
      } else {
        cities.set(hub.city, [hub.slug]);
      }
    });
    return cities;
  }, [hubs]);

  const slugs = useMemo(() => {
    const selectedCity = cities[0];
    const slugs = selectedCity && cityMap.has(selectedCity) ? cityMap.get(selectedCity).join() : '';
    return slugs;
  }, [cities, cityMap]);

  const { data: allTurfs } = useGetTurfsRequest(
    { queryParams: { slugs: slugs } },
    {
      enabled: !!slugs,
      refetchOnWindowFocus: false,
      select: (allTurfs) => {
        return allTurfs.filter((turf) => turf.area && turf.area.every((area) => area.length));
      },
    }
  );

  const cityOptions = useMemo(() => {
    let cityOptions: string[] = [];
    countries.forEach((country) => {
      if (countryMap.has(country)) {
        const current = countryMap.get(country);
        cityOptions = [...cityOptions, ...current];
      }
    });
    return cityOptions.map((city) => {
      return { value: city, label: city };
    });
  }, [countryMap, countries]);

  const selectedCountries = useMemo(() => {
    if (countries.length) {
      return COUNTRIES.filter((option) => countries.includes(option.value));
    }
  }, [countries]);

  const selectedCities = useMemo(() => {
    if (cities.length) {
      return cityOptions.filter((option) => option.value && cities.includes(option.value));
    }
  }, [cities, cityOptions]);

  const selectedStatuses = useMemo(() => {
    if (statuses.length) {
      return STATUS_OPTIONS.filter((option) => statuses.includes(option.value));
    }
  }, [statuses]);

  const setFilters = (searchParams: SearchParams) => {
    setSelectedHub('');
    const currentSearchParams = {
      ...(countries.length ? { country: countries } : {}),
      ...(cities.length ? { city: cities } : {}),
      ...(statuses.length ? { status: statuses } : {}),
    };
    setSearchParams({
      ...currentSearchParams,
      ...searchParams,
    });
  };

  const filteredHubs = useMemo(() => {
    if (!hubs) return [];
    if (hasNoSeachParams) return [];
    return getFilteredHubs({
      hubs,
      countries,
      cities,
      statuses,
    });
  }, [hubs, hasNoSeachParams, countries, cities, statuses]);

  const bounds = useMemo(() => {
    if (selectedHub) return null;
    if (!allTurfs || !allTurfs.length) return null;
    const turfs = allTurfs.map((turfs) => turfs.area).flat();
    if (!turfs || !turfs.length) return null;
    const defaultDeliveryArea: number[][] = [];
    turfs.forEach((turf) =>
      turf?.forEach((point) => {
        if (point.lat && point.long) {
          defaultDeliveryArea.push([point.lat, point.long]);
        }
      })
    );
    const bounds = getLatLngBounds(defaultDeliveryArea);
    if (bounds && bounds.isValid()) return bounds;
    return null;
  }, [selectedHub, allTurfs]);

  const zoom = useMemo(() => {
    if (selectedHub) return null;
    if (countries.length === 1) return CountryCoordinates[countries[0]].zoom;
    return 5;
  }, [countries, selectedHub]);

  const center = useMemo(() => {
    if (countries.length) {
      return CountryCoordinates[countries[0]].coordinates;
    }
    return CountryCoordinates.DE.coordinates;
  }, [countries]);

  const isValidBounds = bounds && bounds.isValid();
  const additionalReactLeafletGoogleMapProps = isValidBounds ? { bounds } : { zoom };

  if (error) return <ErrorMessage errorMessage={error.payload as string} />;

  return (
    <div className="px-10 h-full">
      <div className="flex space-x-4 my-5">
        <MultiSelect
          options={COUNTRIES}
          onSelect={(countries: Array<string>) => setFilters({ country: countries, city: [] })}
          variant="large"
          selectedOptions={selectedCountries}
          placeholder={t('components.delivery-area-page.placeholder.country')}
        />
        <MultiSelect
          options={cityOptions}
          onSelect={(cities: Array<string>) => {
            const latetSelectedCity = cities.pop();
            setFilters({ city: latetSelectedCity ? [latetSelectedCity] : [] });
          }}
          variant="small"
          selectedOptions={selectedCities}
          placeholder={t('components.delivery-area-page.placeholder.city')}
        />
        <MultiSelect
          options={STATUS_OPTIONS}
          onSelect={(statuses: Array<string>) => setFilters({ status: statuses })}
          selectedOptions={selectedStatuses}
          placeholder={t('components.delivery-area-page.placeholder.status')}
        />
      </div>
      <div className="flex space-x-2 items-center mt-5 mb-1">
        <div className="w-2 h-2 rounded-full bg-flinkPink-medium" />
        <span>{t('components.delivery-area-page.status.label.open')}</span>
        <div className="w-2 h-2 rounded-full bg-flinkGray" />
        <span>{t('components.delivery-area-page.status.label.closed')}</span>
        <div className="w-2 h-2 rounded-full bg-orange" />
        <span>{t('components.delivery-area-page.status.label.extended')}</span>
      </div>
      <div className="h-3/4 w-full">
        <ReactLeafletGoogleMap
          center={center}
          maxZoom={12}
          {...additionalReactLeafletGoogleMapProps}
        >
          {filteredHubs.map((hub) => (
            <DeliveryAreas
              key={hub.slug}
              hub={hub}
              allTurfs={allTurfs}
              selectedHub={selectedHub}
              setSelectedHub={setSelectedHub}
            />
          ))}
        </ReactLeafletGoogleMap>
      </div>
    </div>
  );
};

export default DeliveryAreaPage;
