import { ListScheduleClosure } from 'api/generated/apiSchemas';
import { format, utcToZonedTime } from 'date-fns-tz';

export type filterType = {
  searchQuery: string;
  countries: Array<string>;
};

type FilterLogsType = {
  hubsWithScheduledClosures: ListScheduleClosure[];
  filter: filterType;
};

export const sortLogsBasedOnStartDate = (hubsWithScheduledClosures: ListScheduleClosure[]) => {
  return hubsWithScheduledClosures.sort((a, b) => {
    if (a.start_date === undefined || b.start_date === undefined) {
      return 0;
    }
    return new Date(a.start_date).getTime() - new Date(b.start_date).getTime();
  });
};

export const customDate = (customDate?: string): string => {
  if (!customDate) return '';
  const timeZone = 'CET';
  const date = new Date(customDate);
  const zonedDate = utcToZonedTime(date, timeZone);
  const formattedDate = format(zonedDate, 'HH:mm (dd.MM.yyyy)', { timeZone });
  return formattedDate;
};

export const getFilteredLogs = ({ hubsWithScheduledClosures, filter }: FilterLogsType) => {
  let filtered = [...hubsWithScheduledClosures];
  if (filter.countries?.length) {
    filtered = filtered.filter((listScheduleClosure) =>
      listScheduleClosure.hubs?.some(
        (hubResult) =>
          hubResult.country && filter.countries.includes(hubResult.country.toUpperCase())
      )
    );
  }
  if (filter.searchQuery) {
    const query = filter.searchQuery.toLowerCase();
    filtered = filtered.filter((listScheduleClosure) =>
      listScheduleClosure.hubs?.some(
        (scheduledClosureHub) =>
          (scheduledClosureHub.name && scheduledClosureHub.name.toLowerCase().includes(query)) ||
          (scheduledClosureHub.slug && scheduledClosureHub.slug.toLowerCase().includes(query))
      )
    );
  }
  return filtered;
};
