import { useTranslation } from 'hooks';
import Card from 'components/Card';
import { ListState, ListTurf } from 'api/generated/apiSchemas';
import { WorkingHour } from 'lib/helpers';
import { getHubStatusConfig } from './helper';

type HubStatusIndicatorProps = {
  hubState?: ListState;
  turfs?: ListTurf[];
  isDark?: boolean;
  workingHour: WorkingHour;
  openDate?: string | null;
  closeDate?: string | null;
};

const HubStatusIndicator = ({
  hubState,
  turfs,
  isDark,
  workingHour,
  openDate,
  closeDate,
}: HubStatusIndicatorProps) => {
  const { t } = useTranslation();

  if (!hubState) return null;

  const { title, text, borderColor } = getHubStatusConfig({
    hubState,
    turfs,
    workingHour,
    openDate,
    closeDate,
  });

  return (
    <Card
      backgroundColor={isDark ? 'bg-flinkGray-dark' : 'bg-flinkGray-medium'}
      title={t(title)}
      text={t(text)}
      borderColor={borderColor}
    />
  );
};

export default HubStatusIndicator;
