import { IconArrowLeft } from 'assets/icons';
import { useKeyPress, useOutsideClick, useTranslation } from 'hooks';
import { QUERY_KEYS, ROUTE_PATHS } from 'lib/constants';
import { useCallback, useEffect, useRef } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetHubRequest } from 'api/generated/apiComponents';
import { shouldEnableUpdateHubConfig } from 'lib/permissions';
import { HubDetail, serializeHub } from 'components/HubDrawer/serializer';
import { notifyError } from 'lib/toast';
import HubCoordinates from 'components/HubCoordinates';
import HubWorkingHours from 'components/HubWorkingHours';

const Unauthorized = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col text-center">
      <p className="text-2xl font-bold text-white">{t('pages.hub-settings-page.unauthorized')}</p>
      <p className="font-medium text-flinkGray-light">
        {t('pages.hub-settings-page.no-permission')}
      </p>
    </div>
  );
};

const Navigation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { slug } = useParams();
  const { state } = useLocation();

  return (
    <div className="flex justify-between">
      <div className="flex items-center">
        <button
          className="mr-4 h-10 w-10 bg-flinkGray-dark px-2 flex items-center rounded-full"
          onClick={() => {
            if (state?.background) {
              navigate(`${ROUTE_PATHS.HUB_MANAGER}/${slug}`, {
                state: { background: state.background },
              });
            } else {
              navigate(`${ROUTE_PATHS.HUB_MANAGER}/${slug}`);
            }
          }}
          data-testid="back-button"
        >
          <IconArrowLeft />
        </button>
        <div className="my-4 text-4xl font-bold text-white">
          {t('pages.hub-settings-page.title')}
        </div>
      </div>
    </div>
  );
};

const HubSettingsPageContents = () => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const hubSlug = slug || '';
  const { data: hub } = useGetHubRequest<HubDetail>(
    { pathParams: { hubSlug } },
    {
      select: (data) => {
        return serializeHub(data);
      },
      onError: (error) => {
        const errorMessage = `${t('toast.error')}${error.payload ? `:${error.payload}` : ''}`;
        notifyError(errorMessage);
      },
    }
  );
  if (!hub) return null;

  return (
    <>
      <Navigation />
      <HubCoordinates hubSlug={hubSlug} hubCoordinates={hub.coordinates} />
      <HubWorkingHours hub={hub} />
    </>
  );
};

const HubSettingsPage = () => {
  const ref = useRef<HTMLDivElement>(null);
  const escape = useKeyPress('Escape');
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { state } = useLocation();
  const hasPermission = shouldEnableUpdateHubConfig();

  const path = state?.background
    ? `${ROUTE_PATHS.HUB_MANAGER}${state.background.search}`
    : ROUTE_PATHS.HUB_MANAGER;

  const onClose = useCallback(() => {
    queryClient.refetchQueries({
      queryKey: QUERY_KEYS.HUBS,
    });
    queryClient.resetQueries({ queryKey: QUERY_KEYS.GET_HUB_REQUEST, exact: true });
    queryClient.resetQueries({ queryKey: QUERY_KEYS.GET_ALL_TURFS_REQUEST, exact: true });
    navigate(path);
  }, [navigate, path, queryClient]);

  useEffect(() => {
    if (escape) {
      onClose();
    }
  }, [escape, onClose]);

  useOutsideClick(ref, () => {
    onClose();
  });

  return (
    <div
      className="w-full h-full flex fixed bg-background box-border flex-row items-center justify-end overflow-hidden top-0 right-0 z-10 transition-all ease-in-out duration-2000 transform"
      role="presentation"
    >
      <div
        className="bg-flinkGray-medium overflow-y-auto overflow-x-hidden w-3/5 h-full px-6 py-8"
        role="presentation"
        ref={ref}
      >
        {!hasPermission ? <Unauthorized /> : <HubSettingsPageContents />}
      </div>
    </div>
  );
};

export default HubSettingsPage;
