import Spinner from 'components/Spinner';
import { ChangeEvent, memo, useCallback, useState } from 'react';
import { shouldBeAbleToDeleteTurf, shouldEnableUpdatePDT } from 'lib/permissions';
import Toggle from 'components/Toggle';
import { PdtClosureRule, Turf } from 'api/generated/apiSchemas';
import isEqual from 'lodash.isequal';
import { usePatchTurfRequest } from 'api/generated/apiComponents';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'lib/constants';
import { notifySuccess, notifyError } from 'lib/toast';
import { useTranslation } from 'hooks';
import TurfDeleteButton from 'components/TurfDeleteButton';
import sdk from '@hubportal/sdk';

export type Pdt = {
  enabled: boolean;
  close_above: number;
  open_below: number;
};

type TurfPdtSettingPropsType = { turf: Turf };

const TurfPdtSetting = ({ turf }: TurfPdtSettingPropsType) => {
  const { t } = useTranslation();
  const [hasChange, setHasChange] = useState(false);
  const [hasError, setHasError] = useState(false);
  const hubSlug = turf.hub_slug;
  const turfId = turf.id;
  const isPdtEnabled = !!turf.closure_rules?.pdt?.enabled;
  const pdtCloseAbove = turf.closure_rules?.pdt?.close_above ?? 0;
  const pdtOpenBelow = turf.closure_rules?.pdt?.open_below ?? 0;
  const currentPdt = {
    enabled: isPdtEnabled,
    close_above: pdtCloseAbove,
    open_below: pdtOpenBelow,
  };
  const [pdt, setPdt] = useState<Pdt>(currentPdt);
  const enableUpdatePDT = shouldEnableUpdatePDT();
  const shouldDisabledInputPdt = !enableUpdatePDT || (enableUpdatePDT && !pdt.enabled);
  const isUserAbleToDeleteTurf = shouldBeAbleToDeleteTurf();
  const { mutate: updatePdtSetting, isLoading } = usePatchTurfRequest();
  const queryClient = useQueryClient();

  const handlePdtUpdate = useCallback(() => {
    if (hubSlug && turfId) {
      updatePdtSetting(
        {
          body: {
            closure_rules: {
              pdt,
            },
          },
          pathParams: { hubSlug, turfId },
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries({
              queryKey: QUERY_KEYS.GET_ALL_TURFS_REQUEST,
            });
            setHasChange(false);
            notifySuccess(t('toast.changes-applied-successfully'));
          },
          onError: (e) => {
            const errorMessage = `${t('toast.error')}${e.payload ? `:${e.payload}` : ''}`;
            sdk.reportError(new Error(String(e.payload)), '@flapp/hub-manager');
            notifyError(errorMessage);
          },
        }
      );
    }
  }, [hubSlug, pdt, queryClient, t, turfId, updatePdtSetting]);

  const handleUpdatePdt = (newValue: PdtClosureRule) => {
    const newPdt =
      newValue.enabled === false ? { ...currentPdt, ...newValue } : { ...pdt, ...newValue };
    const hasChange = !isEqual(turf.closure_rules?.pdt, newPdt);
    const getValidation = () => {
      if (
        pdt.open_below === 0 ||
        pdt.close_above === 0 ||
        newValue.close_above === 0 ||
        newValue.open_below === 0
      ) {
        return false;
      }
      if (typeof newValue.close_above === 'number') {
        return pdt.enabled && newValue.close_above >= pdt.open_below;
      }
      if (typeof newValue.open_below === 'number') {
        return pdt.enabled && newValue.open_below <= pdt.close_above;
      }
      if (typeof newValue.enabled === 'boolean') {
        return pdt.close_above >= pdt.open_below;
      }
      return false;
    };
    const isValid = getValidation();
    setHasError(!isValid);
    setHasChange(hasChange);
    setPdt(newPdt);
  };

  return (
    <div className="p-6  grow">
      <div className="flex items-center">
        <p className="text-xl font-bold text-white mr-2">
          {t('components.turf-management-card.toggle-label.auto-open-close')}
        </p>
        {enableUpdatePDT ? (
          <Toggle
            onChange={() => handleUpdatePdt({ enabled: !pdt.enabled })}
            value={pdt.enabled}
            dataTestid="auto-open-close"
          />
        ) : null}
      </div>
      <div className="mt-1">
        <p className="text-base text-white py-2">
          {t('components.turf-management-card.label.auto-close-pdt')}
        </p>
        <div
          className={`${
            hasError ? 'border-solid border-2 border-flinkPink' : 'border-none'
          } h-12 flex items-center justify-between w-fit p-3 bg-flinkGray-medium rounded-lg gap-4`}
        >
          <input
            type="number"
            min={1}
            className="text-base text-white w-20 border-none"
            value={pdt.close_above}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleUpdatePdt({ close_above: parseInt(e.target.value) })
            }
            aria-label="pdt_close_above"
            disabled={shouldDisabledInputPdt}
          />
          <p className="text-base text-white">{t('components.turf-management-card.label.min')}</p>
        </div>
        <p className="text-base text-white py-2 mt-2">
          {t('components.turf-management-card.label.auto-open-pdt')}
        </p>
        <div
          className={`${
            hasError ? 'border-solid border-2 border-flinkPink' : 'border-none'
          } h-12 flex items-center justify-between w-fit p-3 bg-flinkGray-medium rounded-lg gap-4`}
        >
          <input
            type="number"
            min={1}
            className="text-base text-white w-20 border-none"
            value={pdt.open_below}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleUpdatePdt({ open_below: parseInt(e.target.value) })
            }
            aria-label="pdt_open_below"
            disabled={shouldDisabledInputPdt}
          />
          <p className="text-base text-white">{t('components.turf-management-card.label.min')}</p>
        </div>
        <div className="flex justify-end pt-2">
          {isUserAbleToDeleteTurf ? <TurfDeleteButton hubSlug={hubSlug} turfId={turfId} /> : null}
          {enableUpdatePDT ? (
            <button
              className="flex items-center justify-center text-white bg-flinkPink leading-none p-4 rounded fontFamily-sans text-lg font-extrabold h-13 disabled:bg-flinkGray-light w-42"
              onClick={handlePdtUpdate}
              data-testid="save-values-pdf"
              disabled={!hasChange || hasError}
            >
              {isLoading ? (
                <Spinner size={5} />
              ) : (
                t('components.turf-management-card.button.save-changes')
              )}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default memo(TurfPdtSetting);
