import {
  configIsHubActive,
  getClosureReasonState,
  isTimeNowInsideWorkingHour,
  WorkingHour,
} from 'lib/helpers';
import { ListState, ListTurf } from 'api/generated/apiSchemas';

export const getHubStatusConfig = ({
  hubState,
  turfs,
  workingHour,
  openDate,
  closeDate,
}: {
  hubState: ListState;
  turfs?: ListTurf[];
  workingHour: WorkingHour;
  openDate?: string | null;
  closeDate?: string | null;
}) => {
  const isHubActive = configIsHubActive({ openDate, closeDate });

  if (!isHubActive) {
    return {
      //Hub status: Inactive - not operating
      title: 'components.hub-status-indicator.inactive',
      text: 'components.hub-status-indicator.inactive.reason.not-operating',
      borderColor: 'border-flinkGray-light',
    };
  }

  const isInWorkingHour = isTimeNowInsideWorkingHour(workingHour);

  if (!isInWorkingHour) {
    return {
      //Hub status: Closed - outside of business hour
      title: 'components.hub-status-indicator.closed',
      text: 'components.hub-status-indicator.closed.reason.after-business-hours',
      borderColor: 'border-red',
    };
  }

  const isHubDisabled = hubState.force_close;

  if (isHubDisabled)
    return {
      //Hub status: Closed manually - emergency closure is active
      title: 'components.hub-status-indicator.closed',
      text: 'components.hub-status-indicator.closed.reason.closed-manually',
      borderColor: 'border-red',
    };
  const isHubTemporaryClosed =
    hubState.closure_reasons &&
    Object.values(hubState.closure_reasons).some((value) => getClosureReasonState(value));
  if (isHubTemporaryClosed) {
    //Hub status: Closed temporarily - when any of closure_reasons at hub-level are true
    return {
      title: 'components.hub-status-indicator.closed',
      text: 'components.hub-status-indicator.closed.reason.closed-temporary',
      borderColor: 'border-red',
    };
  }
  const isAllTurfEnabled = turfs?.every((turf) => {
    const hasNoClosureReasons = turf.state?.closure_reasons
      ? Object.values(turf.state.closure_reasons).every(
          (value) => getClosureReasonState(value) === false
        )
      : true;
    return !turf.state?.force_close && hasNoClosureReasons;
  });

  if (isAllTurfEnabled) {
    //Hub status: Operational - all turfs are enabled
    return {
      title: 'components.hub-status-indicator.operational',
      text: 'components.hub-status-indicator.operational.reason',
      borderColor: 'border-veggieGreen-dark',
    };
  }
  const isAllTurfDisabled = turfs?.every(
    (turf) =>
      turf.state?.force_close ||
      (turf.state?.closure_reasons &&
        Object.values(turf.state.closure_reasons).some((value) => getClosureReasonState(value)))
  );
  if (isAllTurfDisabled) {
    //Hub status: Closed - all turfs are disabled
    return {
      title: 'components.hub-status-indicator.closed',
      text: 'components.hub-status-indicator.closed.reason.disabled',
      borderColor: 'border-red',
    };
  }
  //Hub status: Partially Operational - any of turfs are enabled
  return {
    title: 'components.hub-status-indicator.partially-operational',
    text: 'components.hub-status-indicator.partially-operational.reason',
    borderColor: 'border-orange',
  };
};
