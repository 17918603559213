import { AuditLog } from 'api/generated/apiSchemas';
import AuditLogsContainer from 'components/AuditLogsContainer';
import { useTranslation } from 'hooks';

const AuditLogs = ({ auditLogs }: { auditLogs: AuditLog[] | undefined }) => {
  const { t } = useTranslation();
  const logs = auditLogs;

  if (!logs || !logs.length) {
    return (
      <div className="py-3">
        <p className="text-white text-lg font-light">
          {t('pages.audit-logs-page.no-log-displayed')}
        </p>
      </div>
    );
  }
  return (
    <div>
      {logs.map((log: AuditLog) => (
        <AuditLogsContainer log={log} key={log.id} />
      ))}
    </div>
  );
};

export default AuditLogs;
