import { IconCheck, IconArrowDownLg, IconArrowUpLg } from 'assets/icons';
import { memo, useCallback, useEffect, useRef, useState } from 'react';

import { useKeyPress, useOutsideClick } from 'hooks/index';

export type Option = {
  value: string;
  label: string;
  disabled?: boolean;
};

export type SelectProps = {
  name?: string;
  placeholder?: string;
  options: Array<Option>;
  selectedOption?: Option;
  onSelect?: (option: Option) => void;
  isDisabled?: boolean;
  displayValue?: string;
};

const SingleSelect = ({
  name,
  placeholder,
  options,
  selectedOption,
  onSelect,
  isDisabled,
  displayValue = selectedOption?.label || '',
}: SelectProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const escape = useKeyPress('Escape');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (escape && isOpen) {
      onClose();
    }
  }, [escape, isOpen, onClose]);

  useOutsideClick(ref, () => {
    if (isOpen) {
      onClose();
    }
  });

  const onSelectOption = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    option: Option
  ) => {
    event.preventDefault();
    onClose();
    onSelect && onSelect(option);
  };
  const Icon = isOpen ? IconArrowUpLg : IconArrowDownLg;

  return (
    <div className="relative" ref={ref}>
      <button
        className="flex justify-between items-center min-w-fit h-12 rounded-lg p-2 text-white"
        onClick={() => setIsOpen(!isOpen)}
        disabled={isDisabled}
        type="button"
      >
        <input
          className="block truncate text-base border-0 py-3 pr-8 w-full text-left placeholder-flinkGray-light caret-transparent bg-transparent"
          value={displayValue}
          disabled={isDisabled}
          name={name}
          placeholder={placeholder}
        />
        <Icon />
      </button>
      {isOpen && (
        <ul className="absolute w-full min-w-48 z-10 bg-flinkGray max-h-96 rounded-md py-1 border-0 overflow-auto text-sm">
          {options.map((option: Option) => (
            <li key={option.value} className="hover:bg-flinkGray-medium">
              <button
                className="flex items-center justify-between p-2 my-0.5 hover:bg-flinkGray-medium w-full border-b-0 text-base text-white disabled:opacity-60 disabled:bg-flinkGray-medium disabled:cursor-not-allowed"
                onClick={(event) => onSelectOption(event, option)}
                type="button"
                disabled={option.disabled}
              >
                <span className="ml-3 block truncate">{option.label}</span>
                {selectedOption?.value === option.value && <IconCheck color="#E31C79" />}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default memo(SingleSelect);
