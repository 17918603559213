import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import HubStatusIndicator from 'components/HubStatusIndicator';
import { useKeyPress, useOutsideClick, useTranslation } from 'hooks';
import HubEmergencyClosureModal from 'components/HubEmergencyClosureModal';
import {
  useGetAllTurfsRequest,
  useHubPatchRequest,
  useGetHubRequest,
} from 'api/generated/apiComponents';
import { ListState, Turf } from 'api/generated/apiSchemas';
import { HubDetail, serializeHub } from './serializer';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { QUERY_KEYS, ROUTE_PATHS } from 'lib/constants';
import { notifyError, notifySuccess } from 'lib/toast';
import TurfsContainer from 'components/TurfsContainer';
import ClickAndCollect from 'components/ClickAndCollect';
import ScheduledClosuresInfoBar from 'components/ScheduledClosuresInfoBar';
import HubSettingDropdownMenu from 'components/HubSettingDropdownMenu';
import ImportTurfButton from 'components/ImportTurfButton';
import CreateTurfButton from 'components/CreateTurfButton';
import sdk from '@hubportal/sdk';
import useTurfsArea from 'stores/useTurfsArea';
import WorkingHoursDropDown from 'components/WorkingHoursDropDown';
import { EmergencyClosureReasons } from 'lib/types';

const HubDrawerContents = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { slug } = useParams();
  const hubSlug = slug || '';
  const path = state?.background
    ? `${state.background.pathname}${state.background.search}`
    : ROUTE_PATHS.HUB_MANAGER;
  const [isHubEmergencyClosureModalVisible, setIsHubEmergencyClosureModalVisible] = useState(false);
  const { mutate } = useHubPatchRequest();
  const queryClient = useQueryClient();
  const { data: hub } = useGetHubRequest<HubDetail>(
    { pathParams: { hubSlug } },
    {
      select: (data) => {
        return serializeHub(data);
      },
      onError: (error) => {
        const errorMessage = `${t('toast.error')}${error.payload ? `:${error.payload}` : ''}`;
        notifyError(errorMessage);
        navigate(path);
      },
    }
  );

  const { setCurrentExistingTurfs } = useTurfsArea();

  const { data: turfs, error } = useGetAllTurfsRequest<Turf[]>(
    {
      pathParams: { hubSlug },
    },
    {
      onSuccess: (data) => setCurrentExistingTurfs(data),
    }
  );

  const toggleHubEmergencyClosureModal = () => {
    setIsHubEmergencyClosureModalVisible(
      (isHubEmergencyClosureModalVisible) => !isHubEmergencyClosureModalVisible
    );
  };

  const isHubDisabled = hub?.forceClose;

  const shouldDisableHubEmergencyClosure = useMemo(() => {
    return turfs?.every((turf) => !turf.enabled) && !isHubDisabled;
  }, [isHubDisabled, turfs]);

  const hubState: ListState = {
    force_close: isHubDisabled,
    closure_reasons: hub?.closureReasons,
  };
  const handleUpdateHubState = (reason?: EmergencyClosureReasons) => {
    const comment = reason ? { comment: reason } : null;
    mutate(
      {
        body: { force_close: { active: !isHubDisabled, ...comment } },
        pathParams: { hubSlug },
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: QUERY_KEYS.GET_HUB_REQUEST,
          });
          await queryClient.invalidateQueries({
            queryKey: QUERY_KEYS.HUBS,
          });
          notifySuccess(t('toast.changes-applied-successfully'));
        },
        onError: (e) => {
          const errorMessage = `${t('toast.error')}${e.payload ? `:${e.payload}` : ''}`;
          sdk.reportError(new Error(String(e.payload)), '@flapp/hub-manager');
          notifyError(errorMessage);
        },
        onSettled: () => setIsHubEmergencyClosureModalVisible(false),
      }
    );
  };

  if (!hub) return null;

  return (
    <>
      <div className="flex justify-between">
        <div className="flex items-center">
          <p className="text-4xl font-bold fontFamily-flink text-white" data-testid="hubName">
            {hub.name}
          </p>
        </div>
        <HubSettingDropdownMenu
          hubSlug={hubSlug}
          hubName={hub.name}
          closureReasons={hub.closureReasons}
          isHubDisabled={isHubDisabled}
          shouldDisableHubEmergencyClosure={shouldDisableHubEmergencyClosure}
        />
      </div>
      <div className="py-6">
        <p
          className="text-base fontFamily-flink font-medium text-flinkGray-light pb-1"
          data-testid="hubAddress"
        >
          {hub.address}
        </p>
        <p className="text-sm fontFamily-flink font-small text-flinkGray-light pb-1">
          {t('components.turfoperationalmodal.hub-open-date-label')} :{' '}
          {hub.openDate ? new Date(hub.openDate).toLocaleDateString() : '-'}
        </p>
        <p className="text-sm fontFamily-flink font-small text-flinkGray-light pb-1">
          {t('components.turfoperationalmodal.hub-close-date-label')} :{' '}
          {hub.closeDate ? new Date(hub.closeDate).toLocaleDateString() : '-'}
        </p>
        <p className="text-sm fontFamily-flink font-small text-flinkGray-light pb-1">
          GLN : {hub.gln || '-'}
        </p>
        <div className="flex items-center my-2">
          <p className="font-medium text-flinkGray-light">
            {t('components.turfoperationalmodal.working-hours-label')}
          </p>
          <WorkingHoursDropDown workinghours={hub.workingHours} />
        </div>
        <ScheduledClosuresInfoBar hubSlug={hubSlug} closureReasons={hub.closureReasons} />
      </div>
      <div className="flex space-x-4">
        <HubStatusIndicator
          hubState={hubState}
          turfs={turfs}
          isDark
          workingHour={hub.workingHour}
          openDate={hub.openDate}
          closeDate={hub.closeDate}
        />
        <ClickAndCollect isClickAndCollectActive={hub.clickAndCollectActive} hubSlug={hubSlug} />
      </div>
      <div className="flex justify-between pt-10 items-center">
        <p className="text-2xl font-bold fontFamily-flink text-white">
          {t('components.turfoperationalmodal.turf-management')}
        </p>
        <div className="flex justify-between items-center space-x-4">
          <CreateTurfButton hubSlug={hubSlug} turfs={turfs} />
          <ImportTurfButton />
        </div>
      </div>
      {!error ? (
        <TurfsContainer
          turfs={turfs}
          hubCoordinates={hub.coordinates}
          isHubDisabled={isHubDisabled}
          workingHour={hub.workingHour}
          openDate={hub.openDate}
          closeDate={hub.closeDate}
        />
      ) : (
        <div>
          <p className=" text-white text-2xl font-bold pb-4">
            {t('components.turfoperationalmodal.error-in-turf')}
          </p>
        </div>
      )}
      <div>
        {isHubEmergencyClosureModalVisible && (
          <HubEmergencyClosureModal
            onClickCancel={toggleHubEmergencyClosureModal}
            hubName={hub.name}
            updateHubState={handleUpdateHubState}
          />
        )}
      </div>
    </>
  );
};
const HubDrawer = () => {
  const ref = useRef<HTMLDivElement>(null);
  const escape = useKeyPress('Escape');
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { state } = useLocation();
  const path = state?.background
    ? `${state.background.pathname}${state.background.search}`
    : ROUTE_PATHS.HUB_MANAGER;
  const onClose = useCallback(() => {
    queryClient.refetchQueries({
      queryKey: QUERY_KEYS.HUBS,
    });
    queryClient.removeQueries({ queryKey: QUERY_KEYS.GET_HUB_REQUEST, exact: true });
    queryClient.removeQueries({ queryKey: QUERY_KEYS.GET_ALL_TURFS_REQUEST, exact: true });
    navigate(path);
  }, [navigate, path, queryClient]);

  useEffect(() => {
    if (escape) {
      onClose();
    }
  }, [escape, onClose]);

  useOutsideClick(ref, () => {
    onClose();
  });

  return (
    <div
      className="w-full h-full flex fixed bg-background box-border flex-row items-center justify-end overflow-hidden top-0 right-0 z-10 transition-all ease-in-out duration-2000 transform"
      role="presentation"
      data-testid="hubDetailsId"
    >
      <div
        className="bg-flinkGray-medium overflow-y-auto overflow-x-hidden w-3/5 h-full px-6 py-11"
        role="presentation"
        ref={ref}
      >
        <HubDrawerContents />
      </div>
    </div>
  );
};

export default HubDrawer;
