import { TO, WEEKDAYS, WEEKDAYS_NO } from 'lib/constants';
import { useState } from 'react';
import { useTranslation } from 'hooks';
import { Icon } from '@hubportal/components';
import WorkingHoursSelect from 'components/WorkingHoursSelect';
import { getSortedWeekdays, getTimeOptions } from 'lib/helpers';
import { WorkingHours } from 'api/generated/apiSchemas';
import isEqual from 'lodash.isequal';
import { useHubPatchRequest } from 'api/generated/apiComponents';
import sdk from '@hubportal/sdk';
import { notifyError, notifySuccess } from 'lib/toast';
import { useParams } from 'react-router-dom';
import { HubWorkingHoursProps, MultiWorkingHours, WeekDayProps, WorkingHourProps } from 'lib/types';

const WeekDays = ({ selectedWorkingHours, setSelectedWorkingHours }: WeekDayProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex gap-4 py-4">
      {Object.values(WEEKDAYS_NO).map((dayNo) => {
        const weekdayNo = Number(dayNo);
        const isSelectedWeekday = selectedWorkingHours.some(
          (selectedWorkingHour) => selectedWorkingHour.day_no === weekdayNo
        );
        return (
          <button
            key={weekdayNo}
            className={`${
              isSelectedWeekday
                ? 'bg-flinkPink-light text-flinkPink'
                : 'border border-flinkGray text-flinkGray-light'
            }  h-10 w-15 rounded font-medium cursor-pointer`}
            onClick={() => {
              if (isSelectedWeekday) {
                setSelectedWorkingHours(
                  selectedWorkingHours.filter(
                    (selectedWorkingHour) => selectedWorkingHour.day_no !== weekdayNo
                  )
                );
              } else {
                const selectedWeekdays = [
                  ...selectedWorkingHours,
                  {
                    day_no: weekdayNo,
                    working_hours: [{ open_time: '', close_time: '' }],
                  },
                ];
                setSelectedWorkingHours(selectedWeekdays);
              }
            }}
          >
            {t(`components.hub-working-hours.day_of_week.${WEEKDAYS[weekdayNo]}`)}
          </button>
        );
      })}
    </div>
  );
};

const WorkingHour = ({
  selectedWorkingHours,
  setSelectedWorkingHours,
  workingHour,
  indexOfWorkingHour,
  weekday,
}: WorkingHourProps) => {
  const props = { id: 'plus-icon' };
  const { t } = useTranslation();
  const enableAddWorkingHour =
    weekday.working_hours.length > 1
      ? indexOfWorkingHour === weekday.working_hours.length - 1
      : true;
  const enableRemoveWorkingHour =
    weekday.working_hours.length > 1 ? indexOfWorkingHour !== 0 : false;

  const handleOnSelectInput = (value: WorkingHours) => {
    const newSelectedWorkingHours = selectedWorkingHours.map((selectedWorkingHour) => {
      if (selectedWorkingHour.day_no === weekday.day_no) {
        const workingHours = selectedWorkingHour.working_hours;
        const newWorkingHours = workingHours.map((workingHour, index) => {
          if (index === indexOfWorkingHour) {
            return { ...workingHour, ...value };
          }
          return workingHour;
        });
        return {
          ...selectedWorkingHour,
          working_hours: newWorkingHours,
        };
      }
      return selectedWorkingHour;
    });
    setSelectedWorkingHours(newSelectedWorkingHours);
  };
  return (
    <div key={indexOfWorkingHour} className="flex items-center w-64">
      <div className="flex items-center">
        <WorkingHoursSelect
          options={getTimeOptions()}
          onSelectInput={(value) => handleOnSelectInput({ open_time: value })}
          selectedOption={workingHour.open_time}
        />
        <span className="mx-2 text-white"> {t('components.hub-working-hours.to')}</span>
        <WorkingHoursSelect
          options={getTimeOptions(TO, workingHour.open_time)}
          onSelectInput={(value) => handleOnSelectInput({ close_time: value })}
          selectedOption={workingHour.close_time}
        />
      </div>
      {/* Hidden until we have BE ready to accept more than one Working hours per day */}
      <div className="hidden flex w-24 min-w-20 mx-2 gap-2">
        {enableAddWorkingHour && (
          <button
            className="h-8 w-8 bg-flinkGray-dark px-2 flex items-center rounded-full text-white"
            onClick={() => {
              const newSelectedWorkingHours = selectedWorkingHours.map((selectedWorkingHour) => {
                if (selectedWorkingHour.day_no === weekday.day_no) {
                  return {
                    ...selectedWorkingHour,
                    working_hours: [
                      ...selectedWorkingHour.working_hours,
                      { open_time: '', close_time: '' },
                    ],
                  };
                }
                return selectedWorkingHour;
              });
              setSelectedWorkingHours(newSelectedWorkingHours);
            }}
          >
            <Icon type="plus" {...props} />
          </button>
        )}
        {enableRemoveWorkingHour && (
          <button
            className="h-8 w-8 bg-flinkGray-dark px-2 flex items-center rounded-full text-white ml-auto"
            onClick={() => {
              const newSelectedWorkingHours = selectedWorkingHours.map((selectedWorkingHour) => {
                if (selectedWorkingHour.day_no === weekday.day_no) {
                  const workingHours = selectedWorkingHour.working_hours;
                  workingHours.splice(indexOfWorkingHour, 1);
                  return {
                    ...selectedWorkingHour,
                    working_hours: workingHours,
                  };
                }
                return selectedWorkingHour;
              });
              setSelectedWorkingHours(newSelectedWorkingHours);
            }}
          >
            <Icon type="minus" {...props} />
          </button>
        )}
      </div>
    </div>
  );
};

const WeeklyWorkngHours = ({ selectedWorkingHours, setSelectedWorkingHours }: WeekDayProps) => {
  const { t } = useTranslation();
  const sortedSelectedWorkingHours = getSortedWeekdays(selectedWorkingHours);
  return (
    <div className="flex flex-col py-4 w-full gap-4">
      {sortedSelectedWorkingHours.map((weekday) => {
        if (weekday.day_no === undefined) return null;
        return (
          <div key={weekday.day_no} className="flex justify-start items-center">
            <p className="font-medium text-lg text-white self-start py-1 leading-10 w-48">
              {t(`components.hub-working-hours.day_of_week.full.${WEEKDAYS[weekday.day_no]}`)}
            </p>
            <div className="flex flex-col gap-4">
              {weekday.working_hours.map((workingHour, indexOfWorkingHour) => (
                <WorkingHour
                  key={indexOfWorkingHour}
                  selectedWorkingHours={selectedWorkingHours}
                  setSelectedWorkingHours={setSelectedWorkingHours}
                  workingHour={workingHour}
                  indexOfWorkingHour={indexOfWorkingHour}
                  weekday={weekday}
                />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const HubWorkingHours = ({ hub }: HubWorkingHoursProps) => {
  const { t } = useTranslation();
  const { mutate } = useHubPatchRequest();
  const { slug } = useParams();
  const hubSlug = slug || '';

  const currentWorkingHours = hub.workingHours
    ? hub.workingHours.map((weekday) => ({
        day_no: weekday.day_no,
        working_hours: [{ open_time: weekday.open_time, close_time: weekday.close_time }],
      }))
    : [];

  const [selectedWorkingHours, setSelectedWorkingHours] =
    useState<MultiWorkingHours>(currentWorkingHours);

  const hasNoChange = isEqual(currentWorkingHours, selectedWorkingHours);
  const handleUpdateWorkingHour = () => {
    const singleWorkingHours = selectedWorkingHours.map(({ day_no, working_hours }) => ({
      day_no,
      open_time: working_hours[0].open_time,
      close_time: working_hours[0].close_time,
    }));
    mutate(
      {
        body: { working_hours: singleWorkingHours },
        pathParams: { hubSlug },
      },
      {
        onSuccess: () => {
          notifySuccess(t('toast.changes-applied-successfully'));
        },
        onError: (e) => {
          const errorMessage = `${t('toast.error')}${e.payload ? `:${e.payload}` : ''}`;
          sdk.reportError(new Error(String(e.payload)), '@flapp/hub-manager');
          notifyError(errorMessage);
        },
      }
    );
  };

  return (
    <div className="my-4 w-full">
      <p className="text-2xl font-bold text-white">{t('components.hub-working-hours.title')}</p>
      <WeekDays
        selectedWorkingHours={selectedWorkingHours}
        setSelectedWorkingHours={setSelectedWorkingHours}
      />
      <WeeklyWorkngHours
        selectedWorkingHours={selectedWorkingHours}
        setSelectedWorkingHours={setSelectedWorkingHours}
      />
      <button
        className="text-white bg-flinkPink w-56 leading-none p-4 rounded-lg fontFamily-sans text-lg font-extrabold h-12 disabled:bg-flinkGray-light"
        type="submit"
        disabled={hasNoChange}
        onClick={handleUpdateWorkingHour}
      >
        {t('components.hub-working-hours.save')}
      </button>
    </div>
  );
};

export default HubWorkingHours;
