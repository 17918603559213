import { Map } from 'leaflet';
import isEqual from 'lodash.isequal';
import { useEffect, useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { MapContainer, MapContainerProps } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';

import 'leaflet/dist/leaflet.css';

const defaultStyles = [
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [{ visibility: 'off' }],
  },
];

const ReactLeafletGoogleMap = ({
  children,
  className,
  bounds,
  center,
  zoom,
  maxZoom,
  ...props
}: MapContainerProps & { children: React.ReactNode }) => {
  const mapRef = useRef<Map>(null);

  useEffect(() => {
    if (mapRef.current && center && zoom) {
      mapRef.current.setView(center, zoom);
    }
  }, [mapRef, center, zoom]);

  const boundsRef = useRef(bounds);

  if (!isEqual(boundsRef.current, bounds)) {
    boundsRef.current = bounds;
  }

  useEffect(() => {
    if (mapRef.current && bounds) {
      mapRef.current.fitBounds(bounds, { maxZoom });
    }
  }, [mapRef, bounds, boundsRef, maxZoom]);

  const reactLeafletGoogleLayerprops = {
    type: 'roadmap',
    noWrap: true,
    styles: defaultStyles,
  };

  return (
    <ErrorBoundary FallbackComponent={() => <></>}>
      <MapContainer
        ref={mapRef}
        className={`${className || ''} z-10 h-full w-full `}
        attributionControl={false}
        bounds={bounds}
        center={center}
        zoom={zoom}
        {...props}
      >
        <ReactLeafletGoogleLayer
          apiKey={process.env.GOOGLE_MAPS_KEY || ''}
          {...reactLeafletGoogleLayerprops}
        />
        {children}
      </MapContainer>
    </ErrorBoundary>
  );
};

export default ReactLeafletGoogleMap;
