import { ListHub } from 'api/generated/apiSchemas';
import { COUNTRIES, STATUS } from 'lib/constants';
import {
  configIsHubActive,
  getClosureReasonState,
  getWorkingHours,
  isTimeNowInsideWorkingHour,
} from 'lib/helpers';

export type SearchParams = {
  search?: string;
  country?: Array<string>;
  city?: Array<string>;
  status?: Array<string>;
};

export type FiltersProps = {
  hubs: ListHub[];
  searchQuery?: string;
  countries?: Array<string>;
  cities?: Array<string>;
  statuses?: Array<string>;
};

export const getSearchParams = (searchParams: URLSearchParams) => {
  const countries = searchParams.getAll('country');
  const cities = searchParams.getAll('city');
  const statuses = searchParams.getAll('status');
  const searchQuery = searchParams.get('search') || '';

  return { countries, cities, statuses, searchQuery };
};

export const getCountryAndCityOptions = (hubs?: ListHub[]) => {
  if (!hubs) return { countryOptions: [], cityOptions: [] };
  const countriesWithDuplicates = hubs.map((hub) => hub.country);
  const countries = [...new Set(countriesWithDuplicates)];
  const countryOptions = COUNTRIES.filter((country) => countries.includes(country.value));
  const citiesWithDuplicates = hubs
    .filter((hub) => countries.includes(hub.country))
    .map((hub) => hub.city);
  const cities = [...new Set(citiesWithDuplicates)];
  const cityOptions = cities
    .filter((city): city is string => !!city)
    .map((city) => {
      return { value: city, label: city };
    });
  return { countryOptions, cityOptions };
};

export const getHubStatus = (hub: ListHub) => {
  const isHubActive = configIsHubActive({
    openDate: hub.open_date,
    closeDate: hub.close_date,
  });
  if (!isHubActive) return STATUS.INACTIVE;
  const workingHour = getWorkingHours(hub.working_hours);
  const isInWorkingHour = isTimeNowInsideWorkingHour(workingHour);
  const isHubDisabled = hub.state?.force_close;
  const isHubTemporaryClosed =
    hub.state?.closure_reasons &&
    Object.values(hub.state.closure_reasons).some((value) => getClosureReasonState(value));
  const isAllTurfDisabled = hub.turfs?.every(
    (turf) =>
      turf.state?.force_close ||
      (turf.state?.closure_reasons &&
        Object.values(turf.state.closure_reasons).some((value) => getClosureReasonState(value)))
  );
  if (!isInWorkingHour || isHubDisabled || isHubTemporaryClosed || isAllTurfDisabled)
    return STATUS.CLOSED;

  const isAllTurfEnabled = hub.turfs?.every((turf) => {
    const hasTufClosureReasons = turf.state?.closure_reasons;
    const isTurfEnabled = hasTufClosureReasons
      ? Object.values(hasTufClosureReasons).every((value) => getClosureReasonState(value) === false)
      : true;
    return !turf.state?.force_close && isTurfEnabled;
  });

  if (isAllTurfEnabled) return STATUS.OPERATIONAL;

  return STATUS.PARTIALLY_OPERATIONAL;
};

export const getFilteredHubs = ({
  hubs,
  countries,
  cities,
  statuses,
  searchQuery,
}: FiltersProps) => {
  let filtered = hubs;
  if (countries?.length)
    filtered = filtered.filter((hub: ListHub) => hub.country && countries.includes(hub.country));
  if (cities?.length)
    filtered = filtered.filter((hub: ListHub) => hub.city && cities.includes(hub.city));
  if (statuses?.length)
    filtered = filtered.filter((hub: ListHub) => {
      const hubState = getHubStatus(hub);
      return statuses?.includes(hubState);
    });
  if (searchQuery) {
    filtered = filtered.filter((hub: ListHub) => {
      const query = searchQuery.toLowerCase();
      return (
        (hub.name && hub.name.toString().toLowerCase().indexOf(query) > -1) ||
        (hub.slug && hub.slug.toString().toLowerCase().indexOf(query) > -1)
      );
    });
  }
  return filtered;
};
