import { DEFAULT_START_TIME, DEFAULT_END_DAY_TIME } from 'lib/constants';
import { getZonedDate } from 'lib/helpers';
import { DaySelectOptions, DEFAULT_PAGE_SIZE, DEFAULT_PAGE, Option } from './constants';

const TODAY = getZonedDate();

export const getCurrentValues = (searchParams: URLSearchParams) => {
  const query = searchParams?.get('query') || '';
  const limit = Number(searchParams?.get('limit') ?? DEFAULT_PAGE_SIZE);
  const page = Number(searchParams?.get('page') ?? DEFAULT_PAGE);
  const events = searchParams?.get('events')?.split(',').filter(Boolean) || [];
  const selectedDay = DaySelectOptions.find(
    (item) => item.value === searchParams?.get('selectedDay')
  ) || { value: 'today', label: 'Today' };
  const from = searchParams.get('from') ? new Date(searchParams.get('from') as string) : TODAY;
  const to = searchParams.get('to') ? new Date(searchParams.get('to') as string) : TODAY;
  const fromTime = searchParams.get('fromTime')
    ? ({
        value: searchParams.get('fromTime'),
        label: searchParams.get('fromTime'),
      } as Option)
    : { value: DEFAULT_START_TIME, label: DEFAULT_START_TIME };
  const toTime = searchParams?.get('toTime')
    ? ({ value: searchParams.get('toTime'), label: searchParams.get('toTime') } as Option)
    : { value: DEFAULT_END_DAY_TIME, label: DEFAULT_END_DAY_TIME };

  return { events, query, selectedDay, from, to, fromTime, toTime, limit, page };
};
