import { IconEarthPin, IconFlinkHub, IconBook } from 'assets/icons';
import { ROUTE_PATHS } from 'lib/constants';
import { shouldBeAbleToViewAuditLogs } from 'lib/permissions';
import { useTranslation } from 'hooks';
import { Outlet, useLocation, useNavigate, Location } from 'react-router-dom';

const buttonConfig = (page: string, state?: Location['state']) => {
  const hubManagerPath = state?.search
    ? `${ROUTE_PATHS.HUB_MANAGER}${state.search}`
    : ROUTE_PATHS.HUB_MANAGER;
  return [
    {
      Icon: IconFlinkHub,
      label: 'components.layout.menu.hub-list',
      isCurrentPage: page === ROUTE_PATHS.HUB_MANAGER,
      path: hubManagerPath,
      enabled: true,
    },
    {
      Icon: IconEarthPin,
      label: 'components.layout.menu.delivery-area',
      isCurrentPage: page === ROUTE_PATHS.HUB_DELIVERY_AREA,
      path: ROUTE_PATHS.HUB_DELIVERY_AREA,
      enabled: true,
    },
    {
      Icon: IconBook,
      label: 'components.layout.menu.audit-logs',
      isCurrentPage: page === ROUTE_PATHS.AUDIT_LOGS_PAGE,
      path: ROUTE_PATHS.AUDIT_LOGS_PAGE,
      enabled: shouldBeAbleToViewAuditLogs(),
    },
  ];
};

const Header = () => {
  const { t } = useTranslation();
  const { state, pathname, search } = useLocation();
  const navigate = useNavigate();
  return (
    <div className="w-full flex items-center justify-between h-22 border-b border-flinkGray px-10">
      <div className="text-2xl font-bold text-white" data-testid="title">
        {t('components.layout.title')}
      </div>
      <div className="flex gap-7">
        {buttonConfig(pathname, state)
          .filter(({ enabled }) => enabled)
          .map(({ Icon, label, isCurrentPage, path }) => {
            return (
              <button
                key={label}
                className={`${
                  isCurrentPage
                    ? 'text-white'
                    : 'text-flinkGray-light cursor-pointer hover:border-b-2 hover:border-flinkPink'
                } flex items-center gap-2 pb-1 font-medium text-sm`}
                disabled={isCurrentPage}
                onClick={() => {
                  if (search) {
                    navigate(path, {
                      state: { search },
                    });
                  } else {
                    navigate(path);
                  }
                }}
              >
                <Icon color={isCurrentPage ? '#FFFFFF' : '#BFBFBF'} />
                {t(label)}
              </button>
            );
          })}
      </div>
    </div>
  );
};

const Layout = () => {
  return (
    <div className="h-screen overflow-hidden bg-flinkGray-medium text-flinkGray-light">
      <Header />
      <Outlet />
    </div>
  );
};

export default Layout;
