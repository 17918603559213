const IconSelect = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <ellipse cx="12" cy="12" rx="10" ry="10" fill="#E31C79" />
        <path
          d="M16.3752 9.0835L10.2502 15.2085L7.3335 12.2918"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
export default IconSelect;
