import { IconArrowDownLg, IconArrowUpLg } from 'assets/icons';
import { memo, useCallback, useEffect, useRef, useState } from 'react';

import { TFunction } from 'i18next';
import { useKeyPress, useOutsideClick, useTranslation } from 'hooks/index';
import { WorkingHours } from 'api/generated/apiSchemas';
import { getFormattedWorkingHours } from 'lib/helpers';

export type WorkingHoursDropDownPropsType = {
  workinghours?: WorkingHours[];
};

type WorkingHourType = {
  weekday: string;
  isToday: boolean;
  open_time: string | undefined;
  close_time: string | undefined;
};

const getFormattedDisplayedValue = (workingHour: WorkingHourType, t: TFunction) => {
  const displayValue =
    workingHour?.open_time && workingHour?.close_time
      ? `${workingHour.open_time} - ${workingHour.close_time}`
      : t('components.working-hours-dropdown.closed');

  return `${t(
    `components.working-hours-dropdown.day_of_week.${workingHour.weekday}`
  )}: ${displayValue}`;
};

const WorkingHoursDropDown = ({ workinghours }: WorkingHoursDropDownPropsType) => {
  const ref = useRef<HTMLDivElement>(null);
  const escape = useKeyPress('Escape');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { t } = useTranslation();
  const formattedWorkingHours = getFormattedWorkingHours(workinghours);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (escape && isOpen) {
      onClose();
    }
  }, [escape, isOpen, onClose]);

  useOutsideClick(ref, () => {
    if (isOpen) {
      onClose();
    }
  });

  const today = formattedWorkingHours.find((workinghour) => workinghour.isToday);
  const displayValue = getFormattedDisplayedValue(today as WorkingHourType, t);

  return (
    <div className="w-52 h-12 relative mx-2" ref={ref}>
      <button
        className="flex justify-between items-center w-full h-full rounded-lg px-3 text-left font-medium text-flinkGray-light cursor-pointer bg-flinkGray"
        onClick={() => setIsOpen(true)}
        type="button"
      >
        {displayValue}
        <IconArrowDownLg className="w-5 h-5" />
      </button>
      {isOpen && (
        <ul
          className="absolute top-0 bg-flinkGray min-w-52 h-fit  rounded-lg px-3 py-2"
          aria-label="working-hours"
        >
          <IconArrowUpLg
            className="w-5 h-5 absolute top-3.5 right-3 cursor-pointer"
            onClick={() => setIsOpen(false)}
          />
          {formattedWorkingHours?.map((workingHour) => {
            const displayValue = getFormattedDisplayedValue(workingHour, t);
            return (
              <li
                key={workingHour.weekday}
                className={`py-2 ${
                  workingHour.isToday ? 'text-white font-medium' : 'text-flinkGray-light'
                }`}
              >
                {displayValue}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default memo(WorkingHoursDropDown);
