import { lazy, memo, Suspense } from 'react';
import { LatLngLiteral } from 'leaflet';
import TurfPdtSetting from 'components/TurfPdtSetting';
import { Turf } from 'api/generated/apiSchemas';

const TurfMap = lazy(() => import('components/TurfMap'));

type TurfDetailsPropsType = { turf: Turf; hubLocation?: LatLngLiteral };

const TurfDetails = ({ turf, hubLocation }: TurfDetailsPropsType) => {
  return (
    <div className="flex h-full border-t border-flinkGray-medium rounded-b-lg">
      <Suspense fallback={<div>...</div>}>
        <TurfMap className="rounded-bl-lg" hubLocation={hubLocation} turfLocations={turf.area} />
      </Suspense>
      <TurfPdtSetting turf={turf} />
    </div>
  );
};

export default memo(TurfDetails);
