import DateRangeSelect from 'components/DateRangeSelect';
import TimeSelect from 'components/TimeSelect';
import { format } from 'date-fns-tz';
import { useTranslation } from 'hooks';
import { Option } from 'components/SingleSelect';
import { getTimeOptions } from 'lib/helpers';

export type SelectedDateTimeType = {
  selectedDate?: Array<Date>;
  startTime: Option;
  endTime: Option;
};

type ScheduledClosureDatePropsType = {
  selectedDateTime: SelectedDateTimeType;
  handleSelect: (option: SelectedDateTimeType) => void;
};

const StartAndEndTimeSelect = ({
  selectedDateTime,
  handleSelect,
}: ScheduledClosureDatePropsType) => {
  const { t } = useTranslation();

  if (!selectedDateTime.selectedDate) return null;

  const pattern = 'dd.MM.yyyy';
  const formatedStartDate = selectedDateTime.selectedDate[0]
    ? ` - ${format(selectedDateTime.selectedDate[0], pattern)}`
    : '';
  const formatedEndDate = selectedDateTime.selectedDate[1]
    ? ` - ${format(selectedDateTime.selectedDate[1], pattern)}`
    : formatedStartDate;

  return (
    <div className="flex gap-4">
      <TimeSelect
        title={t('components.start-and-end-timeselect.closing-at.label')}
        options={getTimeOptions()}
        selectedOption={selectedDateTime.startTime}
        displayValue={`${selectedDateTime.startTime.value}${formatedStartDate}`}
        handleSelect={(option) => handleSelect({ ...selectedDateTime, startTime: option })}
        customClass="select-time-scheduled-closure"
      />
      <TimeSelect
        title={t('components.start-and-end-timeselect.reopening-at.label')}
        options={getTimeOptions()}
        selectedOption={selectedDateTime.endTime}
        displayValue={`${selectedDateTime.endTime.value}${formatedEndDate}`}
        handleSelect={(option) => handleSelect({ ...selectedDateTime, endTime: option })}
        customClass="select-time-scheduled-closure"
      />
    </div>
  );
};

const ScheduledClosureDate = ({
  selectedDateTime,
  handleSelect,
}: ScheduledClosureDatePropsType) => {
  return (
    <div className="py-6">
      <DateRangeSelect
        selectedDateRange={selectedDateTime.selectedDate}
        onChange={(option: Date[]) => handleSelect({ ...selectedDateTime, selectedDate: option })}
      />
      <StartAndEndTimeSelect selectedDateTime={selectedDateTime} handleSelect={handleSelect} />
    </div>
  );
};

export default ScheduledClosureDate;
