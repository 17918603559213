/**
 * Generated by @openapi-codegen
 *
 * @version v1
 */
import * as reactQuery from '@tanstack/react-query';
import { useApiContext, ApiContext } from './apiContext';
import type * as Fetcher from './apiFetcher';
import { apiFetch } from './apiFetcher';
import type * as Schemas from './apiSchemas';
import type * as RequestBodies from './apiRequestBodies';
import type * as Responses from './apiResponses';

export type GetAuditLogRequestPathParams = {
  /**
   * ID for the AuditLog to fetch
   */
  id: string;
};

export type GetAuditLogRequestError = Fetcher.ErrorWrapper<{
  status: 500;
  payload: Responses.GetAuditLogErrorResponse;
}>;

export type GetAuditLogRequestVariables = {
  pathParams: GetAuditLogRequestPathParams;
} & ApiContext['fetcherOptions'];

/**
 * Fetches an audit log record by the given ID
 */
export const fetchGetAuditLogRequest = (
  variables: GetAuditLogRequestVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Responses.GetAuditLogResponse,
    GetAuditLogRequestError,
    undefined,
    {},
    {},
    GetAuditLogRequestPathParams
  >({ url: '/v1/audit_log/{id}', method: 'get', ...variables, signal });

/**
 * Fetches an audit log record by the given ID
 */
export const useGetAuditLogRequest = <TData = Responses.GetAuditLogResponse>(
  variables: GetAuditLogRequestVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.GetAuditLogResponse, GetAuditLogRequestError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Responses.GetAuditLogResponse, GetAuditLogRequestError, TData>(
    queryKeyFn({ path: '/v1/audit_log/{id}', operationId: 'getAuditLogRequest', variables }),
    ({ signal }) => fetchGetAuditLogRequest({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type GetAuditLogsRequestQueryParams = {
  /**
   * Amount of records that should be returned (default 10)
   *
   * @format uint64
   */
  limit?: number;
  /**
   * Offset for pagination
   *
   * @format uint64
   */
  offset?: number;
  /**
   * Search by hub slug, turf name or email
   */
  query?: string;
  /**
   * Filter by even type. Possible values are: hub_create, hub_update, hub_delete, turf_create, turf_update, turf_delete
   */
  event_type?: string;
  /**
   * From which time records should be taken (format 2006-01-02T15:04:05Z07:00, 2006-01-02T08:04:05Z, etc)
   */
  from?: string;
  /**
   * To which time records should be taken (format 2006-01-02T15:04:05Z07:00, 2006-01-02T08:04:05Z, etc)
   */
  to?: string;
};

export type GetAuditLogsRequestError = Fetcher.ErrorWrapper<{
  status: 500;
  payload: Responses.GetAuditLogsErrorResponse;
}>;

export type GetAuditLogsRequestVariables = {
  queryParams?: GetAuditLogsRequestQueryParams;
} & ApiContext['fetcherOptions'];

/**
 * Fetches a list of audit logs
 */
export const fetchGetAuditLogsRequest = (
  variables: GetAuditLogsRequestVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Responses.GetAuditLogsResponse,
    GetAuditLogsRequestError,
    undefined,
    {},
    GetAuditLogsRequestQueryParams,
    {}
  >({ url: '/v1/audit_logs', method: 'get', ...variables, signal });

/**
 * Fetches a list of audit logs
 */
export const useGetAuditLogsRequest = <TData = Responses.GetAuditLogsResponse>(
  variables: GetAuditLogsRequestVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.GetAuditLogsResponse, GetAuditLogsRequestError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Responses.GetAuditLogsResponse, GetAuditLogsRequestError, TData>(
    queryKeyFn({ path: '/v1/audit_logs', operationId: 'getAuditLogsRequest', variables }),
    ({ signal }) => fetchGetAuditLogsRequest({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type GetDeliveryAreasRequestError = Fetcher.ErrorWrapper<{
  status: 500;
  payload: Responses.GetDeliveryAreasErrorResponse;
}>;

export type GetDeliveryAreasRequestVariables = ApiContext['fetcherOptions'];

export const fetchGetDeliveryAreasRequest = (
  variables: GetDeliveryAreasRequestVariables,
  signal?: AbortSignal
) =>
  apiFetch<Responses.GetDeliveryAreasResponse, GetDeliveryAreasRequestError, undefined, {}, {}, {}>(
    { url: '/v1/delivery_areas', method: 'get', ...variables, signal }
  );

export const useGetDeliveryAreasRequest = <TData = Responses.GetDeliveryAreasResponse>(
  variables: GetDeliveryAreasRequestVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Responses.GetDeliveryAreasResponse,
      GetDeliveryAreasRequestError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Responses.GetDeliveryAreasResponse,
    GetDeliveryAreasRequestError,
    TData
  >(
    queryKeyFn({ path: '/v1/delivery_areas', operationId: 'getDeliveryAreasRequest', variables }),
    ({ signal }) => fetchGetDeliveryAreasRequest({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type GetHubRequestPathParams = {
  /**
   * HubSlug for the Hub to fetch
   */
  hubSlug: string;
};

export type GetHubRequestError = Fetcher.ErrorWrapper<{
  status: 500;
  payload: Responses.GetHubErrorResponse;
}>;

export type GetHubRequestVariables = {
  pathParams: GetHubRequestPathParams;
} & ApiContext['fetcherOptions'];

/**
 * Fetches a hub using its slug
 */
export const fetchGetHubRequest = (variables: GetHubRequestVariables, signal?: AbortSignal) =>
  apiFetch<
    Responses.GetHubResponse,
    GetHubRequestError,
    undefined,
    {},
    {},
    GetHubRequestPathParams
  >({ url: '/v1/hub/{hubSlug}', method: 'get', ...variables, signal });

/**
 * Fetches a hub using its slug
 */
export const useGetHubRequest = <TData = Responses.GetHubResponse>(
  variables: GetHubRequestVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.GetHubResponse, GetHubRequestError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Responses.GetHubResponse, GetHubRequestError, TData>(
    queryKeyFn({ path: '/v1/hub/{hub_slug}', operationId: 'getHubRequest', variables }),
    ({ signal }) => fetchGetHubRequest({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type HubPatchRequestPathParams = {
  /**
   * HubSlug for the Hub to patch
   */
  hubSlug: string;
};

export type HubPatchRequestError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.HubPatchErrorResponse;
    }
  | {
      status: 500;
      payload: Responses.HubPatchErrorResponse;
    }
>;

export type HubPatchRequestVariables = {
  body?: Schemas.HubDetails;
  pathParams: HubPatchRequestPathParams;
} & ApiContext['fetcherOptions'];

/**
 * Patches the hub with additional details
 */
export const fetchHubPatchRequest = (variables: HubPatchRequestVariables, signal?: AbortSignal) =>
  apiFetch<undefined, HubPatchRequestError, Schemas.HubDetails, {}, {}, HubPatchRequestPathParams>({
    url: '/v1/hub/{hubSlug}',
    method: 'patch',
    ...variables,
    signal,
  });

/**
 * Patches the hub with additional details
 */
export const useHubPatchRequest = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, HubPatchRequestError, HubPatchRequestVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, HubPatchRequestError, HubPatchRequestVariables>(
    (variables: HubPatchRequestVariables) =>
      fetchHubPatchRequest({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ClosureReasonPatchRequestPathParams = {
  /**
   * HubSlug for the Hub to patch
   */
  hubSlug: string;
};

export type ClosureReasonPatchRequestError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.PatchClosureReasonErrorResponse;
    }
  | {
      status: 500;
      payload: Responses.PatchClosureReasonErrorResponse;
    }
>;

export type ClosureReasonPatchRequestVariables = {
  body?: RequestBodies.ClosureReasons;
  pathParams: ClosureReasonPatchRequestPathParams;
} & ApiContext['fetcherOptions'];

/**
 * Patches the hub closure reasons
 */
export const fetchClosureReasonPatchRequest = (
  variables: ClosureReasonPatchRequestVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    ClosureReasonPatchRequestError,
    RequestBodies.ClosureReasons,
    {},
    {},
    ClosureReasonPatchRequestPathParams
  >({ url: '/v1/hub/{hubSlug}/closure_reasons', method: 'patch', ...variables, signal });

/**
 * Patches the hub closure reasons
 */
export const useClosureReasonPatchRequest = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ClosureReasonPatchRequestError,
      ClosureReasonPatchRequestVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ClosureReasonPatchRequestError,
    ClosureReasonPatchRequestVariables
  >(
    (variables: ClosureReasonPatchRequestVariables) =>
      fetchClosureReasonPatchRequest({ ...fetcherOptions, ...variables }),
    options
  );
};

export type CreateTurfRequestPathParams = {
  /**
   * HubSlug for the Hub to Create
   */
  hubSlug: string;
};

export type CreateTurfRequestError = Fetcher.ErrorWrapper<{
  status: 500;
  payload: Responses.CreateTurfErrorResponse;
}>;

export type CreateTurfRequestVariables = {
  body?: RequestBodies.Turf;
  pathParams: CreateTurfRequestPathParams;
} & ApiContext['fetcherOptions'];

/**
 * Create a turf
 */
export const fetchCreateTurfRequest = (
  variables: CreateTurfRequestVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Responses.CreateTurfResponse,
    CreateTurfRequestError,
    RequestBodies.Turf,
    {},
    {},
    CreateTurfRequestPathParams
  >({ url: '/v1/hub/{hubSlug}/turf', method: 'post', ...variables, signal });

/**
 * Create a turf
 */
export const useCreateTurfRequest = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.CreateTurfResponse,
      CreateTurfRequestError,
      CreateTurfRequestVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Responses.CreateTurfResponse,
    CreateTurfRequestError,
    CreateTurfRequestVariables
  >(
    (variables: CreateTurfRequestVariables) =>
      fetchCreateTurfRequest({ ...fetcherOptions, ...variables }),
    options
  );
};

export type DeleteTurfRequestPathParams = {
  /**
   * HubSlug for the Hub to Delete
   */
  hubSlug: string;
  /**
   * TurfID for the turf to Delete
   */
  turfId: string;
};

export type DeleteTurfRequestError = Fetcher.ErrorWrapper<
  | {
      status: 404;
      payload: Responses.DeleteTurfErrorResponse;
    }
  | {
      status: 500;
      payload: Responses.DeleteTurfErrorResponse;
    }
>;

export type DeleteTurfRequestVariables = {
  pathParams: DeleteTurfRequestPathParams;
} & ApiContext['fetcherOptions'];

/**
 * Delete a turf
 */
export const fetchDeleteTurfRequest = (
  variables: DeleteTurfRequestVariables,
  signal?: AbortSignal
) =>
  apiFetch<undefined, DeleteTurfRequestError, undefined, {}, {}, DeleteTurfRequestPathParams>({
    url: '/v1/hub/{hubSlug}/turf/{turfId}',
    method: 'delete',
    ...variables,
    signal,
  });

/**
 * Delete a turf
 */
export const useDeleteTurfRequest = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteTurfRequestError, DeleteTurfRequestVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, DeleteTurfRequestError, DeleteTurfRequestVariables>(
    (variables: DeleteTurfRequestVariables) =>
      fetchDeleteTurfRequest({ ...fetcherOptions, ...variables }),
    options
  );
};

export type PatchTurfRequestPathParams = {
  /**
   * HubSlug for the Hub to fetch
   */
  hubSlug: string;
  /**
   * TurfID for the turf to fetch
   */
  turfId: string;
};

export type PatchTurfRequestError = Fetcher.ErrorWrapper<{
  status: 500;
  payload: Responses.PatchTurfErrorResponse;
}>;

export type PatchTurfRequestVariables = {
  body?: RequestBodies.Turf;
  pathParams: PatchTurfRequestPathParams;
} & ApiContext['fetcherOptions'];

/**
 * Fetches a list of turfs for a specific hub
 */
export const fetchPatchTurfRequest = (variables: PatchTurfRequestVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    PatchTurfRequestError,
    RequestBodies.Turf,
    {},
    {},
    PatchTurfRequestPathParams
  >({ url: '/v1/hub/{hubSlug}/turf/{turfId}', method: 'patch', ...variables, signal });

/**
 * Fetches a list of turfs for a specific hub
 */
export const usePatchTurfRequest = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, PatchTurfRequestError, PatchTurfRequestVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, PatchTurfRequestError, PatchTurfRequestVariables>(
    (variables: PatchTurfRequestVariables) =>
      fetchPatchTurfRequest({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ScheduleAreaChangeRequestPathParams = {
  /**
   * HubSlug for the Hub to fetch
   */
  hubSlug: string;
  /**
   * TurfID for the turf to fetch
   */
  turfId: string;
};

export type ScheduleAreaChangeRequestError = Fetcher.ErrorWrapper<{
  status: 500;
  payload: Responses.ScheduleAreaChangeErrorResponse;
}>;

export type ScheduleAreaChangeRequestVariables = {
  body?: Schemas.ScheduledChange;
  pathParams: ScheduleAreaChangeRequestPathParams;
} & ApiContext['fetcherOptions'];

/**
 * Schedule a turf area change accordingly to the given payload
 */
export const fetchScheduleAreaChangeRequest = (
  variables: ScheduleAreaChangeRequestVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    ScheduleAreaChangeRequestError,
    Schemas.ScheduledChange,
    {},
    {},
    ScheduleAreaChangeRequestPathParams
  >({ url: '/v1/hub/{hubSlug}/turf/{turfId}/area/schedule', method: 'post', ...variables, signal });

/**
 * Schedule a turf area change accordingly to the given payload
 */
export const useScheduleAreaChangeRequest = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ScheduleAreaChangeRequestError,
      ScheduleAreaChangeRequestVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ScheduleAreaChangeRequestError,
    ScheduleAreaChangeRequestVariables
  >(
    (variables: ScheduleAreaChangeRequestVariables) =>
      fetchScheduleAreaChangeRequest({ ...fetcherOptions, ...variables }),
    options
  );
};

export type PatchTurfClosureReasonsRequestPathParams = {
  /**
   * HubSlug for the Hub to fetch
   */
  hubSlug: string;
  /**
   * TurfID for the turf to fetch
   */
  turfId: string;
};

export type PatchTurfClosureReasonsRequestError = Fetcher.ErrorWrapper<{
  status: 500;
  payload: Responses.PatchTurfClosureReasonsErrorResponse;
}>;

export type PatchTurfClosureReasonsRequestVariables = {
  body?: RequestBodies.ClosureReasons;
  pathParams: PatchTurfClosureReasonsRequestPathParams;
} & ApiContext['fetcherOptions'];

/**
 * Fetches a list of turfs for a specific hub
 */
export const fetchPatchTurfClosureReasonsRequest = (
  variables: PatchTurfClosureReasonsRequestVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    PatchTurfClosureReasonsRequestError,
    RequestBodies.ClosureReasons,
    {},
    {},
    PatchTurfClosureReasonsRequestPathParams
  >({
    url: '/v1/hub/{hubSlug}/turf/{turfId}/closure_reasons',
    method: 'patch',
    ...variables,
    signal,
  });

/**
 * Fetches a list of turfs for a specific hub
 */
export const usePatchTurfClosureReasonsRequest = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      PatchTurfClosureReasonsRequestError,
      PatchTurfClosureReasonsRequestVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    PatchTurfClosureReasonsRequestError,
    PatchTurfClosureReasonsRequestVariables
  >(
    (variables: PatchTurfClosureReasonsRequestVariables) =>
      fetchPatchTurfClosureReasonsRequest({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetAllTurfsRequestPathParams = {
  /**
   * HubSlug for the turfs Hub to fetch
   */
  hubSlug: string;
};

export type GetAllTurfsRequestError = Fetcher.ErrorWrapper<{
  status: 500;
  payload: Responses.GetAllTurfsErrorResponse;
}>;

export type GetAllTurfsRequestVariables = {
  pathParams: GetAllTurfsRequestPathParams;
} & ApiContext['fetcherOptions'];

/**
 * Fetches a list of turfs for a specific hub
 */
export const fetchGetAllTurfsRequest = (
  variables: GetAllTurfsRequestVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Responses.GetAllTurfsResponse,
    GetAllTurfsRequestError,
    undefined,
    {},
    {},
    GetAllTurfsRequestPathParams
  >({ url: '/v1/hub/{hubSlug}/turfs', method: 'get', ...variables, signal });

/**
 * Fetches a list of turfs for a specific hub
 */
export const useGetAllTurfsRequest = <TData = Responses.GetAllTurfsResponse>(
  variables: GetAllTurfsRequestVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.GetAllTurfsResponse, GetAllTurfsRequestError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Responses.GetAllTurfsResponse, GetAllTurfsRequestError, TData>(
    queryKeyFn({ path: '/v1/hub/{hub_slug}/turfs', operationId: 'getAllTurfsRequest', variables }),
    ({ signal }) => fetchGetAllTurfsRequest({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type HubsError = Fetcher.ErrorWrapper<{
  status: 500;
  payload: Responses.GetHubListErrorResponse;
}>;

export type HubsVariables = ApiContext['fetcherOptions'];

/**
 * Fetches a list of hubs in their List format
 */
export const fetchHubs = (variables: HubsVariables, signal?: AbortSignal) =>
  apiFetch<Responses.GetHubListResponse, HubsError, undefined, {}, {}, {}>({
    url: '/v1/hubs',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Fetches a list of hubs in their List format
 */
export const useHubs = <TData = Responses.GetHubListResponse>(
  variables: HubsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.GetHubListResponse, HubsError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Responses.GetHubListResponse, HubsError, TData>(
    queryKeyFn({ path: '/v1/hubs', operationId: 'hubs', variables }),
    ({ signal }) => fetchHubs({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type BatchHubPatchRequestError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.HubPatchErrorResponse;
    }
  | {
      status: 500;
      payload: Responses.HubPatchErrorResponse;
    }
>;

export type BatchHubPatchRequestRequestBody = Schemas.PatchRequest[];

export type BatchHubPatchRequestVariables = {
  body?: BatchHubPatchRequestRequestBody;
} & ApiContext['fetcherOptions'];

/**
 * Batch Update the hub with additional details
 */
export const fetchBatchHubPatchRequest = (
  variables: BatchHubPatchRequestVariables,
  signal?: AbortSignal
) =>
  apiFetch<undefined, BatchHubPatchRequestError, BatchHubPatchRequestRequestBody, {}, {}, {}>({
    url: '/v1/hubs',
    method: 'patch',
    ...variables,
    signal,
  });

/**
 * Batch Update the hub with additional details
 */
export const useBatchHubPatchRequest = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      BatchHubPatchRequestError,
      BatchHubPatchRequestVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    BatchHubPatchRequestError,
    BatchHubPatchRequestVariables
  >(
    (variables: BatchHubPatchRequestVariables) =>
      fetchBatchHubPatchRequest({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetHubDetailsWithIDRequestPathParams = {
  /**
   * HubSlug for the LegacyHub to fetch
   */
  hubId: string;
};

export type GetHubDetailsWithIDRequestError = Fetcher.ErrorWrapper<{
  status: 500;
  payload: Responses.GetHubDetailsWithIDErrorResponse;
}>;

export type GetHubDetailsWithIDRequestVariables = {
  pathParams: GetHubDetailsWithIDRequestPathParams;
} & ApiContext['fetcherOptions'];

/**
 * Returns information regarding specific hub
 */
export const fetchGetHubDetailsWithIDRequest = (
  variables: GetHubDetailsWithIDRequestVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Responses.GetHubDetailsWithIDResponse,
    GetHubDetailsWithIDRequestError,
    undefined,
    {},
    {},
    GetHubDetailsWithIDRequestPathParams
  >({ url: '/v1/hubs/{hubId}', method: 'get', ...variables, signal });

/**
 * Returns information regarding specific hub
 */
export const useGetHubDetailsWithIDRequest = <TData = Responses.GetHubDetailsWithIDResponse>(
  variables: GetHubDetailsWithIDRequestVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Responses.GetHubDetailsWithIDResponse,
      GetHubDetailsWithIDRequestError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Responses.GetHubDetailsWithIDResponse,
    GetHubDetailsWithIDRequestError,
    TData
  >(
    queryKeyFn({ path: '/v1/hubs/{hub_id}', operationId: 'getHubDetailsWithIDRequest', variables }),
    ({ signal }) => fetchGetHubDetailsWithIDRequest({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ScheduleClosureListError = Fetcher.ErrorWrapper<{
  status: 500;
  payload: Responses.GetScheduleClosureListErrorResponse;
}>;

export type ScheduleClosureListVariables = ApiContext['fetcherOptions'];

/**
 * Fetches a list of scheduled closures
 */
export const fetchScheduleClosureList = (
  variables: ScheduleClosureListVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Responses.GetScheduleClosureListResponse,
    ScheduleClosureListError,
    undefined,
    {},
    {},
    {}
  >({ url: '/v1/hubs/closure_reasons', method: 'get', ...variables, signal });

/**
 * Fetches a list of scheduled closures
 */
export const useScheduleClosureList = <TData = Responses.GetScheduleClosureListResponse>(
  variables: ScheduleClosureListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Responses.GetScheduleClosureListResponse,
      ScheduleClosureListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Responses.GetScheduleClosureListResponse,
    ScheduleClosureListError,
    TData
  >(
    queryKeyFn({ path: '/v1/hubs/closure_reasons', operationId: 'scheduleClosureList', variables }),
    ({ signal }) => fetchScheduleClosureList({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type BatchClosureReasonPatchRequestError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.PatchClosureReasonErrorResponse;
    }
  | {
      status: 500;
      payload: Responses.PatchClosureReasonErrorResponse;
    }
>;

export type BatchClosureReasonPatchRequestRequestBody = Schemas.BatchUpdateClosureReasonRequest[];

export type BatchClosureReasonPatchRequestVariables = {
  body?: BatchClosureReasonPatchRequestRequestBody;
} & ApiContext['fetcherOptions'];

/**
 * Batch Update the hub closure reasons
 */
export const fetchBatchClosureReasonPatchRequest = (
  variables: BatchClosureReasonPatchRequestVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    BatchClosureReasonPatchRequestError,
    BatchClosureReasonPatchRequestRequestBody,
    {},
    {},
    {}
  >({ url: '/v1/hubs/closure_reasons', method: 'patch', ...variables, signal });

/**
 * Batch Update the hub closure reasons
 */
export const useBatchClosureReasonPatchRequest = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      BatchClosureReasonPatchRequestError,
      BatchClosureReasonPatchRequestVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    BatchClosureReasonPatchRequestError,
    BatchClosureReasonPatchRequestVariables
  >(
    (variables: BatchClosureReasonPatchRequestVariables) =>
      fetchBatchClosureReasonPatchRequest({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetHubDetailsWithSlugRequestPathParams = {
  /**
   * HubSlug for the LegacyHub to fetch
   */
  hubSlug: string;
};

export type GetHubDetailsWithSlugRequestError = Fetcher.ErrorWrapper<{
  status: 500;
  payload: Responses.GetHubDetailsWithSlugErrorResponse;
}>;

export type GetHubDetailsWithSlugRequestVariables = {
  pathParams: GetHubDetailsWithSlugRequestPathParams;
} & ApiContext['fetcherOptions'];

/**
 * Returns information regarding specific hub
 */
export const fetchGetHubDetailsWithSlugRequest = (
  variables: GetHubDetailsWithSlugRequestVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Responses.GetHubDetailsWithSlugResponse,
    GetHubDetailsWithSlugRequestError,
    undefined,
    {},
    {},
    GetHubDetailsWithSlugRequestPathParams
  >({ url: '/v1/hubs/slug/{hubSlug}', method: 'get', ...variables, signal });

/**
 * Returns information regarding specific hub
 */
export const useGetHubDetailsWithSlugRequest = <TData = Responses.GetHubDetailsWithSlugResponse>(
  variables: GetHubDetailsWithSlugRequestVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Responses.GetHubDetailsWithSlugResponse,
      GetHubDetailsWithSlugRequestError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Responses.GetHubDetailsWithSlugResponse,
    GetHubDetailsWithSlugRequestError,
    TData
  >(
    queryKeyFn({
      path: '/v1/hubs/slug/{hub_slug}',
      operationId: 'getHubDetailsWithSlugRequest',
      variables,
    }),
    ({ signal }) => fetchGetHubDetailsWithSlugRequest({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type GetTurfsRequestQueryParams = {
  /**
   * Filter by slugs.
   */
  slugs?: string;
};

export type GetTurfsRequestError = Fetcher.ErrorWrapper<{
  status: 500;
  payload: Responses.GetTurfsErrorResponse;
}>;

export type GetTurfsRequestVariables = {
  queryParams?: GetTurfsRequestQueryParams;
} & ApiContext['fetcherOptions'];

/**
 * Fetches a list of turfs
 */
export const fetchGetTurfsRequest = (variables: GetTurfsRequestVariables, signal?: AbortSignal) =>
  apiFetch<
    Responses.GetTurfsResponse,
    GetTurfsRequestError,
    undefined,
    {},
    GetTurfsRequestQueryParams,
    {}
  >({ url: '/v1/hubs/turfs', method: 'get', ...variables, signal });

/**
 * Fetches a list of turfs
 */
export const useGetTurfsRequest = <TData = Responses.GetTurfsResponse>(
  variables: GetTurfsRequestVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.GetTurfsResponse, GetTurfsRequestError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Responses.GetTurfsResponse, GetTurfsRequestError, TData>(
    queryKeyFn({ path: '/v1/hubs/turfs', operationId: 'getTurfsRequest', variables }),
    ({ signal }) => fetchGetTurfsRequest({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type BatchScheduleAreaChangeRequestError = Fetcher.ErrorWrapper<{
  status: 500;
  payload: Responses.BatchScheduleAreaChangeErrorResponse;
}>;

export type BatchScheduleAreaChangeRequestRequestBody = Schemas.BatchScheduledChange[];

export type BatchScheduleAreaChangeRequestVariables = {
  body?: BatchScheduleAreaChangeRequestRequestBody;
} & ApiContext['fetcherOptions'];

/**
 * Batch Schedule a turf area change accordingly to the given payload
 */
export const fetchBatchScheduleAreaChangeRequest = (
  variables: BatchScheduleAreaChangeRequestVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    BatchScheduleAreaChangeRequestError,
    BatchScheduleAreaChangeRequestRequestBody,
    {},
    {},
    {}
  >({ url: '/v1/hubs/turfs/area/schedule', method: 'post', ...variables, signal });

/**
 * Batch Schedule a turf area change accordingly to the given payload
 */
export const useBatchScheduleAreaChangeRequest = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      BatchScheduleAreaChangeRequestError,
      BatchScheduleAreaChangeRequestVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    BatchScheduleAreaChangeRequestError,
    BatchScheduleAreaChangeRequestVariables
  >(
    (variables: BatchScheduleAreaChangeRequestVariables) =>
      fetchBatchScheduleAreaChangeRequest({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetAllHubsRequestError = Fetcher.ErrorWrapper<{
  status: 500;
  payload: Responses.GetAllHubsErrorResponse;
}>;

export type GetAllHubsRequestVariables = ApiContext['fetcherOptions'];

export const fetchGetAllHubsRequest = (
  variables: GetAllHubsRequestVariables,
  signal?: AbortSignal
) =>
  apiFetch<Responses.GetAllHubsResponse, GetAllHubsRequestError, undefined, {}, {}, {}>({
    url: '/v1/internal/hubs',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetAllHubsRequest = <TData = Responses.GetAllHubsResponse>(
  variables: GetAllHubsRequestVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.GetAllHubsResponse, GetAllHubsRequestError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Responses.GetAllHubsResponse, GetAllHubsRequestError, TData>(
    queryKeyFn({ path: '/v1/internal/hubs', operationId: 'getAllHubsRequest', variables }),
    ({ signal }) => fetchGetAllHubsRequest({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type QueryOperation =
  | {
      path: '/v1/audit_log/{id}';
      operationId: 'getAuditLogRequest';
      variables: GetAuditLogRequestVariables;
    }
  | {
      path: '/v1/audit_logs';
      operationId: 'getAuditLogsRequest';
      variables: GetAuditLogsRequestVariables;
    }
  | {
      path: '/v1/delivery_areas';
      operationId: 'getDeliveryAreasRequest';
      variables: GetDeliveryAreasRequestVariables;
    }
  | {
      path: '/v1/hub/{hub_slug}';
      operationId: 'getHubRequest';
      variables: GetHubRequestVariables;
    }
  | {
      path: '/v1/hub/{hub_slug}/turfs';
      operationId: 'getAllTurfsRequest';
      variables: GetAllTurfsRequestVariables;
    }
  | {
      path: '/v1/hubs';
      operationId: 'hubs';
      variables: HubsVariables;
    }
  | {
      path: '/v1/hubs/{hub_id}';
      operationId: 'getHubDetailsWithIDRequest';
      variables: GetHubDetailsWithIDRequestVariables;
    }
  | {
      path: '/v1/hubs/closure_reasons';
      operationId: 'scheduleClosureList';
      variables: ScheduleClosureListVariables;
    }
  | {
      path: '/v1/hubs/slug/{hub_slug}';
      operationId: 'getHubDetailsWithSlugRequest';
      variables: GetHubDetailsWithSlugRequestVariables;
    }
  | {
      path: '/v1/hubs/turfs';
      operationId: 'getTurfsRequest';
      variables: GetTurfsRequestVariables;
    }
  | {
      path: '/v1/internal/hubs';
      operationId: 'getAllHubsRequest';
      variables: GetAllHubsRequestVariables;
    };
